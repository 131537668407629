import firebase from "firebase/compat/app"

// Add the Firebase products that you want to use
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/storage"
import { getUserLocation } from "./location"
import { get, post, patch, del } from "./api_helper"

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      firebase.initializeApp(firebaseConfig)
    }
  }

  getFirestore() {
    return firebase.firestore();
  }
  /**
   * Registers the user with given details
   */
  registerUser = async (email, password) => {
    try {
      const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
      const user = userCredential.user;
      if (user) {
        const token = await user.getIdToken(true);
        localStorage.setItem('authToken', token);
      }
      return user;
    } catch (error) {
      console.error("Error while registering:", error);
      throw this._handleError(error);
    }
  };

  /**
   * Registers the user with given details
   */
  editProfileAPI = (userData) => {
    return (async () => {
      try {
        const { data } = await post(
          'https://updateuserprofile-ieywpvcuja-nw.a.run.app',
          userData,
        )
        return data || null;
      } catch (error) {
        console.error("Error while receiving player: ", error)
        throw this._handleError(error)
      }
    })()
  }

  getCompanyEmployees = () => {
    return (async () => {
      try {
        const { data } = await get(
          'https://gamedevgetcompanyemployees-ieywpvcuja-nw.a.run.app',
        )
        return data || null;
      } catch (error) {
        console.error("Error while receiving player: ", error)
        throw this._handleError(error)
      }
    })()
  }

  /**
   * Login user with given details
   */

  loginUser = async (email, password) => {
    try {
      const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
      const user = userCredential.user;
      if (user) {
        const token = await user.getIdToken(true);
        console.log(token, 'NEW')
        localStorage.setItem('authToken', token);
      }
      return user;
    } catch (error) {
      console.error("Error while logging in:", error);
      throw this._handleError(error);
    }
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = email => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  /**
   * Social Login user with given details
   */

  socialLoginUser = async type => {
    let provider
    if (type === "google") {
      provider = new firebase.auth.GoogleAuthProvider()
    } else if (type === "facebook") {
      provider = new firebase.auth.FacebookAuthProvider()
    }
    try {
      const result = await firebase.auth().signInWithPopup(provider)
      const user = result.user
      const userToken = firebase.auth().currentUser;

      if (userToken) {
        const token = await userToken.getIdToken(true);
        localStorage.setItem('authToken', token);
      }

      return user
    } catch (error) {
      throw this._handleError(error)
    }
  }

  addNewUserToFirestore = async user => {
    const profile = user
    const location = await getUserLocation()
    const fullName = profile?.displayName?.length
      ? profile?.displayName.split(" ")
      : ["", ""]

    let details = {
      first_name: profile?.given_name
        ? profile?.given_name
        : profile?.first_name
          ? profile?.first_name
          : fullName && fullName.length
            ? fullName[0]
            : "",
      last_name: profile?.family_name
        ? profile?.family_name
        : profile?.last_name
          ? profile?.last_name
          : fullName && fullName.length > 1
            ? fullName[1]
            : "",
      // coordinates: location?.coordinates || {},
      country_code: location?.countryCode || "",
      // country: location?.country,
      // city: location?.city,
      // full_name: profile?.displayName || "",
      email: profile?.email,
      picture: profile?.picture || "",
      last_login_time: firebase.firestore.FieldValue.serverTimestamp(),
    }
    const { data } = await post(
      'https://europe-west2-thirdpillar-api-fb.cloudfunctions.net/createUser',
      details
    )
    if (data?.data) {
      localStorage.setItem("authUser", JSON.stringify(data))
      return data?.data
    } else {
      return null
    }
  }

  getUserData = () => {
    return (async () => {
      try {
        const { data } = await get('https://getuserdata-ieywpvcuja-nw.a.run.app')
        return data.userData || null;
      } catch (error) {
        console.error("Error while receiving user data: ", error)
        throw this._handleError(error)
      }
    })()
  }

  getGameBundleRef = game_bundle => {//WV?
    return new Promise(async (resolve, reject) => {
      try {
        const gameRef = firebase
          .firestore()
          .collection("Game_Bundle")
          .doc(game_bundle)
        const gameData = await gameRef.get()

        if (!gameData?.exists) {
          throw new Error("Game bundle doesn't exist")
        } else {
          resolve(gameRef)
        }
      } catch (error) {
        reject(error)
      }
    })
  }

  createPlayer = (data, gameRef) => {// WV"?
    return new Promise(async (resolve, reject) => {
      try {
        console.log("Try to create player profile", data)

        const uid = firebase.auth().currentUser?.uid
        const userRef = firebase.firestore().collection("User").doc(uid)
        const playerRef = firebase
          .firestore()
          .collection("Player")
          .doc(`${uid}-${data.game_bundle}-${data.player_id}`)

        const playerData = await playerRef.get()

        if (playerData.exists) {
          resolve(playerData.data())
        } else {
          await playerRef.set({
            ...data,
            game_bundle: gameRef,
            user: userRef,
            created_at: firebase.firestore.FieldValue.serverTimestamp(),
            modified_at: firebase.firestore.FieldValue.serverTimestamp(),
          })
          const newPlayer = await playerRef.get()
          resolve(newPlayer.data())
        }
      } catch (error) {
        console.log("Error creating player data:", error)
        reject(error)
      }
    })
  }

  getAllUsers = () => {
    return (async () => {
      try {
        const { data } = await post('https://getallusers-ieywpvcuja-nw.a.run.app')
        return data || null;
      } catch (error) {
        console.error("Error while receiving all user list: ", error)
        throw this._handleError(error)
      }
    })()
  }

  addCompany = payload => {
    return (async () => {
      try {
        const { data } = await post(
          'https://addcompany-ieywpvcuja-nw.a.run.app',
          payload
        )
        return data || null;
      } catch (error) {
        console.error("Error adding new company ", error)
        if (error.response.status === 409) {
          error.message = 'Company with the same name or id already registered.'
          throw this._handleError(error)
        }
        throw this._handleError(error)
      }
    })()
  }

  editCompany = payload => {
    return (async () => {
      try {
        const { data } = await patch(
          'https://editcompany-ieywpvcuja-nw.a.run.app',
          payload
        )
        return data || null;
      } catch (error) {
        console.error("Error editing the company ", error)
        throw this._handleError(error)
      }
    })()
  }

  uploadFileToFirebase = ({ file, company }) => {
    return (async () => {
      try {
        if (file) {
          const storageRef = firebase.storage().ref(`logo/${company}-logo`);
          const snapshot = await storageRef.put(file);
          const downloadURL = await snapshot.ref.getDownloadURL();
          return downloadURL;
        }
        return null;
      } catch (error) {
        console.error("Error while uploading file: ", error);
        throw this._handleError(error);
      }
    })();
  }

  changeUserAuthPassword = newPassword => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log("newPass", newPassword)
        const user = firebase.auth().currentUser

        const response = await user.updatePassword(newPassword)
        resolve("Successful")
        console.log("Password updated successfully", response)
      } catch (error) {
        console.error("Error updating password:", error.message)
        reject(error)
      }
    })
  }

  getAllCompanies = () => {
    return (async () => {
      try {
        const { data } = await get('https://getallcompanies-ieywpvcuja-nw.a.run.app')
        return data || null;

      } catch (error) {
        console.error("Error while receiving player: ", error)
        throw this._handleError(error)
      }
    })()
  }

  getBundle = (id) => {
    return (async () => {
      try {
        const { data } = await post('https://getbundle-ieywpvcuja-nw.a.run.app', { id: id.payload })
        return data || null;

      } catch (error) {
        console.error("Error while receiving player: ", error)
        throw this._handleError(error)
      }
    })()
  }

  editBundle = (payload) => {
    const { id, status } = payload;
    console.log(id, status)
    return (async () => {
      try {
        const { data } = await patch('https://editbundle-ieywpvcuja-nw.a.run.app', payload)
        return data || null;

      } catch (error) {
        console.error("Error while receiving player: ", error)
        throw this._handleError(error)
      }
    })()
  }

  inviteUser = ({ email, role, last_name, first_name, account }) => {
    return (async () => {
      try {
        const { data } = await post(
          `https://inviteuser-ieywpvcuja-nw.a.run.app`,
          {
            email,
            role,
            first_name,
            last_name,
            account
          }
        )

        return data || null;

      } catch (error) {
        if (error.response.status === 409) {
          error.message = 'User already invited or registered.'
          throw this._handleError(error)
        }

        console.error("Error while receiving player: ", error)
        throw this._handleError(error)
      }
    })()
  }

  getApiLogs = payload => {
    const { key } = payload;
    return (async () => {
      try {
        const { data } = await post(
          'https://getapilogs-ieywpvcuja-nw.a.run.app',
          {
            key
          }
        )
        return data || null;
      } catch (error) {
        console.error("Error while updating user role: ", error)
        throw this._handleError(error)
      }
    })()
  }

  updateUserRole = payload => {
    const { id, role } = payload
    return (async () => {
      try {
        const { data } = await post(
          'https://handlerolechange-ieywpvcuja-nw.a.run.app',
          {
            id,
            role
          }
        )
        return data || null;
      } catch (error) {
        console.error("Error while updating user role: ", error)
        throw this._handleError(error)
      }
    })()
  }

  deleteProfile = payload => {
    const { id, email } = payload
    return (async () => {
      try {
        const { data } = await del(`https://handleuserdelete-ieywpvcuja-nw.a.run.app?id=${id}&email=${email}`)
        return data || null;
      } catch (error) {
        console.error("Error while updating user role: ", error)
        throw this._handleError(error)
      }
    })()
  }

  playerGetAccountData = payload => {
    return (async () => {
      try {
        const { data } = await post(
          `https://playergetaccountdata-ieywpvcuja-nw.a.run.app`,
          {
            payload,
          }
        )
        return data || null;
      } catch (error) {
        console.error("Error while receiving player: ", error)
        throw this._handleError(error)
      }
    })()
  }

  gameDevGetAccountData = () => {
    return (async () => {
      try {
        const { data } = await post('https://gamedevgetaccountdata-ieywpvcuja-nw.a.run.app')
        return data || null;
      } catch (error) {
        console.error("Error while receiving player: ", error)
        throw this._handleError(error)
      }
    })()
  }

  getBundleDetails = id => {
    return (async () => {
      try {
        const { data } = await post(
          `https://getbundledetails-ieywpvcuja-nw.a.run.app`,
          {
            id,
          }
        )
        return data || null;
      } catch (error) {
        console.error("Error while receiving player: ", error)
        throw this._handleError(error)
      }
    })()
  }

  thirdAdminGetAccountData = () => {
    return (async () => {
      try {
        const { data } = await post('https://thirdadmingetaccountdata-ieywpvcuja-nw.a.run.app')
        return data || null;
      } catch (error) {
        console.error("Error while receiving player: ", error)
        throw this._handleError(error)
      }
    })()
  }

  addGame = ({ payload }) => {
    return (async () => {
      try {
        const { data } = await post('https://addgame-ieywpvcuja-nw.a.run.app', payload)

        return data || null;
      } catch (error) {
        if (error.response.status === 409) {
          error.message = 'A game with the same bundle ID or game ID already exists.'
          throw this._handleError(error)
        }

        throw this._handleError(error)
      }
    })()
  }


  deleteBundle = ({ payload }) => {
    const { id } = payload
    return (async () => {
      try {
        const { data } = await del(`https://deletebundle-ieywpvcuja-nw.a.run.app?id=${id}`)

        return data || null;
      } catch (error) {
        throw this._handleError(error)
      }
    })()
  }

  addKey = (payload) => {
    return (async () => {
      try {
        const { data } = await post('https://addapikey-ieywpvcuja-nw.a.run.app', payload)
        return data || null;
      } catch (error) {
        if (error.response.status === 409) {
          error.message = 'API key already exists'
          throw this._handleError(error)
        }
        console.log(error)
        throw this._handleError(error)
      }
    })()
  }

  adminGetChartsData = () => {
    return (async () => {
      try {
        const { data } = await get('https://admingetchartsdata-ieywpvcuja-nw.a.run.app')
        return data || null;
      } catch (error) {
        console.log(error)
        throw this._handleError(error)
      }
    })()
  }

  gameDevGetChartsData = () => {
    return (async () => {
      try {
        const { data } = await get('https://gamedevgetchartsdata-ieywpvcuja-nw.a.run.app')
        return data || null;
      } catch (error) {
        console.log(error)
        throw this._handleError(error)
      }
    })()
  }

  deleteKey = (payload) => {
    return (async () => {
      try {
        const { data } = await patch('https://deleteapikey-ieywpvcuja-nw.a.run.app', payload)
        return data || null;
      } catch (error) {
        console.log(error)
        throw this._handleError(error)
      }
    })()
  }

  setLoggeedInUser = user => {
    localStorage.setItem("authUser", JSON.stringify(user))
  }

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null
    return JSON.parse(localStorage.getItem("authUser"))
  }

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message
    return errorMessage
  }
}

let _fireBaseBackend = null

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = config => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config)
  }
  return _fireBaseBackend
}

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend
}

export { initFirebaseBackend, getFirebaseBackend }
