import {
    GAMEDEV_GET_ACCOUNT_DATA, GAMEDEV_GET_ACCOUNT_DATA_ERROR, GAMEDEV_GET_ACCOUNT_DATA_SUCCESS,
    GET_CHARTS_DATA_DEV,
    GET_CHARTS_DATA_DEV_ERROR,
    GET_CHARTS_DATA_DEV_SUCCESS
} from "./actionTypes";

export const GameDevGetAccountData = () => {
    return {
        type: GAMEDEV_GET_ACCOUNT_DATA,
    }
}

export const GameDevGetAccountDataSuccess = payload => {
    return {
        type: GAMEDEV_GET_ACCOUNT_DATA_SUCCESS,
        payload
    }
}

export const GameDevGetAccountDataError = () => {
    return {
        type: GAMEDEV_GET_ACCOUNT_DATA_ERROR,
    }
}

export const getChartsDataDev = () => {
    return {
        type: GET_CHARTS_DATA_DEV,
    }
}

export const getChartsDataDevSuccess = (payload) => {
    return {
        type: GET_CHARTS_DATA_DEV_SUCCESS,
        payload
    }
}

export const getChartsDataDevError = () => {
    return {
        type: GET_CHARTS_DATA_DEV_ERROR,
    }
}