import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, RESET_PROFILE_FLAG, DELETE_PROFILE_SUCCESS, DELETE_PROFILE_ERROR, UPDATE_USER_ROLE_SUCCESS, UPDATE_USER_ROLE_ERROR, INVITE_COMPANY_EMPLOYEE_SUCCESS, INVITE_COMPANY_EMPLOYEE_ERROR, GET_COMPANY_EMPLOYEES_SUCCESS, GET_COMPANY_EMPLOYEES_ERROR } from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  data: null,
  loading: false,
  employees: null
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state };
      break;
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload };
      break;
    case PROFILE_ERROR:
      state = { ...state, error: action.payload };
      break;
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;
