import axios from "axios"

const getLocationDetails = async (latitude, longitude) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCd2huN74RKzVKznj_i4oXDpslGuZNhtRA`
    )
    const results = response.data.results

    if (results.length > 0) {
      const addressComponents = results[0].address_components
      const country = addressComponents.find(comp =>
        comp.types.includes("country")
      )
      const city = addressComponents.find(comp =>
        comp.types.includes("locality")
      )

      return {
        coordinates: {
          latitude,
          longitude,
        },
        countryCode: country?.short_name ? country.short_name : "Unknown",
        country: country?.long_name ? country.long_name : "Unknown",
        city: city?.long_name ? city.long_name : "Unknown",
      }
    } else {
      return null
    }
  } catch (e) {
    return null
  }
}

export const getUserLocation = () => {
  return new Promise((resolve, reject) => {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async position => {
            const locationDetails = await getLocationDetails(
              position.coords.latitude,
              position.coords.longitude
            )
            resolve(locationDetails)
          },
          error => {
            console.error("Geolocation error:", error)
            resolve(null)
          }
        )
      } else {
        console.log("Geolocation not supported")
        resolve(null)
      }
    } catch (e) {
      console.error("Error in getUserLocation:", e)
      resolve(null)
    }
  })
}
