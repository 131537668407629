import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { ADD_KEY, DELETE_KEY, GET_BUNDLE_DATA } from "./actionTypes";
import { addKeyError, addKeySuccess, deleteKeySuccess, getBundleDataError, getBundleDataSuccess } from "./actions";
import { getFirebaseBackend } from "helpers/firebase_helper";
import { formatFirestoreTimestampDate, formatFirestoreTimestampTime } from "helpers/date_helper";
import { toast } from "react-toastify"

const fireBaseBackend = getFirebaseBackend();

const formatDate = item => {
    if (item.created_at) {
        item.created_at = {
            date: formatFirestoreTimestampDate(item.created_at),
            time: formatFirestoreTimestampTime(item.created_at)
        };
    }

    if (item.modified_at) {
        item.modified_at = {
            date: formatFirestoreTimestampDate(item.modified_at),
            time: formatFirestoreTimestampTime(item.modified_at)
        };
    }

    if (item.last_login_time) {
        item.last_login_time = {
            date: formatFirestoreTimestampDate(item.last_login_time),
            time: formatFirestoreTimestampTime(item.last_login_time)
        };
    }

    return item
}

function* getBundleDetails({ payload }) {
    try {
        let data = yield call(fireBaseBackend.getBundleDetails, payload);

        data.bundleData.created_at = {
            date: formatFirestoreTimestampDate(data.bundleData.created_at),
            time: formatFirestoreTimestampTime(data.bundleData.created_at)
        }

        data = {
            ...data,
            apiKeys: data.apiKeys.map(formatDate)
        }

        yield put(getBundleDataSuccess(data));
    } catch (error) {
        console.log(error);
        yield put(getBundleDataError());
    }
}

function* addKey({ payload }) {
    try {
        let data = yield call(fireBaseBackend.addKey, payload);

        data.apiKey.created_at = {
            date: formatFirestoreTimestampDate(data.apiKey.created_at),
            time: formatFirestoreTimestampTime(data.apiKey.created_at)
        }

        data.apiKey.modified_at = {
            date: formatFirestoreTimestampDate(data.apiKey.modified_at),
            time: formatFirestoreTimestampTime(data.apiKey.modified_at)
        }

        yield put(addKeySuccess(data));
        toast.success("Key Added Successful", { autoClose: 2000 })
    } catch (error) {
        console.log(error);
        toast.error(error, { autoClose: 2000 })
        yield put(addKeyError(error));
    }
}

function* deleteKey({ payload }) {
    try {
        let data = yield call(fireBaseBackend.deleteKey, payload);
        yield put(deleteKeySuccess(data));

        if (data.role === 2) {
            toast.success("Key Deleted Successful", { autoClose: 2000 })
        } else {
            toast.success("Key Disabled Successful", { autoClose: 2000 })
        }
    } catch (error) {
        console.log(error);
        yield put(deleteKeySuccess());
    }
}


export function* watchBundles() {
    yield takeEvery(GET_BUNDLE_DATA, getBundleDetails);
    yield takeEvery(ADD_KEY, addKey);
    yield takeEvery(DELETE_KEY, deleteKey);
}

function* bundlesSaga() {
    yield all([fork(watchBundles)]);
}

export default bundlesSaga;