import React from "react"
import { Badge } from "reactstrap"

const gamesTableConfig = {
    columns: [
        {
            header: "Date",
            accessorKey: "created_at",
            enableColumnFilter: false,
            enableSorting: false,
            cell: cellProps => (
                <p className="text-truncate m-0 text-left">
                    {cellProps.row.original.created_at.date}
                </p>
            ),
        },
        {
            header: "Time",
            accessorKey: "created_at-time",
            enableColumnFilter: false,
            enableSorting: false,
            cell: cellProps => (
                <p className="text-truncate m-0 text-left">
                    {cellProps.row.original.created_at.time}
                </p>
            ),
        },
        {
            header: "Description",
            accessorKey: "description",
            enableColumnFilter: false,
            enableSorting: false,
            cell: cellProps => {
                const money = cellProps.row.original.money;
                const badge = money > 0 ? <Badge className="bg-success fs-6 px-2 py-1" style={{ fontSize: '11px' }}> won</Badge> : <Badge style={{ fontSize: '11px' }} className="bg-danger fs-6 px-2 py-1"> lost</Badge>;
                return <p>You {badge} at {cellProps.row.original.gameName}</p>;
            },
        },
        {
            header: "Amount",
            accessorKey: "amount",
            enableColumnFilter: false,
            enableSorting: false,
            cell: cellProps => {
                return (
                    <p className="text-truncate m-0 text-left">
                        ${cellProps.row.original.money}
                    </p>
                )
            },
        },

    ],
    title: "Your Games",
    isAddButton: false,
    search: 'Search Game'
}

export default gamesTableConfig;