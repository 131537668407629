/* COMPANY */
export const GET_COMPANIES = "GET_COMPANIES"
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS"
export const GET_COMPANIES_FAIL = "GET_COMPANIES_FAIL"

export const GET_COMPANY_GAMES_SUCCESS = "GET_COMPANY_GAMES_SUCCESS"
export const GET_COMPANY_GAMES_FAIL = "GET_COMPANY_GAMES_FAIL"

export const GET_COMPANY_EMPLOYEES = "GET_COMPANY_EMPLOYEES"
export const GET_COMPANY_EMPLOYEES_SUCCESS = "GET_COMPANY_EMPLOYEES_SUCCESS"
export const GET_COMPANY_EMPLOYEES_ERROR = "GET_COMPANY_EMPLOYEES_ERROR"

export const INVITE_COMPANY_EMPLOYEE = "INVITE_COMPANY_EMPLOYEE"
export const INVITE_COMPANY_EMPLOYEE_SUCCESS = "INVITE_COMPANY_EMPLOYEE_SUCCESS"
export const INVITE_COMPANY_EMPLOYEE_ERROR = "INVITE_COMPANY_EMPLOYEE_ERROR"
export const UPDATE_USER_ROLE = "UPDATE_USER_ROLE"
export const UPDATE_USER_ROLE_SUCCESS = "UPDATE_USER_ROLE_SUCCESS"
export const UPDATE_USER_ROLE_ERROR = "UPDATE_USER_ROLE_ERROR"
export const DELETE_PROFILE = "DELETE_PROFILE"
export const DELETE_PROFILE_SUCCESS = "DELETE_PROFILE_SUCCESS"
export const DELETE_PROFILE_ERROR= "DELETE_PROFILE_ERROR"