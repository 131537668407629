import React from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { useDispatch } from "react-redux"

const DeleteModal = ({ modalData, toggle, action, text}) => {
  const { isOpen, data } = modalData;
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(action({
      id: data.id,
      email: data.email || ''
    }));
    toggle()
  }

  return (
    <Modal size="md" isOpen={isOpen} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle} tag="h4">
        Confirm Deletion
      </ModalHeader>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <p className="text-muted font-size-16 mb-4">{text}</p>

          <div className="hstack gap-2 justify-content-center mb-0">
            <button type="button" onClick={handleDelete} className="btn btn-danger">Delete Now</button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default DeleteModal;