
import React from "react"
import { Badge, Button } from "reactstrap"
import { Link } from "react-router-dom"

export const getTableConfig = (toggleModalDeleteData, toggleModalEditStatus) => ({
    columns: [
        {
            header: "Name",
            enableColumnFilter: false,
            enableSorting: true,
            accessorKey: "name",
            cell: cellProps => {
                return (
                    <Link to={`/game/?id=${cellProps.row.original.id}`} className="text-body">
                        {cellProps.row.original.name}
                    </Link>
                )
            },
        },
        {
            header: "Game ID",
            enableColumnFilter: false,
            enableSorting: true,
            accessorKey: "game_id",
            cell: cellProps => {
                return (
                    <Link to={`/game/?id=${cellProps.row.original.id}`} className="text-body">
                        {cellProps.row.original.game_id}
                    </Link>
                )
            },
        },
        {
            header: "Game Bundle ID",
            enableColumnFilter: false,
            enableSorting: true,
            accessorKey: "game_bundle_id",
            cell: cellProps => {
                return (
                    <Link to={`/game/?id=${cellProps.row.original.id}`} className="text-body">
                        {cellProps.row.original.game_bundle_id}
                    </Link>
                )
            },
        },
        {
            header: "Created Date",
            enableColumnFilter: false,
            enableSorting: true,
            accessorKey: "created_at",
            cell: cellProps => {
                return <span>{cellProps.row.original.created_at.date}</span>;
            },
        },
        {
            header: "Modified Date",
            enableColumnFilter: false,
            enableSorting: true,
            accessorKey: "modified_at",
            cell: cellProps => {
                return <span>{cellProps.row.original.modified_at.date}</span>;
            },
        },
        {
            header: "Status",
            accessorKey: "status",
            enableColumnFilter: false,
            enableSorting: true,
            cell: cell => {
                switch (cell.row.original.status) {
                    case 1:
                        return <Badge
                            className="bg-success px-2 py-1"
                            style={{ fontSize: '11px' }}
                            onClick={() => toggleModalEditStatus({ id: cell.row.original.id, status: cell.row.original.status })}
                        >Live
                        </Badge>
                    case 0:
                        return <Badge
                            className="bg-warning px-2 py-1"
                            style={{ fontSize: '11px' }}
                            onClick={() => toggleModalEditStatus({ id: cell.row.original.id, status: cell.row.original.status })}
                        >
                            Pending
                        </Badge>
                }
            },
        },
        {
            header: "Delete",
            cell: cell => {
                return (
                    <div className="d-flex gap-3">
                        <Button
                            className="text-danger bg-transparent shadow-none border-0"
                            onClick={() => toggleModalDeleteData({ id: cell.row.original.id, email: cell.row.original.email })}
                        >
                            <i className="mdi mdi-trash-can font-size-20" />
                        </Button>
                    </div>
                )
            },
        },
    ],
    title: 'Games',
    search: 'Search Game',
    pt: '0',
    px: '0',
    pb: '0'
})

export default getTableConfig;