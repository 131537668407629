import axios from "axios";
import firebase from "firebase/compat/app"
import "firebase/compat/auth"


//apply base url for axios
const API_URL = "";

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('authToken');
  console.log(token, 'TOKTNE')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => Promise.reject(error));

axiosApi.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    console.error('Axios response error:', error);
    if (error.response && error.response.data === 'Expired token' && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const user = firebase.auth().currentUser;
        if (user) {
          const newToken = await user.getIdToken(true);
          localStorage.setItem('authToken', newToken);
          axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
          return axiosApi(originalRequest);
        }
      } catch (tokenRefreshError) {
        console.error('Token refresh error:', tokenRefreshError);
        return Promise.reject(tokenRefreshError);
      }
    }

    if (error.response) {
      return Promise.reject(error);
    } else if (error.request) {
      console.error('No response received:', error.request);
      return Promise.reject(error);
    } else {
      console.error('Error setting up request:', error.message);
      return Promise.reject(error);
    }
  }
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response);
}

export async function patch(url, config = {}) {
  return await axiosApi
    .patch(url, { ...config })
    .then((response) => response);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, data, config)
    .then(response => response);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, data, config)
    .then(response => response);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response);
}
