import { divIcon } from 'leaflet'
import React from 'react'
import { Container, Spinner } from 'reactstrap'

const Loader = () => {
    return (
        <Container className='d-flex justify-content-center vh-100 align-items-center'>
            <Spinner color="primary">
                Loading...
            </Spinner>
        </Container>
    )
}

export default Loader