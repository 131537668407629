import React from "react"
import { Link } from "react-router-dom"
import { Badge, Button } from "reactstrap"

export const getTableConfig = (openEditModal) => ({
    columns: [{
        header: "Logo",
        id: "#",
        cell: cellProps => (
            <div
                className="d-flex justify-content-center align-items-center bg-light rounded"
                style={{
                    width: '40px',
                    height: '40px',
                    overflow: 'hidden',
                }}
            >
                <img
                    src={cellProps.row.original.company_logo}
                    alt=""
                    className="rounded-circle"
                    style={{
                        minWidth: '90%',
                        minHeight: '90%',
                        maxWidth: '150%',
                        maxHeight: '150%',
                        objectFit: 'cover',
                        objectPosition: 'center'
                    }}
                />
            </div>
        ),
        enableColumnFilter: false,
    },
    {
        header: "Company Name",
        accessorKey: "company",
        enableColumnFilter: false,
        cell: cellProps => {
            return (
                <>
                    <h5 className="text-truncate font-size-14">
                        <Link
                            to={`/company-overview/?id=${cellProps.row.original.id}`}
                            className="text-dark"
                        >
                            {cellProps.row.original.company}{" "}
                        </Link>
                    </h5>
                    <p className="text-truncate text-muted mb-0" style={{ display: 'block', width: '100%', maxWidth: '400px' }}>
                        {cellProps.row.original.company_desc}
                    </p>
                </>
            )
        },
    },
    {
        header: "Created Date",
        accessorKey: "created_at",
        enableColumnFilter: false,
        cell: cellProps => (
            <p>
                {cellProps.row.original.created_at.date}
            </p>
        ),
    },
    {
        header: "Status",
        accessorKey: "status",
        cell: cellProps => {
            switch (cellProps.row.original.status) {
                case 1:
                    return <Badge className="bg-success px-2 py-1" style={{ fontSize: '11px' }}>Live</Badge>
                case 0:
                    return <Badge className="bg-warning px-2 py-1" style={{ fontSize: '11px' }} >Pending</Badge>
            }
        },
        enableColumnFilter: false,
    },
    {
        header: "Edit",
        cell: cell => {
            return (
                <div className="d-flex gap-4">
                    <Button
                        className="text-success bg-transparent shadow-none border-0"
                        onClick={() => openEditModal({
                            companyName: cell.row.original.company,
                            description: cell.row.original.company_desc,
                            status: cell.row.original.status,
                            platform_fee: cell.row.original.platform_fee,
                            rev_share: cell.row.original.rev_share,
                            file: cell.row.original.company_logo, 
                        })}
                    >
                        <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                    </Button>
                </div>
            )
        },
    },

        // {
        //     header: "Team",
        //     accessorKey: "total",
        //     enableColumnFilter: false,
        //     cell: cellProps => {
        //         return (
        //             <div className="avatar-group">
        //                 {cellProps.row.original.team && cellProps.row.original.team.length
        //                     ? cellProps.row.original.team.map((member, index) => (
        //                         <div className="avatar-group-item" key={index}>
        //                             {!member.picture || member.picture !== "Null" ? (
        //                                 <>
        //                                     <UncontrolledTooltip
        //                                         placement="right"
        //                                         target={`UncontrolledTooltipExample-${member.id}`}
        //                                     >
        //                                         {member.first_name + " " + member.last_name}
        //                                     </UncontrolledTooltip>
        //                                     <Link
        //                                         to="#"
        //                                         className="team-member d-inline-block"
        //                                         id={`UncontrolledTooltipExample-${member.id}`}
        //                                     >
        //                                         <img
        //                                             src={member.picture}
        //                                             className="rounded-circle avatar-xs"
        //                                             alt=""
        //                                         />
        //                                     </Link>
        //                                 </>
        //                             ) : (
        //                                 <>
        //                                     <UncontrolledTooltip
        //                                         placement="right"
        //                                         target={`UncontrolledTooltipExample-${member.id}`}
        //                                     >
        //                                         {member.first_name}
        //                                     </UncontrolledTooltip>
        //                                     <Link
        //                                         to="#"
        //                                         className="d-inline-block"
        //                                         id={`UncontrolledTooltipExample-${member.id}`}
        //                                     >
        //                                         <div className="avatar-xs">
        //                                             <span
        //                                                 className={`avatar-title rounded-circle bg-${member.color}  text-white font-size-16`}
        //                                             >
        //                                                 {member.first_name.slice(0, 1)}
        //                                             </span>
        //                                         </div>
        //                                     </Link>
        //                                 </>
        //                             )}
        //                         </div>
        //                     ))
        //                     : null}
        //             </div>
        //         )
        //     },
        // }
    ],
    search: 'Search Company'
})

export default getTableConfig;