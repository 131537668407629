export const convertFirebaseTimestampToFormattedDate = firebaseTimestamp => {
  // Assuming firebaseTimestamp is a Firebase timestamp object!)
  const jsDate = firebaseTimestamp.toDate();

  // Months array to get month abbreviation
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]

  // Get day, month, and year
  const day = jsDate.getDate()
  const month = months[jsDate.getMonth()]
  const year = jsDate.getFullYear().toString() // Extract last two digits of the year

  // Format the date as "DD Mon, YY"
  const formattedDate = `${day} ${month}, ${year}`

  return formattedDate
}

export const convertSecondsToFormattedDateTime = second => {
  // Convert seconds to milliseconds
  const milliseconds = second * 1000

  // Create a new date object
  const jsDate = new Date(milliseconds)

  // Months array to get month abbreviation
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]

  // Get day, month, and year
  const day = jsDate.getDate()
  const month = months[jsDate.getMonth()]
  const year = jsDate.getFullYear().toString() // Extract last two digits of the year

  // Get hours, minutes, and seconds
  const hours = jsDate.getHours().toString().padStart(2, "0")
  const minutes = jsDate.getMinutes().toString().padStart(2, "0")
  const seconds = jsDate.getSeconds().toString().padStart(2, "0")

  // Format the date as "DD Mon, YY HH:MM:SS"
  const formattedDateTime = `${day} ${month}, ${year} ${hours}:${minutes}:${seconds}`

  return formattedDateTime
}

export const getCurrentDate = () => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  const date = new Date()
  const day = date.getDate()
  const month = months[date.getMonth()]
  const year = date.getFullYear()
  return `${day} ${month}, ${year}`
}

export const formatFirestoreTimestampDate = (timestamp, format = 'en-US') => {
  const milliseconds = timestamp._seconds * 1000 + timestamp._nanoseconds / 1e6;
  const date = new Date(milliseconds);

  const formattedDateTime = new Intl.DateTimeFormat(format, {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true
  }).format(date);

  const [, month, day, year] = formattedDateTime.match(/([a-zA-Z]+) (\d+), (\d+),/);
  return `${day} ${month}, ${year}`;
}

export const formatFirestoreTimestampTime = (timestamp, format = 'en-US') => {
  const milliseconds = timestamp._seconds * 1000 + timestamp._nanoseconds / 1e6;
  const date = new Date(milliseconds);

  const formattedTime = new Intl.DateTimeFormat(format, {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true
  }).format(date);

  return formattedTime;
}

export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp.seconds * 1000 + timestamp.nanos / 1000000);
  return date.toLocaleString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });
}

export const convertTimestampToDate = (timestamp) => {
  const { _seconds } = timestamp;
  const date = new Date(_seconds * 1000);
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();

  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  return `${year}-${formattedMonth}-${formattedDay}`;
}

export const updateTimestamps = items => items.map(item => ({
  ...item,
  created_at: convertTimestampToDate(item.created_at)
}));
