import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  LOGIN_USER_WV,
  GET_ME,
  GET_ME_SUCCESS,
  NO_USER,
} from "./actionTypes"

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  }
}

export const loginUserWv = (user, history, game_bundle, player_id) => {
  return {
    type: LOGIN_USER_WV,
    payload: { user, history, game_bundle, player_id },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const getMe = () => {
  return {
    type: GET_ME
  }
}

export const noUser = () => {
  return {
    type: NO_USER
  }
}

export const getMeSuccess = user => {
  return {
    type: GET_ME_SUCCESS,
    payload: user,
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const socialLogin = (type, history, authFor, game_bundle, player_id) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { type, history, authFor, game_bundle, player_id },
  }
}
