import React, { useMemo, useState } from "react"
import {
    Col,
    Container,
    Row,
    Card,
    CardBody,
} from "reactstrap"

//Import Component
import Breadcrumbs from "components/Common/Breadcrumb"
import "flatpickr/dist/themes/material_blue.css"
import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import TableContainer from "components/Common/TableContainer"

const List = ({ companies = [], loading, tableConfig, rightHeaderContent, pxUsers }) => {
    const { title, isAddButton, pt, search, px, pb } = tableConfig;
    const columns = useMemo(
        () => tableConfig.columns, []
    )

    return (
        <React.Fragment>
            <div className={`page-content pb-${pb || '10'} pt-${pt || '10'} px-${pxUsers || '10'}`}>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    {title ? <h4 className="my-3">{title}</h4> : <Breadcrumbs title="Companies" breadcrumbItem="Companies List" />}
                    {loading && !companies?.length ? (
                        <Spinners />
                    ) : (
                        <Row>
                            <Col lg="12" className={px && 'px-0'}>
                                <Card>
                                    <CardBody>
                                        <TableContainer
                                            columns={columns}
                                            data={companies}
                                            isGlobalFilter={true}
                                            isAddButton={isAddButton}
                                            isPagination={true}
                                            isCustomPageSize={true}
                                            SearchPlaceholder={search}
                                            buttonClass="btn btn-success btn-rounded"
                                            buttonName=" Add Company"
                                            tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                            theadClass="table-light"
                                            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                            pagination="pagination"
                                            rightHeaderContent={rightHeaderContent}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}

                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    )
}

export default List;
