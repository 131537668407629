import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux';
import { GameDevGetAccountData } from 'store/actions';
import Loader from 'components/Loader';
import { Container } from "reactstrap"
import List from 'components/List';
import { useSelector } from 'react-redux';
import getTableConfigDev from './tableConfigDev';


const GameDevLogs = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(state => state.GameDevAccount);
  const tableConfig = useMemo(() => (
    getTableConfigDev()
  ), [])

  useEffect(() => {
    dispatch(GameDevGetAccountData());
  }, [dispatch])


  if (!tableConfig || loading) {
    return <Loader />
  }

  return (
    <React.Fragment>
      <Container fluid>
        <List
          companies={data?.allGames ?? ''}
          loading={loading}
          tableConfig={tableConfig}
          pxUsers='2'
        />
      </Container>
    </React.Fragment>
  )
}

export default GameDevLogs