import { ADD_KEY_ERROR, ADD_KEY_SUCCESS, DELETE_KEY_ERROR, DELETE_KEY_SUCCESS, GET_BUNDLE_DATA, GET_BUNDLE_DATA_ERROR, GET_BUNDLE_DATA_SUCCESS } from "./actionTypes";

const initialState = {
    loading: true,
    data: null,
    matches: null,
    apiKeys: null
};

const BundleDetails = (state = initialState, action) => {
    switch (action.type) {
        case GET_BUNDLE_DATA: {
            state = {
                ...state,
                loading: true
            }
            break;
        }
        case GET_BUNDLE_DATA_SUCCESS: {
            state = {
                ...state,
                data: action.payload.bundleData,
                matches: action.payload.matches,
                apiKeys: action.payload.apiKeys,
                loading: false
            }
            break;
        }
        case GET_BUNDLE_DATA_ERROR: {
            state = {
                ...state,
                loading: false
            }
            break;
        }
        case ADD_KEY_SUCCESS: {
            const { api_key, active, created_at, modified_at, id } = action.payload.apiKey;
            const key = { api_key, active, created_at, modified_at, id }
            state = {
                ...state,
                apiKeys: [key, ...state.apiKeys],
                loading: false
            }
            break;
        }
        case ADD_KEY_ERROR: {
            state = {
                ...state,
                loading: false
            }
            break;
        }
        case DELETE_KEY_SUCCESS: {
            const { id, role } = action.payload;
            let updatedApiKeys;
            if (role === 2) {
                updatedApiKeys = state.apiKeys.filter(apiKey => apiKey.id !== id);
            } else {
                updatedApiKeys = state.apiKeys.map(apiKey => {
                    if (apiKey.id === id) {
                        return { ...apiKey, active: false };
                    }
                    return apiKey;
                });
            }
            return {
                ...state,
                apiKeys: updatedApiKeys,
                loading: false,
            };
        }
        case DELETE_KEY_ERROR: {
            state = {
                ...state,
                loading: false
            }
            break;
        }
    }

    return state;
};

export default BundleDetails;