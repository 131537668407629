import {
    GET_API_LOGS,
    GET_API_LOGS_ERROR,
    GET_API_LOGS_SUCCESS
} from "./actionTypes";

export const getApiLogs = (payload) => {
    return {
        type: GET_API_LOGS,
        payload
    }
}

export const getApiLogsSuccess = payload => {
    return {
        type: GET_API_LOGS_SUCCESS,
        payload
    }
}

export const getApiLogsError = payload => {
    console.log('error')
    return {
        type: GET_API_LOGS_ERROR,
        payload
    }
}