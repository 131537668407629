import { GET_API_LOGS, GET_API_LOGS_ERROR, GET_API_LOGS_SUCCESS } from "./actionTypes"

const INIT_STATE = {
    error: {},
    loading: true,
    logs: null,
}

const ApiLogs = (state = INIT_STATE, action) => {
    switch (action.type) {

        case GET_API_LOGS:
            return {
                ...state,
                loading: true
            };

        case GET_API_LOGS_SUCCESS:
            return {
                ...state,
                logs: action.payload,
                loading: false
            };

        case GET_API_LOGS_ERROR:
            return {
                ...state,
                loading: false
            };


        default:
            return state
    }
}

export default ApiLogs
