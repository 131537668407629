import React from "react"
import { Badge } from "reactstrap"

export const tableConfigGamDev = {
    columns: [{
        header: "Key",
        accessorKey: "key",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
            return (
                <p className="text-body m-0">
                    {cellProps.row.original.api_key}
                </p>
            )
        },
    },
    {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
            return cell.row.original.active ? (
                <Badge className="bg-success px-2 py-1" style={{ fontSize: '11px' }}>Active</Badge>
            ) : (
                <Badge className="bg-danger px-2 py-1" style={{ fontSize: '11px' }}>Disabled</Badge>
            );
        },
    },
    {
        header: "Created Date",
        enableColumnFilter: false,
        enableSorting: true,
        accessorKey: "created_at",
        cell: cellProps => {
            return <span>{cellProps.row.original.created_at.date}</span>;
        },
    }],
    title: "Api Keys",
    search: 'Search Key',
    pt: '0',
}