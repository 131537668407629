import {
    GET_ALL_USERS_SUCCESS,
    GET_ALL_USERS_ERROR,
    INVITE_USER_SUCCESS,
    INVITE_USER_ERROR,
    UPDATE_USER_ROLE_ADMIN_ERROR,
    DELETE_PROFILE_ADMIN_SUCCESS,
    DELETE_PROFILE_ADMIN_ERROR,
    UPDATE_USER_ROLE_ADMIN_SUCCESS,
    GET_DASHBOARD_DATA_SUCCESS,
    GET_DASHBOARD_DATA_ERROR,
    GET_COMPANIES_ERROR,
    GET_COMPANIES_SUCCESS,
    GET_BUNDLE_ERROR,
    GET_BUNDLE_SUCCESS,
    ADD_GAME_SUCCESS,
    ADD_GAME_ERROR,
    DELETE_BUNDLE_SUCCESS,
    DELETE_BUNDLE_ERROR,
    ADD_COMPANY_ERROR,
    ADD_COMPANY_SUCCESS,
    EDIT_COMPANY_SUCCESS,
    EDIT_COMPANY_ERROR,
    UPDATE_BUNDLE_DATA_SUCCESS,
    UPDATE_BUNDLE_DATA_ERROR,
    GET_BUNDLE,
    GET_DASHBOARD_DATA,
    GET_ALL_USERS,
    GET_COMPANIES,
    GET_CHARTS_DATA,
    GET_CHARTS_DATA_SUCCESS,
    GET_CHARTS_DATA_ERROR
} from "./actionTypes"

const INIT_STATE = {
    error: {},
    loading: true,
    data: null,
    dashboard: null,
    companies: null,
    bundle: null,
    company: null,
    charts: null
}

const Admin = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_USERS:
            return {
                ...state,
                loading: true
            };

        case GET_ALL_USERS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false
            };

        case GET_ALL_USERS_ERROR:
            return {
                ...state,
                loading: false
            };

        case INVITE_USER_SUCCESS: {
            const { email, role, last_name, first_name, id, Account } = action.payload
            const user = { email, role, last_name, first_name, docId: id, isInvited: true, Account };
            return {
                ...state,
                data: {
                    ...state.data,
                    users: [user, ...state.data.users],
                },
                loading: false
            };
        }

        case INVITE_USER_ERROR: {
            return {
                ...state,
                loading: false
            };
        }

        case UPDATE_USER_ROLE_ADMIN_SUCCESS: {
            const { id, role } = action.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    users: state.data.users.map(user =>
                        user.docId === id ? { ...user, role } : user
                    )
                },
                loading: false
            };
        }

        case UPDATE_USER_ROLE_ADMIN_ERROR: {
            return state = {
                ...state,
                loading: false
            };
        }

        case DELETE_PROFILE_ADMIN_SUCCESS: {
            const updatedUsers = state.data.users.filter(user => user.email !== action.payload.email);
            return {
                ...state,
                data: {
                    ...state.data,
                    users: updatedUsers,
                },
                loading: false
            };
        }

        case DELETE_PROFILE_ADMIN_ERROR: {
            return state = {
                ...state,
                loading: false
            }
        }

        case GET_DASHBOARD_DATA:
            return {
                ...state,
                loading: true
            };

        case GET_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                dashboard: action.payload,
                loading: false
            };

        case GET_DASHBOARD_DATA_ERROR:
            return {
                ...state,
                loading: false
            };

        case GET_CHARTS_DATA:
            return {
                ...state,
                loading: true
            };

        case GET_CHARTS_DATA_SUCCESS:
            return {
                ...state,
                charts: action.payload,
                loading: false
            };

        case GET_CHARTS_DATA_ERROR:
            return {
                ...state,
                loading: false
            };

        case GET_COMPANIES:
            return {
                ...state,
                loading: true
            }
        case GET_COMPANIES_SUCCESS:
            return {
                ...state,
                companies: action.payload,
                loading: false
            }
        case GET_COMPANIES_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            };

        case ADD_COMPANY_SUCCESS: {
            const { company_desc, platform_fee, rev_share, company_logo, created_at, modified_at, id } = action.payload;
            const newCompany = { company: action.payload.company, company_desc, platform_fee, rev_share, company_logo, status: action.payload.status, created_at, modified_at, id };
            return {
                ...state,
                companies: [newCompany, ...state.companies,],
                loading: false
            };
        }

        case ADD_COMPANY_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            };

        case EDIT_COMPANY_SUCCESS: {
            const { company_desc, platform_fee, rev_share, company_logo, id, status } = action.payload;
            const updatedCompanies = state.companies.map(company => {
                if (company.id === id) {
                    return {
                        ...company,
                        company_desc,
                        platform_fee,
                        rev_share,
                        company_logo,
                        status
                    };
                }
                return company;
            });

            return {
                ...state,
                companies: updatedCompanies,
                loading: false
            };
        }

        case EDIT_COMPANY_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            };


        case UPDATE_BUNDLE_DATA_SUCCESS: {
            const { id, status } = action.payload.bundle;

            const updatedItems = state.bundle.map(item => {
                if (item.id === id) {
                    return { ...item, status: status };
                }
                return item;
            });

            return {
                ...state,
                bundle: updatedItems,
                loading: false
            };
        }

        case UPDATE_BUNDLE_DATA_ERROR: {
            state = {
                ...state,
                loading: false
            }
        }
        case GET_BUNDLE:
            return {
                ...state,
                loading: true
            }
        case GET_BUNDLE_SUCCESS:
            return {
                ...state,
                bundle: action.payload.allGames,
                company: action.payload.accountData,
                loading: false
            }
        case GET_BUNDLE_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case ADD_GAME_SUCCESS:
            const { name, status, created_at, modified_at, description, game_bundle_id, game_id, id } = action.payload
            const newGame = { name, status, created_at, modified_at, description, game_bundle_id, game_id, id };
            return {
                ...state,
                bundle: [newGame, ...state.bundle],
                loading: false
            }
        case ADD_GAME_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            }

        case DELETE_BUNDLE_SUCCESS:
            const updatedBundle = state.bundle.filter(game => game.id !== action.payload.id);

            return {
                ...state,
                bundle: updatedBundle,
                loading: false
            };

        case DELETE_BUNDLE_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        default:
            return state
    }
}

export default Admin
