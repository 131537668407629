import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import ecommerceSaga from "./e-commerce/saga"
import contactsSaga from "./contacts/saga"
import dashboardSaasSaga from "./dashboard-saas/saga"
import dashboardBlogSaga from "./dashboard-blog/saga"
import companySaga from "./company/saga"
import PlayerSaga from "./player/saga"
import GameDevSaga from "./gameDeveloper/saga"
import bundlesSaga from "./bundleDetails/saga"
import adminSaga from "./admin/saga"
import ApiLogsSaga from "./apiLogs/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(ApiLogsSaga),
    fork(bundlesSaga),
    fork(adminSaga),
    fork(GameDevSaga),
    fork(PlayerSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(companySaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(ecommerceSaga),
    fork(contactsSaga),
    fork(dashboardSaasSaga),
    fork(dashboardBlogSaga),
  ])
}
