import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Container
} from "reactstrap"

import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

import { getRoleType } from "helpers/ui/user_helper"
import { useDispatch } from "react-redux"
import { logoutUser } from "store/actions"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const { user } = props
  const [menu, setMenu] = useState(false)
  const dispatch = useDispatch();

  const displayName = user?.first_name;
  const email = user?.email;
  const firstLetter = displayName ? displayName[0].toUpperCase() : email[0].toUpperCase();

  const onLogout = () => {
    dispatch(logoutUser());
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <div className="d-flex align-items-center">
            <div
              style={{
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                backgroundColor: 'rgb(127 145 158)',
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '20px',
              }}
              className="rounded-circle header-profile-user"
            >
              {firstLetter}
            </div>
            <span className="d-none d-xl-inline-block ms-2 me-1">
              {user && user.role ? getRoleType(user.role) : ""}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem>
          {user?.role && user.role > 1 ? (
            <DropdownItem tag="a" href="/crypto-wallet">
              <i className="bx bx-wallet font-size-16 align-middle me-1" />
              {props.t("My Wallet")}
            </DropdownItem>
          ) : null}
          {/* <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Settings")}
          </DropdownItem> */}
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Container className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <Button className="p-0" outline color="black" onClick={onLogout}>Logout</Button>
          </Container>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
