import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { GAMEDEV_GET_ACCOUNT_DATA, GET_CHARTS_DATA_DEV } from "./actionTypes";
import { GameDevGetAccountDataError, GameDevGetAccountDataSuccess, getChartsDataDevError, getChartsDataDevSuccess } from "./actions";
import { getFirebaseBackend } from "helpers/firebase_helper";
import { formatFirestoreTimestampDate, formatFirestoreTimestampTime, updateTimestamps } from "helpers/date_helper";

const fireBaseBackend = getFirebaseBackend();

const formatDate = item => {
    item.created_at = {
        date: formatFirestoreTimestampDate(item.created_at),
        time: formatFirestoreTimestampTime(item.created_at)
    };

    if (item.modified_at) {
        item.modified_at = {
            date: formatFirestoreTimestampDate(item.modified_at),
            time: formatFirestoreTimestampTime(item.modified_at)
        };
    }

    return item
}

function* getGameDevData({ payload }) {
    try {
        let data = yield call(fireBaseBackend.gameDevGetAccountData, payload);

        data = {
            ...data,
            allGames: data.allGames.map(formatDate),
        }

        yield put(GameDevGetAccountDataSuccess(data));
    } catch (error) {
        console.log(error);
        yield put(GameDevGetAccountDataError());
    }
}

function* chartsDataAdmin() {
    try {
        const data = yield call(fireBaseBackend.gameDevGetChartsData);

        data.users.dataLast30Days = updateTimestamps(data.users.dataLast30Days);
        data.users.data30To60Days = updateTimestamps(data.users.data30To60Days);
        data.users.dataAllTime = updateTimestamps(data.users.dataAllTime);
        data.revenue.dataLast30Days = updateTimestamps(data.revenue.dataLast30Days);
        data.revenue.data30To60Days = updateTimestamps(data.revenue.data30To60Days);
        data.revenue.dataAllTime = updateTimestamps(data.revenue.dataAllTime);

        yield put(getChartsDataDevSuccess(data));
    } catch (error) {
        console.log(error);
        yield put(getChartsDataDevError());
    }
}

export function* watchGameDev() {
    yield takeEvery(GAMEDEV_GET_ACCOUNT_DATA, getGameDevData);
    yield takeEvery(GET_CHARTS_DATA_DEV, chartsDataAdmin);
}

function* GameDevSaga() {
    yield all([fork(watchGameDev)]);
}

export default GameDevSaga;