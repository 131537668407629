import {
  GET_COMPANY_GAMES_SUCCESS,
  GET_COMPANY_GAMES_FAIL,
  GET_COMPANY_EMPLOYEES_SUCCESS,
  GET_COMPANY_EMPLOYEES_ERROR,
  INVITE_COMPANY_EMPLOYEE_SUCCESS,
  INVITE_COMPANY_EMPLOYEE_ERROR,
  UPDATE_USER_ROLE_SUCCESS,
  UPDATE_USER_ROLE_ERROR,
  DELETE_PROFILE_SUCCESS,
  DELETE_PROFILE_ERROR,
  GET_COMPANY_EMPLOYEES
} from "./actionTypes"

const INIT_STATE = {
  isFetchingCompanies: true,
  companies: [],
  companyDetail: null,
  companyGames: [],
  companyGameLogs: [],
  error: {},
  loading: true,
  data: null,
}

const Company = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY_GAMES_SUCCESS:
      return {
        ...state,
        companyGames: action.payload,
      }
    case GET_COMPANY_GAMES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_COMPANY_EMPLOYEES:
      return {
        ...state,
        loading: true
      };
      
    case GET_COMPANY_EMPLOYEES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };

    case GET_COMPANY_EMPLOYEES_ERROR:
      return {
        ...state,
        loading: false
      };

    case INVITE_COMPANY_EMPLOYEE_SUCCESS: {
      const employee = { email: action.payload.email, role: action.payload.role, last_name: action.payload.last_name, first_name: action.payload.first_name, docId: action.payload.id, isInvited: true };
      return {
        ...state,
        data: {
          ...state.data,
          employees: [employee, ...state.data.employees],
        },
        loading: false
      };
    }

    case INVITE_COMPANY_EMPLOYEE_ERROR: {
      return {
        ...state,
        loading: false
      };
      break;
    }

    case UPDATE_USER_ROLE_SUCCESS: {
      const { id, role } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          employees: state.data.employees.map(employee =>
            employee.docId === id ? { ...employee, role } : employee
          )
        },
        loading: false
      };
    }

    case UPDATE_USER_ROLE_ERROR: {
      return state = {
        ...state,
        loading: false
      };
    }

    case DELETE_PROFILE_SUCCESS: {
      const updatedEmployees = state.data.employees.filter(employee => employee.email !== action.payload.email);
      return {
        ...state,
        data: {
          ...state.data,
          employees: updatedEmployees,
        },
        loading: false
      };
    }

    case DELETE_PROFILE_ERROR: {
      state = {
        ...state,
        loading: false
      }
      break;
    }
    default:
      return state
  }
}

export default Company
