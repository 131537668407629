import React, { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import List from "components/List"
import { getBundle } from "store/actions"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import Loader from "components/Loader"
import getTableConfig from "./tableConfig"
import { Button } from "reactstrap"


const CompanyGamesOverview = () => {

    const dispatch = useDispatch();
    const [id, setId] = useState('')
    const { loading, bundle } = useSelector(state => state.Admin);
    const location = useLocation()
    const navigate = useNavigate();

    const tableConfig = useMemo(() => (
        getTableConfig()
    ), [])

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const id = queryParams.get("id")
        setId(id)
    }, [location.search])

    useEffect(() => {
        if (id) {
            dispatch(getBundle(id))
        }
    }, [dispatch, id])

    if (!tableConfig || loading) {
        return <Loader />
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="d-flex justify-content-end" style={{margin:'-10px 0px'}}>
                    <Button className="bg-transparent border-0 text-black" onClick={() => navigate(-1)}>Back to All Companies</Button>
                </div>
                <List
                    companies={bundle || []}
                    loading={loading}
                    tableConfig={tableConfig}
                    className='px-0'
                />
            </div>
        </React.Fragment >
    )
}

export default CompanyGamesOverview
