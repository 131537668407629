import React from "react"
import { Badge, Button } from "reactstrap"

export const tableConfigKeys = (toggleModalDeleteData) => ({
    columns: [
        {
            header: "Key",
            accessorKey: "key",
            enableColumnFilter: false,
            enableSorting: true,
            cell: cellProps => {
                return (
                    <p className="text-body m-0">
                        {cellProps.row.original.api_key}
                    </p>
                )
            },
        },
        {
            header: "Status",
            accessorKey: "status",
            enableColumnFilter: false,
            enableSorting: true,
            cell: cell => {
                return cell.row.original.active ? (
                    <Badge className="bg-success px-2 py-1" style={{ fontSize: '11px' }}>Active</Badge>
                ) : (
                    <Badge className="bg-danger px-2 py-1" style={{ fontSize: '11px' }}>Disabled</Badge>
                );
            },
        },
        {
            header: "Created Date",
            enableColumnFilter: false,
            enableSorting: true,
            accessorKey: "created_at",
            cell: cellProps => {
                return <span>{cellProps.row.original.created_at.date}</span>;
            },
        },
        {
            header: "Delete",
            cell: cell => {
                return (
                    <div className="d-flex gap-3">
                        <Button disabled={!cell.row.original.active}
                            className="text-danger bg-transparent shadow-none border-0"
                            onClick={() => toggleModalDeleteData({ id: cell.row.original.id })}
                        >
                            <i className="mdi mdi-trash-can font-size-20" />
                        </Button>
                    </div>
                )
            },
        },
    ],
    title: "Api Keys",
    search: 'Search Key',
    pt: '0',
})
