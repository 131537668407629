import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  CardSubtitle,
  Button,
  Form,
  FormGroup,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import Spinners from "components/Common/Spinner"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useParams } from "react-router-dom"
import firebase from "firebase/compat/app"

//review and delete

// Add the Firebase products that you want to use
import "firebase/compat/auth"
import "firebase/compat/firestore"

const MatchTypesTable = () => {
  //meta title
  document.title = "Match Types | Third Pillar"
  const { id } = useParams()
  const [matchTypesList, setMatchTypesList] = useState([])
  const [loading, setLoading] = useState(false)
  const [addLoading, setAddLoading] = useState(false)
  const [modal, setModal] = useState(false)
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      orderId: "",
      description: "",
      match_duration: 0,
      max_players: 2,
      payment_sandbox: false,
      real_money: false,
      pay_in_per_player: 10,
      created_at: "",
      modified_at: "",
    },
    validationSchema: Yup.object({
      description: Yup.string().required("Please Enter description"),
      match_duration: Yup.number().required("Please Enter Your match duration"),
      max_players: Yup.number().required("Please Enter Your maximum players"),
      pay_in_per_player: Yup.number().required(
        "Please Enter pay in per player"
      ),
      payment_sandbox: Yup.bool(),
      real_money: Yup.bool(),
    }),
    onSubmit: async values => {
      setAddLoading(true)
      if (id) {
        /* The above code is creating a reference to a specific document in a Firestore collection named
       "Game_Bundle" using the Firebase SDK. The `id` variable is used to specify which document in
       the collection to reference. */
        const gameBundleRef = firebase
          .firestore()
          .collection("Game_Bundle")
          .doc(id)
        // saving to firestore
        await firebase.firestore().collection("Match_Type").add({
          description: values["description"],
          match_duration: values["match_duration"],
          max_players: values["max_players"],
          payment_sandbox: values["payment_sandbox"],
          real_money: values["real_money"],
          pay_in_per_player: values["pay_in_per_player"],
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
          modified_at: firebase.firestore.FieldValue.serverTimestamp(),
          game_bundle: gameBundleRef,
        })

        //  reset the form
        validation.resetForm()
        // close the form
        toggle()
      }
      setAddLoading(false)
    },
  })
  const mockFunction = () => {
    return new Promise(resolve => {
      setTimeout(() => {
        // Simulate some asynchronous operation
        resolve()
      }, 3000) // Wait for 3 seconds
    })
  }
  // toggle modal visibility
  const toggle = () => {
    if (modal) {
      setModal(false)
    } else {
      setModal(true)
    }
  }

  useEffect(() => {
    if (id) {
      const gameBundleRef = firebase
        .firestore()
        .collection("Game_Bundle")
        .doc(id)
      const collection = firebase
        .firestore()
        .collection("Match_Type")
        .where("game_bundle", "==", gameBundleRef)
      const unsubscribe = collection.onSnapshot(async querySnapshot => {
        const companiesData = []

        try {
          // Use Promise.all to wait for all async operations to complete
          await Promise.all(
            querySnapshot.docs.map(async doc => {
              if (doc.exists) {
                const companyData = doc.data()

                companiesData.push({ ...companyData, id: doc.id })
              }
            })
          )

          console.log("gamesData", companiesData)
          // Resolve the promise with the companies data
          setMatchTypesList(companiesData)
        } catch (error) {
          setMatchTypesList([])
          console.error("Error setting up games listener:", error)
          // Handle errors that might occur during asynchronous operations
          // reject(error)
        }
        setLoading(false)
      })
      return () => unsubscribe()
    }
  }, [id])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Match Types"
            breadcrumbItem="Match Types Tables"
          />

          {loading ? (
            <Spinners />
          ) : (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    {/* <CardTitle className="h4">Match Types </CardTitle> */}
                    <CardSubtitle className="card-title-desc">
                      {/* Create responsive tables by wrapping any <code>.table</code>{" "}
                    in <code>.table-responsive</code>
                    to make them scroll horizontally on small devices (under
                    768px). */}
                      <Row className="mb-2 ">
                        <Col sm={12}>
                          <div className="text-sm-end">
                            <Button
                              type="button"
                              className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2 addOrder-modal"
                              onClick={() => toggle()}
                            >
                              <i className="mdi mdi-plus me-1"></i> Create a new
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </CardSubtitle>

                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Description</th>
                            <th>Max Players</th>
                            <th>Pay in (per player)</th>
                            <th>Payment Sandbox</th>
                            <th>Real Money</th>
                            <th>Duration</th>
                            <th>Created At</th>
                            <th>Modified At</th>
                          </tr>
                        </thead>
                        <tbody>
                          {matchTypesList && matchTypesList.length
                            ? matchTypesList.map((item, index) => (
                                <tr key={index}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{item.description}</td>
                                  <td>{item.max_players}</td>
                                  <td>{item.pay_in_per_player}</td>
                                  <td>{item.payment_sandbox.toString()}</td>
                                  <td>{item.real_money.toString()}</td>
                                  <td>{item.match_duration}</td>
                                  <td>
                                    {item.created_at.toDate().toDateString()}
                                  </td>
                                  <td>
                                    {item.modified_at.toDate().toDateString()}
                                  </td>
                                </tr>
                              ))
                            : null}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {"Add Match Type"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>Description</Label>
                      <Input
                        name="description"
                        type="text"
                        placeholder="Insert Billing Name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ""}
                        invalid={
                          validation.touched.description &&
                          validation.errors.description
                            ? true
                            : false
                        }
                      />
                      {validation.touched.description &&
                      validation.errors.description ? (
                        <FormFeedback type="invalid">
                          {validation.errors.description}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>match duration</Label>
                      <Input
                        name="match_duration"
                        type="number"
                        placeholder="Insert match duration"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.match_duration || ""}
                        invalid={
                          validation.touched.match_duration &&
                          validation.errors.match_duration
                            ? true
                            : false
                        }
                      />
                      {validation.touched.match_duration &&
                      validation.errors.match_duration ? (
                        <FormFeedback type="invalid">
                          {validation.errors.match_duration}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <FormGroup check className="mt-2 mb-2">
                      <Input
                        id="payment_sandbox"
                        name="payment_sandbox"
                        type="checkbox"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.real_money || false}
                      />
                      <Label check htmlFor="payment_sandbox">
                        Is Payment sandbox
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mt-2 mb-2">
                      <Input
                        id="real_money"
                        name="real_money"
                        type="checkbox"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.real_money || false}
                      />
                      <Label check htmlFor="real_money">
                        Is Real Money
                      </Label>
                    </FormGroup>
                    <div className="mb-3">
                      <Label>max players</Label>
                      <Input
                        name="max_players"
                        type="number"
                        placeholder="Insert max players"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.max_players || ""}
                        invalid={
                          validation.touched.max_players &&
                          validation.errors.max_players
                            ? true
                            : false
                        }
                      />
                      {validation.touched.max_players &&
                      validation.errors.max_players ? (
                        <FormFeedback type="invalid">
                          {validation.errors.max_players}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>pay in per player</Label>
                      <Input
                        name="pay_in_per_player"
                        type="number"
                        placeholder="Insert pay in per player"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.pay_in_per_player || ""}
                        invalid={
                          validation.touched.pay_in_per_player &&
                          validation.errors.pay_in_per_player
                            ? true
                            : false
                        }
                      />
                      {validation.touched.pay_in_per_player &&
                      validation.errors.pay_in_per_player ? (
                        <FormFeedback type="invalid">
                          {validation.errors.pay_in_per_player}
                        </FormFeedback>
                      ) : null}
                    </div>
                    {/* <div className="mb-3">
                      <Label>Payment Status</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.paymentStatus || ""}
                      >
                        <option>Paid</option>
                        <option>Chargeback</option>
                        <option>Refund</option>
                      </Input>
                      {validation.touched.paymentStatus &&
                      validation.errors.paymentStatus ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.paymentStatus}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Badge Class</Label>
                      <Input
                        name="badgeClass"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.badgeClass || ""}
                      >
                        <option>success</option>
                        <option>danger</option>
                        <option>warning</option>
                      </Input>
                      {validation.touched.badgeClass &&
                      validation.errors.badgeClass ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.badgeClass}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Method</Label>
                      <Input
                        name="paymentMethod"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.paymentMethod || ""}
                      >
                        <option>Mastercard</option>
                        <option>Visa</option>
                        <option>Paypal</option>
                        <option>COD</option>
                      </Input>
                      {validation.touched.paymentMethod &&
                      validation.errors.paymentMethod ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.paymentMethod}
                        </FormFeedback>
                      ) : null}
                    </div> */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        color="success"
                        type="submit"
                        className="save-user"
                        disabled={addLoading}
                      >
                        {addLoading && <Spinners />} Save
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

export default MatchTypesTable
