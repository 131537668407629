import { GET_ME_SUCCESS, LOGOUT_USER_SUCCESS, NO_USER} from "../login/actionTypes"

import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
} from "./actionTypes"

const initialState = {
  registrationError: null,
  message: null,
  loading: true,
  user: null,
  success: false,
}

const account = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      state = {
        ...state,
        loading: true,
        success: false,
        registrationError: null,
      }
      break
    case NO_USER: {
      state = {
        ...state,
        loading: false,
      }
      break;
    }
    case LOGOUT_USER_SUCCESS: {
      state = {
        ...state,
        user: null
      }

      break;
    }
    case GET_ME_SUCCESS: {
      state = {
        ...state,
        loading: false,
        user: action.payload,
        success: true,
        registrationError: null,
      }
      break;
    }
    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        success: true,
        registrationError: null,
      }
      break
    case REGISTER_USER_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        success: false,
        registrationError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default account
