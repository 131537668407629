import {
    PLAYER_GET_ACCOUNT_DATA, PLAYER_GET_ACCOUNT_DATA_ERROR, PLAYER_GET_ACCOUNT_DATA_SUCCESS
} from "./actionTypes";

export const PlayerGetAccountData = () => {
    return {
        type: PLAYER_GET_ACCOUNT_DATA,
    }
}

export const PlayerGetAccountDataSuccess = payload => {
    return {
        type: PLAYER_GET_ACCOUNT_DATA_SUCCESS,
        payload
    }
}

export const PlayerGetAccountDataError = () => {
    return {
        type: PLAYER_GET_ACCOUNT_DATA_ERROR,
    }
}