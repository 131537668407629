import React, { useEffect } from "react"
import { Container } from "reactstrap"
import { useSelector } from "react-redux"
import List from "components/List"
import { useDispatch } from "react-redux"
import { PlayerGetAccountData } from "store/actions"
import gamesTableConfig from "./tableConfigPlayer"

const PlayerGames = () => {
    const dispatch = useDispatch();
    const { data, loading } = useSelector(state => state.PlayerAccount);

    useEffect(() => {
        dispatch(PlayerGetAccountData());
    }, [dispatch]);

    return (
        <React.Fragment>
            <Container fluid>
                <List
                    companies={data?.transactions}
                    loading={loading}
                    tableConfig={gamesTableConfig}
                />
            </Container>
        </React.Fragment>
    )
}

export default PlayerGames