import {
  GET_ORDERS,
  GET_ORDERS_FAIL,
  GET_ORDERS_SUCCESS,
  ADD_NEW_ORDER,
  UPDATE_ORDER,
  DELETE_ORDER,
  GET_ORDERS_LOADING_STATUS
} from "./actionTypes"


export const getOrders = () => ({
  type: GET_ORDERS,
})

export const getOrdersSuccess = orders => ({
  type: GET_ORDERS_SUCCESS,
  payload: orders,
})

export const getOrdersFail = error => ({
  type: GET_ORDERS_FAIL,
  payload: error,
})
export const addNewOrder = order => ({
  type: ADD_NEW_ORDER,
  payload: order,
})
export const updateOrder = order => ({
  type: UPDATE_ORDER,
  payload: order,
})

export const deleteOrder = order => ({
  type: DELETE_ORDER,
  payload: order,
})

export const getOrdersLoadingSuccess = isloading => ({
  type: GET_ORDERS_LOADING_STATUS,
  payload: isloading,
})