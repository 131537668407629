import React from "react"
import PropTypes from "prop-types"
import { Card, CardBody, Col, Row, Badge } from "reactstrap"

const CompanyDetail = ({ company }) => {
  return (
    <Card>
      <CardBody>
        <div className="d-flex">

          <div
            className="d-flex justify-content-center align-items-center   me-4"
            style={{
              width: '65px',
              height: '65px',
              overflow: 'hidden',
            }}
          >
            <img
              src={company.company_logo}
              alt=""
              className="rounded-circle"
              style={{
                minWidth: '90%',
                minHeight: '90%',
                maxWidth: '150%',
                maxHeight: '150%',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </div>

          <div className="overflow-hidden d-flex justify-content-between w-100">
            <h5 className="text-truncate h5 m-0">{company.company}</h5>
            <div className=" d-flex aligm-items-start h-100">  {company.status === 0 ? (<Badge className="bg-warning" style={{ fontSize: '12px', height: '18px' }}>Pending</Badge>) : (<Badge className="bg-success" style={{ fontSize: '12px', height: '18px' }}>Live</Badge>)} </div>
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div>
            <h5 className="font-size-15 mt-4">Company Details :</h5>
            <p className="text-muted">{company.company_desc}</p>
            <div className="text-muted mt-4">
              {company.companyDetails &&
                (company.companyDetails.points || [])?.map((point, index) => (
                  <p key={index}>
                    <i className="mdi mdi-chevron-right text-primary me-1" />{" "}
                    {point}
                  </p>
                ))}
            </div>
          </div>
          
          <Col sm="2" xs="6" className="d-flex justify-content-end">
            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx bx-calendar me-1 text-primary" /> Created At
              </h5>
              <p className="text-muted mb-0">
                {company.created_at.date}
              </p>
            </div>
          </Col>
        </div>
      </CardBody>
    </Card>
  )
}

CompanyDetail.propTypes = {
  company: PropTypes.object,
}

export default CompanyDetail
