export const getRoleType = role => {
  switch (role) {
    case 0:
      return "Super Admin"
    case 1:
      return "Admin"
    case 2:
      return "Company Admin"
    case 3:
      return "User"
    case 4:
      return "Employee"
    default:
      return "Unknown Role"
  }
}
