import React from "react"
import { Link } from "react-router-dom";
import { Badge } from "reactstrap"

export const tableConfigKeys = () => ({
    columns: [
        {
            header: "Key",
            accessorKey: "key",
            enableColumnFilter: false,
            enableSorting: true,
            cell: cellProps => {
                return (
                    <Link
                        to={`/api-logs/?key=${cellProps.row.original.api_key}`}
                        className="text-dark"
                    >
                        <p className="text-body m-0">
                            {cellProps.row.original.api_key}
                        </p>
                    </Link>
                )
            },
        },
        {
            header: "Status",
            accessorKey: "status",
            enableColumnFilter: false,
            enableSorting: true,
            cell: cell => {
                return cell.row.original.active ? (
                    <Badge className="bg-success px-2 py-1" style={{ fontSize: '11px' }}>Active</Badge>
                ) : (
                    <Badge className="bg-danger px-2 py-1" style={{ fontSize: '11px' }}>Disabled</Badge>
                );
            },
        },
        {
            header: "Created Date",
            enableColumnFilter: false,
            enableSorting: true,
            accessorKey: "created_at",
            cell: cellProps => {
                return <span>{cellProps.row.original.created_at.date}</span>;
            },
        },
    ],
    title: "Api Keys",
    search: 'Search Key',
    pt: '0',
})
