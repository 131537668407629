import React, { useState } from "react"
import { Col, Input, Label, Modal, ModalBody, ModalHeader, Row, FormFeedback, Form, } from "reactstrap"
import Select from "react-select"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import { INVITE_COMPANY_EMPLOYEE } from "store/company/actionTypes"

const InviteModal = ({ toggle }) => {
    const dispatch = useDispatch()
    const [selectedUserRole, setSelectedUserRole] = useState({
        value: 4,
        label: 'User',
    })

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            role: 4,
        },
        validationSchema: Yup.object({
            role: Yup.number().required("User Role is Required"),
            email: Yup.string().email("Invalid email"),
            last_name: Yup.string().required("Last Name is Required"),
            first_name: Yup.string().required("First Name is email"),
        }),
        onSubmit: values => {
            dispatch({
                type: INVITE_COMPANY_EMPLOYEE,
                payload: {
                    email: values.email,
                    role: values.role,
                    first_name: values.first_name,
                    last_name: values.last_name
                },
            })
            validation.resetForm()
            toggle()
        },
    })

    return (
        <Modal isOpen={true} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle} tag="h4">
                Invite Member
            </ModalHeader>
            <ModalBody>
                <Form
                    onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                    }}
                >
                    <Row>
                        <Col xs={12}>
                            <div className="mb-3">
                                <Label className="form-label">First Name</Label>
                                <Input
                                    name="first_name"
                                    type="text"
                                    placeholder="First Name"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.first_name || ""}
                                    invalid={
                                        validation.touched.first_name && validation.errors.firstname
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.first_name && validation.errors.first_name ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.first_name}
                                    </FormFeedback>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Last Name</Label>
                                <Input
                                    name="last_name"
                                    type="text"
                                    placeholder="Last Name"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.last_name || ""}
                                    invalid={
                                        validation.touched.last_name && validation.errors.last_name
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.last_name && validation.errors.last_name ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.last_name}
                                    </FormFeedback>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Email</Label>
                                <Input
                                    name="email"
                                    type="text"
                                    placeholder="Email"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.email || ""}
                                    invalid={
                                        validation.touched.email &&
                                            validation.errors.email
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.email &&
                                    validation.errors.email ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.email}
                                    </FormFeedback>
                                ) : null}
                            </div>

                            <div className="mb-3">
                                <Label className="form-label">User Role</Label>
                                <Select
                                    options={[
                                        { value: 2, label: "Admin" },
                                        { value: 4, label: "User" },
                                    ]}
                                    styles={{
                                        menu: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: "#fff", // Set the background color for the dropdown menu
                                        }),
                                    }}
                                    name="role"
                                    value={selectedUserRole}
                                    onChange={e => {
                                        setSelectedUserRole(e)
                                        validation.setFieldValue("role", e.value)
                                    }}
                                // onBlur={validation.set}
                                />
                                {validation.touched.role && validation.errors.role ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.role}
                                    </FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="text-end">
                                <button
                                    type="submit"
                                    className="btn btn-success save-user"
                                >
                                    Invite
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default InviteModal;