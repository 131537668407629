import {
  GET_USER_PROFILE,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  USERS_LOADING_STATUS,
} from "./actionTypes"

export const getUsers = () => ({
  type: GET_USERS,
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})


export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const getUserProfile = () => ({
  type: GET_USER_PROFILE,
})


export const updateUser = (user, userId) => ({
  type: UPDATE_USER,
  payload: { user, userId },
})
export const updateUserFail = (user, userId) => ({
  type: UPDATE_USER_FAIL,
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const getUsersLoadingStatus = isLoading => ({
  type: USERS_LOADING_STATUS,
  payload: isLoading,
})
