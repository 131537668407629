import React from "react"
import { Link } from "react-router-dom"
import { Badge } from "reactstrap"

export const getTableConfig = () => ({
    columns: [{
        header: "Logo",
        id: "#",
        cell: cellProps => (
            <div
                className="d-flex justify-content-center align-items-center bg-light rounded"
                style={{
                    width: '40px',
                    height: '40px',
                    overflow: 'hidden',
                }}
            >
                <img
                    src={cellProps.row.original.company_logo}
                    alt=""
                    className="rounded-circle"
                    style={{
                        minWidth: '90%',
                        minHeight: '90%',
                        maxWidth: '150%',
                        maxHeight: '150%',
                        objectFit: 'cover',
                        objectPosition: 'center'
                    }}
                />
            </div>
        ),
        enableColumnFilter: false,
    },
    {
        header: "Company Name",
        accessorKey: "company",
        enableColumnFilter: false,
        cell: cellProps => {
            return (
                <>
                    <h5 className="text-truncate font-size-14">
                        <Link
                            to={`/company-games-overview/?id=${cellProps.row.original.id}`}
                            className="text-dark"
                        >
                            {cellProps.row.original.company}{" "}
                        </Link>
                    </h5>
                    <p className="text-truncate text-muted mb-0" style={{ display: 'block', width: '100%', maxWidth: '400px' }}>
                        {cellProps.row.original.company_desc}
                    </p>
                </>
            )
        },
    },
    {
        header: "Created Date",
        accessorKey: "created_at",
        enableColumnFilter: false,
        cell: cellProps => (
            <p>
                {cellProps.row.original.created_at.date}
            </p>
        ),
    },
    {
        header: "Status",
        accessorKey: "status",
        cell: cellProps => {
            switch (cellProps.row.original.status) {
                case 1:
                    return <Badge className="bg-success px-2 py-1" style={{ fontSize: '11px' }}>Live</Badge>
                case 0:
                    return <Badge className="bg-warning px-2 py-1" style={{ fontSize: '11px' }} >Pending</Badge>
            }
        },
        enableColumnFilter: false,
    }],
    title:'All companies',
    search: 'Search Company'
})

export default getTableConfig;