import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { createSelector } from 'reselect';

const PublicRoute = ({ children }) => {
    const AccountProperties = createSelector(
        state => state.Account,
        account => ({
            user: account.user,
            registrationError: account.registrationError,
            success: account.success,
        })
    )
    const { user } = useSelector(AccountProperties)

    return user ? <Navigate to="/dashboard" /> : children
};

export default PublicRoute;