import React from 'react'
import TableContainer from './Common/TableContainer'

const Table = ({ columns, data, placeholder = '', }) => {
    return (
        <TableContainer
            columns={columns.columns}
            data={data || []}
            isGlobalFilter={true}
            isPagination={true}
            SearchPlaceholder={placeholder}
            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
            pagination="pagination"
            tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
        />
    )
}

export default Table