import {
    GET_ALL_USERS,
    GET_ALL_USERS_ERROR,
    GET_ALL_USERS_SUCCESS,
    UPDATE_USER_ROLE_ADMIN_SUCCESS,
    UPDATE_USER_ROLE_ADMIN_ERROR,
    UPDATE_USER_ROLE_ADMIN,
    DELETE_PROFILE_ADMIN,
    DELETE_PROFILE_ADMIN_SUCCESS,
    DELETE_PROFILE_ADMIN_ERROR,
    INVITE_USER_SUCCESS,
    INVITE_USER,
    INVITE_USER_ERROR,
    GET_DASHBOARD_DATA,
    GET_DASHBOARD_DATA_SUCCESS,
    GET_DASHBOARD_DATA_ERROR,
    GET_COMPANIES,
    GET_COMPANIES_SUCCESS,
    GET_COMPANIES_ERROR,
    GET_BUNDLE,
    GET_BUNDLE_SUCCESS,
    GET_BUNDLE_ERROR,
    ADD_GAME,
    ADD_GAME_SUCCESS,
    ADD_GAME_ERROR,
    DELETE_BUNDLE,
    DELETE_BUNDLE_ERROR,
    DELETE_BUNDLE_SUCCESS,
    ADD_COMPANY,
    ADD_COMPANY_SUCCESS,
    ADD_COMPANY_ERROR,
    EDIT_COMPANY,
    EDIT_COMPANY_SUCCESS,
    EDIT_COMPANY_ERROR,
    UPDATE_BUNDLE_DATA,
    UPDATE_BUNDLE_DATA_SUCCESS,
    UPDATE_BUNDLE_DATA_ERROR,
    GET_CHARTS_DATA,
    GET_CHARTS_DATA_SUCCESS,
    GET_CHARTS_DATA_ERROR,
} from "./actionTypes"


export const getAllUsers = (payload) => {
    return {
        type: GET_ALL_USERS,
        payload
    }
}

export const getAllUsersSuccess = (payload) => {
    return {
        type: GET_ALL_USERS_SUCCESS,
        payload
    }
}

export const getAllUserError = (payload) => {
    return {
        type: GET_ALL_USERS_ERROR,
        payload
    }
}

export const getChartsData = (payload) => {
    return {
        type: GET_CHARTS_DATA,
        payload
    }
}

export const getChartsDataSuccess = (payload) => {
    return {
        type: GET_CHARTS_DATA_SUCCESS,
        payload
    }
}

export const getChartsDataError = (payload) => {
    return {
        type: GET_CHARTS_DATA_ERROR,
        payload
    }
}

export const inviteUser = (payload) => {
    return {
        type: INVITE_USER,
        payload
    }
}

export const inviteUserSuccess = (payload) => {
    return {
        type: INVITE_USER_SUCCESS,
        payload
    }
}

export const inviteUserError = (payload) => {
    return {
        type: INVITE_USER_ERROR,
        payload
    }
}

export const updateUserRoleAdmin = (payload) => {
    return {
        type: UPDATE_USER_ROLE_ADMIN,
        payload
    }
}

export const updateUserRoleAdminSuccess = (payload) => {
    return {
        type: UPDATE_USER_ROLE_ADMIN_SUCCESS,
        payload
    }
}

export const updateUserRoleAdminError = (payload) => {
    return {
        type: UPDATE_USER_ROLE_ADMIN_ERROR,
        payload
    }
}

export const deleteProfilAdmin = (payload) => {
    return {
        type: DELETE_PROFILE_ADMIN,
        payload
    }
}

export const deleteProfileAdminSuccess = (payload) => {
    return {
        type: DELETE_PROFILE_ADMIN_SUCCESS,
        payload
    }
}

export const deleteProfileAdminError = (payload) => {
    return {
        type: DELETE_PROFILE_ADMIN_ERROR,
        payload
    }
}

export const getDashboardData = (payload) => {
    return {
        type: GET_DASHBOARD_DATA,
        payload
    }
}

export const getDashboardDataSuccess = (payload) => {
    return {
        type: GET_DASHBOARD_DATA_SUCCESS,
        payload
    }
}

export const getDashboardDataError = (payload) => {
    return {
        type: GET_DASHBOARD_DATA_ERROR,
        payload
    }
}

export const getCompanies = () => ({
    type: GET_COMPANIES,
})

export const getCompaniesSuccess = (payload) => ({
    type: GET_COMPANIES_SUCCESS,
    payload: payload,
})

export const getCompaniesError = error => ({
    type: GET_COMPANIES_ERROR,
    payload: error,
})
export const addCompany = (payload) => ({
    type: ADD_COMPANY,
    payload: payload
})

export const addCompanySuccess = (payload) => ({
    type: ADD_COMPANY_SUCCESS,
    payload: payload,
})

export const addCompanyError = error => ({
    type: ADD_COMPANY_ERROR,
    payload: error,
})

export const updateBundleData = (payload) => {
    return {
        type: UPDATE_BUNDLE_DATA,
        payload
    }
}

export const updateBundleDataSuccess = payload => {
    return {
        type: UPDATE_BUNDLE_DATA_SUCCESS,
        payload
    }
}

export const updateBundleDataError = () => {
    return {
        type: UPDATE_BUNDLE_DATA_ERROR,
    }
}

export const editCompany = (payload) => ({
    type: EDIT_COMPANY,
    payload: payload
})

export const editCompanySuccess = (payload) => ({
    type: EDIT_COMPANY_SUCCESS,
    payload: payload,
})

export const editCompanyError = error => ({
    type: EDIT_COMPANY_ERROR,
    payload: error,
})


export const getBundle = (payload) => ({
    type: GET_BUNDLE,
    payload: payload
})

export const getBundleSuccess = (payload) => ({
    type: GET_BUNDLE_SUCCESS,
    payload: payload,
})

export const getBundleError = error => ({
    type: GET_BUNDLE_ERROR,
    payload: error,
})

export const addGame = (payload) => ({
    type: ADD_GAME,
    payload: payload
})

export const addGameSuccess = (payload) => ({
    type: ADD_GAME_SUCCESS,
    payload: payload,
})

export const addGameError = error => ({
    type: ADD_GAME_ERROR,
    payload: error,
})

export const deleteBundle = (payload) => ({
    type: DELETE_BUNDLE,
    payload: payload
})

export const deleteBundleSuccess = (payload) => ({
    type: DELETE_BUNDLE_SUCCESS,
    payload: payload,
})

export const deleteBundleError = error => ({
    type: DELETE_BUNDLE_ERROR,
    payload: error,
})