import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Dropzone from "react-dropzone"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Label,
  Row,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// FlatPickr
import "flatpickr/dist/themes/material_blue.css"
import FlatPickr from "react-flatpickr"

import * as Yup from "yup"
import { useFormik } from "formik"
import moment from "moment"
import SimpleBar from "simplebar-react"
import { projectAssignedTo } from "../../common/data"
// import { createCompany, getUsersList } from "store/actions"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"

const ProjectsCreate = () => {
  //meta title
  document.title = "Create New Company | Third Pillar"

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectedFiles, setSelectedFiles] = useState([])
  const [selectedImage, setSelectedImage] = useState(null)
  const [imgStore, setImgStore] = useState([])
  const [dropList, setDropList] = useState(false)
  const [active, setActive] = useState(0)
  const [adminDropList, setAdminDropList] = useState(false)
  const [dropdownForMemebersOpen, setDropdownForMemebersOpen] = useState(false)

  const handleAcceptedFiles = files => {
    const newImages = files?.map(file => {
      return Object.assign(file, {
        priview: URL.createObjectURL(file),
      })
    })
    setSelectedFiles([...selectedFiles, ...newImages])
  }

  const searchByEmail = (array, searchTerm) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase()

    return array.filter(item => {
      // Check if the email field exists and contains the search term
      return (
        item.email && item.email.toLowerCase().includes(lowerCaseSearchTerm)
      )
    })
  }

  //  img upload
  const handleImageChange = event => {
    event.preventDefault()
    let reader = new FileReader()
    let file = event.target.files[0]
    reader.onloadend = () => {
      setSelectedImage(reader.result)
      validation.setFieldValue("company_logo", reader.result)
    }
    reader.readAsDataURL(file)
  }

  // const handleFileInputChange = (event) => {
  //   const file = event.target.files[0];

  //   if (file) {
  //     const reader = new FileReader();

  //     reader.onloadend = () => {
  //       // The result property contains the base64-encoded string
  //       const base64String = reader.result;
  //       console.log(base64String);
  //     };

  //     // Read the file as a data URL (base64)
  //     reader.readAsDataURL(file);
  //   }
  // };
  const ProjectsProjectProperties = createSelector(
    state => state.projects,
    Projects => ({
      usersList: Projects.usersList,
      projects: Projects.projects,
      loading: Projects.loading,
    })
  )

  const { loading, projects, usersList } = useSelector(
    ProjectsProjectProperties
  )

  const handleClick = item => {
    const isItemInImgStore = imgStore.some(imgItem => imgItem.id === item.id)
    setActive(item.id)
    console.log("isItemInImgStore", isItemInImgStore)
    if (!isItemInImgStore) {
      const newData = [...imgStore, item]
      setImgStore(newData)
      validation.setFieldValue("team", newData)
    } else {
      const newData = imgStore.filter(imgItem => imgItem.id !== item.id)
      console.log("newData", newData.id, imgStore[0].id)
      setImgStore(newData)
      validation.setFieldValue("team", newData)
    }
  }

  const handleSelectAdmin = admin => {
    validation.setFieldValue("admin", admin)
  }

  // validation
  const validation = useFormik({
    initialValues: {
      company: "",
      company_desc: "",
      admin: "",
      company_logo: null,
      platform_fee: 10,
      rev_share: 10,
    },
    validationSchema: Yup.object({
      company: Yup.string().required("Please Enter Your Company Name"),
      company_desc: Yup.string().required("Please Enter Your Project Desc"),
      admin: Yup.string()
        .email("Invalid email address")
        .required("Admin Email is required"),
      company_logo: Yup.string().required("Logo is required"),
      platform_fee: Yup.number().required("Platform Fee is Invalid"),
      rev_share: Yup.number().required("Platform Fee is Invalid"),
    }),
    onSubmit: values => {
      console.log("values", values)
      dispatch(
        createCompany({ ...values, active: Boolean(values.active) }, navigate)
      )
      // console.log(values);
    },
  })

  useEffect(() => {
    dispatch(getUsersList())
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projects" breadcrumbItem="Create New Company" />
          <Form
            id="createproject-form"
            onSubmit={e => {
              e.preventDefault()

              validation.handleSubmit()
              return false
            }}
          >
            {console.log("validation.errors", validation.errors)}
            <Row>
              <Col lg={8}>
                <Card>
                  <CardBody>
                    <input
                      type="hidden"
                      className="form-control"
                      id="formAction"
                      name="formAction"
                      defaultValue="add"
                    />
                    <input
                      type="hidden"
                      className="form-control"
                      id="project-id-input"
                    />
                    <div className="mb-3">
                      <Label htmlFor="projectname-input">Company Name</Label>
                      <Input
                        id="company"
                        name="company"
                        type="text"
                        placeholder="Enter Company Name..."
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.company || ""}
                      />
                      {validation.touched.company &&
                      validation.errors.company ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.company}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Company Logo</Label>

                      <div className="text-center">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute bottom-0 end-0">
                            <Label
                              htmlFor="project-image-input"
                              className="mb-0"
                              id="projectImageInput"
                            >
                              <div className="avatar-xs">
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer shadow font-size-16">
                                  <i className="bx bxs-image-alt"></i>
                                </div>
                              </div>
                            </Label>
                            <UncontrolledTooltip
                              placement="right"
                              target="projectImageInput"
                            >
                              Select Image
                            </UncontrolledTooltip>
                            <input
                              className="form-control d-none"
                              id="project-image-input"
                              type="file"
                              accept="image/png, image/gif, image/jpeg"
                              onChange={handleImageChange}
                            />
                          </div>
                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded-circle">
                              <img
                                src={selectedImage || ""}
                                id="projectlogo-img"
                                alt=""
                                className="avatar-md h-auto rounded-circle"
                              />
                            </div>
                          </div>
                        </div>
                        {validation.touched.company_logo &&
                        validation.errors.company_logo ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.company_logo}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="projectdesc-input">
                        Company Description
                      </Label>
                      <Input
                        as="textarea"
                        id="company_desc"
                        rows={3}
                        name="company_desc"
                        placeholder="Enter Company Description..."
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.company_desc || ""}
                      />
                      {validation.touched.company_desc &&
                      validation.errors.company_desc ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.company_desc}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="projectdesc-input">
                        Assign Admin Email
                      </Label>
                      <Input
                        as="textarea"
                        id="admin"
                        rows={3}
                        name="admin"
                        placeholder="Enter Company Admin Email..."
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.admin || ""}
                      />
                      {validation.touched.admin && validation.errors.admin ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.admin}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={4}>
                <Card>
                  <CardBody>
                    <h5 className="card-title mb-3">Info</h5>
                    <div className="mb-3">
                      <Label htmlFor="project-status-input">Platform Fee</Label>
                      <Input
                        as="textarea"
                        id="platform_fee"
                        rows={3}
                        name="platform_fee"
                        placeholder="Enter Platform Fee..."
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.platform_fee || ""}
                      />
                      <div className="invalid-feedback">
                        Please provide platform fee.
                      </div>
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="project-status-input">
                        Revenue Share
                      </Label>
                      <Input
                        as="textarea"
                        id="rev_share"
                        rows={3}
                        name="rev_share"
                        placeholder="Enter Revenue Fee..."
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.rev_share || ""}
                      />
                      <div className="invalid-feedback">
                        Please provide revenue fee.
                      </div>
                    </div>
                  </CardBody>
                </Card>

                {/* <Card>
                  <CardBody>
                    <h5 className="card-title mb-3">Due Date</h5>
                    <FlatPickr
                      className="form-control d-block"
                      id="orderdate"
                      name="startdate"
                      placeholder="Select date"
                      options={{
                        mode: "single",
                        dateFormat: "d M, Y",
                      }}
                      onChange={customerdate =>
                        validation.setFieldValue(
                          "startdate",
                          moment(customerdate[0]).format("DD MMMM ,YYYY")
                        )
                      }
                      value={validation.values.startdate || ""}
                    />
                    {validation.errors.startdate &&
                    validation.touched.startdate ? (
                      <FormFeedback type="invalid" className="d-block">
                        {validation.errors.startdate}
                      </FormFeedback>
                    ) : null}
                  </CardBody>
                </Card> */}
              </Col>
              <Col lg={8}>
                <div className="text-end mb-4">
                  <Button type="submit" color="primary">
                    Create Project
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProjectsCreate
