import React, { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import List from "components/List"
import { getCompanies } from "store/actions"
import Loader from "components/Loader"
import { Container } from "reactstrap"
import getTableConfig from "./tableConfigAdmin"

const AdminLogs = () => {
    const dispatch = useDispatch();
    const { companies, loading } = useSelector(state => state.Admin);
    const tableConfig = useMemo(() => (
        getTableConfig()
    ), [])

    useEffect(() => {
        dispatch(getCompanies())
    }, [dispatch])

    if (!tableConfig || loading) {
        return <Loader />
    }

    return (
        <React.Fragment>
            <List
                companies={companies ?? ''}
                loading={loading}
                tableConfig={tableConfig}
            />
        </React.Fragment>
    )
}

export default AdminLogs;