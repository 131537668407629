import React, { useState } from "react";
import JSONPretty from "react-json-pretty";
import { Modal, ModalHeader, ModalBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

const getStatusColor = (status) => {
    if (status >= 200 && status < 300) {
        return 'text-success';
    } else if (status >= 400 && status < 500) {
        return 'text-warning';
    } else if (status >= 500) {
        return 'text-danger';
    }
    return 'text-secondary';
};


const InfoModal = ({ modalData, toggle }) => {
    const { isOpen, data } = modalData;
    const [activeTab, setActiveTab] = useState('1');
    const { totalTime, status, url, ipAddress, userAgent, method, timestamp } = data;

    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const tabStyle = {
        height: '500px',
        overflow: 'auto',
        backgroundColor: '#ffffff'
    };

    return (
        <Modal size="lg" isOpen={isOpen} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle} tag="h4">
                API Log Details
            </ModalHeader>
            <ModalBody>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={activeTab === '1' ? 'active' : ''}
                            onClick={() => { toggleTab('1'); }}
                        >
                            Overall
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={activeTab === '2' ? 'active' : ''}
                            onClick={() => { toggleTab('2'); }}
                        >
                            Request Body
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={activeTab === '3' ? 'active' : ''}
                            onClick={() => { toggleTab('3'); }}
                        >
                            Response Body
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} className="mt-4">
                    <TabPane tabId="1" style={tabStyle}>
                        <div style={{ width: '100%' }} className="container">
                            <div className="row" style={{ borderBottom: '1px solid #eff2f7', padding: '15px 0px' }}>
                                <strong className="col-3">Date and Time:</strong>
                                <p className="col-6 text-right m-0">{timestamp}</p>
                            </div>
                            <div className="row" style={{ borderBottom: '1px solid #eff2f7', padding: '15px 0px' }}>
                                <strong className="col-3">Status:</strong>
                                <span className={`col-6 text-right ${getStatusColor(status)}`}>{status}</span>
                            </div>
                            <div className="row" style={{ borderBottom: '1px solid #eff2f7', padding: '15px 0px' }}>
                                <strong className="col-3">Endpoint:</strong>
                                <p className="col-6 text-right m-0">{url}</p>
                            </div>
                            <div className="row" style={{ borderBottom: '1px solid #eff2f7', padding: '15px 0px' }}>
                                <strong className="col-3">Total Request Time:</strong>
                                <p className="col-6 text-right m-0">{totalTime}</p>
                            </div>
                            <div className="row" style={{ borderBottom: '1px solid #eff2f7', padding: '15px 0px' }}>
                                <strong className="col-3">IP Address:</strong>
                                <p className="col-6 text-right m-0">{ipAddress}</p>
                            </div>
                            <div className="row " style={{ borderBottom: '1px solid #eff2f7', padding: '15px 0px' }}>
                                <strong className="col-3">User Agent:</strong>
                                <p className="col-6 text-right m-0">{userAgent}</p>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tabId="2" style={tabStyle}>

                        <JSONPretty
                            id="json-pretty-request"
                            data={data.header}
                            theme={{
                                main: "line-height:1.3; color:#b0b0b0; background:#333333; overflow:auto; font-size:13px; font-family:monospace;",
                                error: "color:#ff6347;",
                                key: "color: #8fbc8f;",
                                string: "color: #dda0dd;",
                                value: "color: #b0c4de;",
                                boolean: "color: #f0e68c;",
                            }}
                        ></JSONPretty>

                        <JSONPretty
                            id="json-pretty-request"
                            data={data.requestBody}
                            theme={{
                                main: "line-height:1.3; color:#b0b0b0; background:#333333; overflow:auto; font-size:13px; font-family:monospace;",
                                error: "color:#ff6347;",
                                key: "color: #8fbc8f;",
                                string: "color: #dda0dd;",
                                value: "color: #b0c4de;",
                                boolean: "color: #f0e68c;",
                            }}
                        ></JSONPretty>
                    </TabPane>
                    <TabPane tabId="3" style={tabStyle}>
                        <JSONPretty
                            id="json-pretty-request"
                            data={data.responseBody}
                            theme={{
                                main: "line-height:1.3; color:#b0b0b0; background:#333333; overflow:auto; font-size:13px; font-family:monospace;",
                                error: "color:#ff6347;",
                                key: "color: #8fbc8f;",
                                string: "color: #dda0dd;",
                                value: "color: #b0c4de;",
                                boolean: "color: #f0e68c;",
                            }}
                        ></JSONPretty>
                    </TabPane>
                </TabContent>
            </ModalBody>
        </Modal >
    );
}

export default InfoModal;