import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { PLAYER_GET_ACCOUNT_DATA } from "./actionTypes";
import { PlayerGetAccountDataError, PlayerGetAccountDataSuccess } from "./actions";
import { getFirebaseBackend } from "helpers/firebase_helper";
import { formatFirestoreTimestampDate, formatFirestoreTimestampTime } from "helpers/date_helper";

const fireBaseBackend = getFirebaseBackend();

function* getData({ payload }) {
    try {
        const data = yield call(fireBaseBackend.playerGetAccountData, payload);

        const updatedData = {
            ...data,
            transactions: data.transactions.map(transaction => ({
                ...transaction,
                created_at: {
                    date: formatFirestoreTimestampDate(transaction.created_at),
                    time: formatFirestoreTimestampTime(transaction.created_at)
                }
            }))
        };

        yield put(PlayerGetAccountDataSuccess(updatedData));
    } catch (error) {
        console.log(error);
        yield put(PlayerGetAccountDataError());
    }
}

export function* watchPlayer() {
    yield takeEvery(PLAYER_GET_ACCOUNT_DATA, getData);
}

function* PlayerSaga() {
    yield all([fork(watchPlayer)]);
}

export default PlayerSaga;
