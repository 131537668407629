import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "./Common/ChartsDynamicColor"
import { aggregateTransactionsData, aggregateUserData, formatSeriesData, generateDaysForCurrentMonth } from "helpers/charts"

const AdminChart = ({ dataColors, title, data, seriesName, yaxisTitle }) => {
    const [selectedMonth, setSelectedMonth] = useState('30');
    const [seriesData, setSeriesData] = useState([]);

    const thisPeriod = seriesName == 'Revenue' ? data?.dataLast30Days?.reduce((acc, item) => {
        return acc + (item.money || 0);
    }, 0) : data?.dataLast30Days?.length || 0;

    const prevPeriod = seriesName == 'Revenue' ? data?.data30To60Days?.reduce((acc, item) => {
        return acc + (item.money || 0);
    }, 0) : data?.data30To60Days?.length || 0;

    const calculatePercentageChange = (current, previous) => {
        if (previous === 0) {
            return current === 0 ? 0 : 100;
        }
        return ((current - previous) / previous * 100).toFixed(1);
    };

    const percentageChange = calculatePercentageChange(
        data?.dataLast30Days?.length || 0,
        data?.data30To60Days?.length || 0
    );

    useEffect(() => {
        let userData;
        if (selectedMonth === '30') {
            userData = data.dataLast30Days;
        } else if (selectedMonth === '60') {
            userData = data.data30To60Days;
        } else {
            userData = data.dataAllTime;
        }
        const userCountsByDate = seriesName == 'Revenue' ? aggregateTransactionsData(userData) : aggregateUserData(userData)
        const newSeriesData = formatSeriesData(userCountsByDate);
        setSeriesData(newSeriesData);
    }, [selectedMonth, data]);

    const options = {
        chart: {
            type: 'line',
            height: 320,
            toolbar: { show: false },
            dropShadow: {
                enabled: true,
                color: "#000",
                top: 18,
                left: 7,
                blur: 8,
                opacity: 0.2,
            },
        },
        colors: getChartColorsArray(dataColors),
        xaxis: {
            type: 'datetime',
            categories: generateDaysForCurrentMonth(),
            title: { text: 'Date' }
        },
        yaxis: {
            title: { text: yaxisTitle }
        },
        tooltip: {
            x: { format: 'dd MMM yyyy' }
        }
    };

    const series = [{
        name: seriesName,
        data: seriesData
    }];

    return (
        <React.Fragment>
            <Col xl="12">
                <Card>
                    <CardBody>
                        <div className="clearfix">
                            <div className="float-end">
                                <div className="input-group input-group-sm">
                                    <select
                                        className="form-select form-select-sm"
                                        value={selectedMonth}
                                        onChange={e => setSelectedMonth(e.target.value)}
                                    >
                                        <option value="30">Last 30 Days</option>
                                        <option value="60">Previous Period</option>
                                        <option value="all">All</option>
                                    </select>
                                    <label className="input-group-text">Period</label>
                                </div>  
                            </div>
                            <h4 className="card-title mb-4">{title}</h4>
                        </div>

                        <Row>
                            <Col lg="4">
                                <div className="text-muted">
                                    <div className="mb-4">
                                        <p>Last 30 Days</p>
                                        <h4>{seriesName === 'Revenue' ? `$${thisPeriod.toFixed(2)}` : thisPeriod}</h4>
                                    </div>
                                    <span className={`badge ${percentageChange >= 0 ? 'badge-soft-success' : 'badge-soft-danger'} font-size-12 me-1`}>
                                        {percentageChange >= 0 ? `+${percentageChange}%` : `${percentageChange}%`} From previous period
                                    </span>
                                    <div className="mt-4">
                                        <p className="mb-2">Previous Period</p>
                                        <h4>{seriesName === 'Revenue' ? `$${prevPeriod.toFixed(2)}` : prevPeriod}</h4>
                                    </div>
                                </div>
                            </Col>

                            <Col lg="8">
                                <div id="line-chart" dir="ltr">
                                    <ReactApexChart
                                        series={series}
                                        options={options}
                                        type="line"
                                        height={320}
                                        className="apex-charts"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment >
    )
}

export default AdminChart
