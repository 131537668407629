export const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
}

export const generateDaysForCurrentMonth = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const daysInMonth = getDaysInMonth(year, month);

    const daysArray = [];
    for (let day = 1; day <= daysInMonth; day++) {
        const date = new Date(year, month, day);
        const dateString = date.toISOString().substring(0, 10);
        daysArray.push(dateString);
    }
    return daysArray;
}

export const aggregateUserData = (userData) => {
    const counts = {};
    userData.forEach(item => {
        counts[item.created_at] = (counts[item.created_at] || 0) + 1;
    });
    return counts;
};

export const aggregateTransactionsData = (userData) => {
    const sums = {};
    userData.forEach(item => {
        if (item.money != null) {
            if (sums[item.created_at]) {
                sums[item.created_at] += item.money;
            } else {
                sums[item.created_at] = item.money;
            }
        }
    });
    return sums;
};

export const formatSeriesData = (aggregatedData) => {
    return Object.entries(aggregatedData).map(([date, count]) => ({ x: date, y: count }));
};