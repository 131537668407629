import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//Company
import Company from "./company/reducer"

//E-commerce
import Ecommerce from "./e-commerce/reducer"

//contacts
import contacts from "./contacts/reducer"

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer"

//Dasboard blog
import DashboardBlog from "./dashboard-blog/reducer"

//Dasboard job
import DashboardJob from "./dashboard-jobs/reducer"

//Player account 
import PlayerAccount from "./player/reducer"

//gamedev account 
import GameDevAccount from "./gameDeveloper/reducer"

//Bundle details
import BundleDetails from "./bundleDetails/reducer"
import Admin from "./admin/reducer"
import ApiLogs from "./apiLogs/reducer"

const rootReducer = combineReducers({
  // public
  GameDevAccount,
  ApiLogs,
  Admin,
  BundleDetails,
  PlayerAccount,
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Company,
  Ecommerce,
  contacts,
  DashboardSaas,
  DashboardBlog,
  DashboardJob,
})

export default rootReducer
