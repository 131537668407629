import {
    ADD_KEY,
    ADD_KEY_ERROR,
    ADD_KEY_SUCCESS,
    DELETE_KEY,
    DELETE_KEY_ERROR,
    DELETE_KEY_SUCCESS,
    GET_BUNDLE_DATA, GET_BUNDLE_DATA_ERROR, GET_BUNDLE_DATA_SUCCESS
} from "./actionTypes";

export const getBundleData = (payload) => {
    return {
        type: GET_BUNDLE_DATA,
        payload
    }
}

export const getBundleDataSuccess = payload => {
    return {
        type: GET_BUNDLE_DATA_SUCCESS,
        payload
    }
}

export const getBundleDataError = () => {
    return {
        type: GET_BUNDLE_DATA_ERROR,
    }
}

export const addKey = (payload) => {
    return {
        type: ADD_KEY,
        payload
    }
}

export const addKeySuccess = payload => {
    return {
        type: ADD_KEY_SUCCESS,
        payload
    }
}

export const addKeyError = () => {
    return {
        type: ADD_KEY_ERROR,
    }
}

export const deleteKey = (payload) => {
    return {
        type: DELETE_KEY,
        payload
    }
}

export const deleteKeySuccess = payload => {
    return {
        type: DELETE_KEY_SUCCESS,
        payload
    }
}

export const deleteKeyError = () => {
    return {
        type: DELETE_KEY_ERROR,
    }
}