import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import {
  registerUser,
  apiError,
  registerWVUser,
  socialLogin,
  registerUserFailed,
} from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import { Link, useLocation, useNavigate } from "react-router-dom"

// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"

const RegisterWV = props => {
  //meta title
  document.title = "Register | Third Pillar"

  const [gameBundleId, setGameBundleId] = useState(null)
  const [playerId, setPlayerId] = useState(null)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    // Use URLSearchParams to parse the query parameters from the location
    const queryParams = new URLSearchParams(location.search)

    // Get the value of a specific parameter, e.g., 'paramName'
    const gameBundleIdValue = queryParams.get("game_bundle_id")
    setGameBundleId(gameBundleIdValue)
    const playerIdValue = queryParams.get("player_id")
    setPlayerId(playerIdValue)

    console.log("Query Parameter Value:", gameBundleIdValue, playerIdValue)
  }, [location.search])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      // username: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      // username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      if (!gameBundleId) {
        // dispatch(registerWVUser(values, navigate)) // handle error
        return null
      }
      dispatch(registerWVUser(values, navigate, gameBundleId, playerId))
    },
  })

  const signIn = type => {
    console.log("playerId")
    dispatch(socialLogin(type, navigate, "WV", gameBundleId, playerId))
  }

  //for facebook and google authentication
  const socialResponse = type => {
    signIn(type)
  }

  const AccountProperties = createSelector(
    state => state.Account,
    account => ({
      user: account.user,
      registrationError: account.registrationError,
      success: account.success,
      loading: account.loading,
    })
  )

  const { user, registrationError, success, loading } =
    useSelector(AccountProperties)

  useEffect(() => {
    dispatch(apiError(""))
    dispatch(registerUserFailed(""))
  }, [])

  // useEffect(() => {
  //   success && setTimeout(() => navigate("/login"), 2000)
  // }, [success])

  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div> */}
      <div className="account-pages my-5 pt-sm-0">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                {/* <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Free Register</h5>
                        <p>Get your free Third Pillar account now.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div> */}
                <CardBody className="pt-0">
                  {/* <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div> */}
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      style={{
                        flexDirection: "row",
                      }}
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        // return false
                      }}
                    >
                      {/* {user && user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}
                       */}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}
                      <Row>
                        <Col className="w-50">
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              type="password"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>
                          {/* <div className="mt-4">
                            <Link to="/forgot-password" className="text-muted">
                              <i className="mdi mdi-lock me-1" />
                              Forgot your password?
                            </Link>
                          </div> */}
                        </Col>

                        <Col className="d-flex flex-column align-items-center">
                          <div
                            style={{
                              marginTop: 28,
                              marginBottom: 15,
                              width: "100%",
                            }}
                          >
                            <button
                              className="btn btn-primary btn-block w-100"
                              type="submit"
                              disabled={loading}
                            >
                              Register
                            </button>
                          </div>
                          <p className="m-0">or Sign up with</p>
                          {/* <ul className="list-inline w-100 mt-0 m-0 p-0">
                            <li className="list-inline-item"></li> */}
                          {/* <li className="list-inline-item w-100"> */}
                          <button
                            className="btn btn-primary btn-block w-100 d-flex justify-content-center mt-2"
                            type="button"
                            style={{
                              background: "white",
                              border: "1px solid #007BFF", // Replace with your primary color
                              color: "#007BFF", // Replace with your primary color
                            }}
                            onClick={e => {
                              e.preventDefault()
                              socialResponse("google")
                            }}

                            // disabled={loading}
                          >
                            <span style={{ color: "#4285F4" }} className="px-2">
                              <i
                                className="mdi mdi-google"
                                style={{ color: "#4285F4" }}
                              />
                            </span>
                            Continue with Google
                          </button>
                          {/* </li>
                          </ul> */}
                        </Col>
                      </Row>
                    </Form>
                    <div className="mt-4 text-center">
                      <p className="mb-0">
                        Already have an account ?{" "}
                        <Link
                          to={`/wv/login?player_id=${gameBundleId}&game_bundle_id=${playerId}`}
                          className="text-primary"
                        >
                          {" "}
                          Login
                        </Link>{" "}
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  By registering you agree to the Third Pillar{" "}
                  <Link to="#" className="font-weight-medium text-primary">
                    Terms of Use
                  </Link>
                </p>
                <p>© {new Date().getFullYear()} Third Pillar.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RegisterWV
