import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { getMe, noUser} from "store/actions"
import firebase from "firebase/compat/app"
import axios from "axios"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import Loader from "components/Loader"

const getLoginLoading = createSelector(
  state => state.Account,
  account => account.loading
)

const Authmiddleware = props => {
  const dispatch = useDispatch()
  const isLoading = useSelector(getLoginLoading);

  const onAuthStateChanged = async (user) => {
    if (user) {   
      const token = await user.getIdToken(true);
      if (token) {
        localStorage.setItem('authToken',token)
        dispatch(getMe())
      }
    } else {
      localStorage.removeItem('authUser')
      localStorage.removeItem('authToken')
      dispatch(noUser())
    }
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged(onAuthStateChanged);
  }, [])
  return <React.Fragment>{isLoading ? <Loader/> : props.children}</React.Fragment>
}

export default Authmiddleware
