import React from "react"

const gamesTableConfig = {
    columns: [
        {
            header: "ID",
            accessorKey: "id",
            enableColumnFilter: false,
            enableSorting: true,
            cell: cellProps => {
                return (
                    <p className="text-body m-0">
                        {cellProps.row.original.id}
                    </p>
                )
            },
        },
        {
            header: "Description",
            enableColumnFilter: false,
            enableSorting: true,
            accessorKey: "description",
            cell: cellProps => (
                <p className="text-truncate m-0">
                    {cellProps.row.original.description}
                </p>
            ),
        },
        {
            header: "Match Duration (seconds)",
            enableColumnFilter: false,
            enableSorting: true,
            accessorKey: "duration",
            cell: cellProps => (
                <p className="text-truncate m-0">
                    {cellProps.row.original.match_duration}
                </p>
            ),
        },
        {
            header: "Max Players",
            enableColumnFilter: false,
            enableSorting: true,
            accessorKey: "game_bundle_id",
            cell: cellProps => (
                <p className="text-truncate m-0">
                    {cellProps.row.original.max_players}
                </p>
            ),
        },
        {
            header: "Pay In Per Player ($)",
            enableColumnFilter: false,
            enableSorting: true,
            accessorKey: "created_at",
            cell: cellProps => (
                <p className="text-truncate m-0">
                    {cellProps.row.original.pay_in_per_player}
                </p>
            ),
        },
    ],
    title: "Match Types",
    isAddButton: false,
    pt: '0',
    pb: '0',
    search: 'Search Match'
}

export default gamesTableConfig;