import React, { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { getBundleData } from "store/bundleDetails/actions"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import List from "components/List"
import { Container, Button } from "reactstrap"
import { createSelector } from "reselect"
import Loader from "components/Loader"
import { tableConfigKeys } from "./tableConfig"
import GameDescription from "components/GameDescription"

const KeysList = () => {
    const dispatch = useDispatch();
    const { data, loading, apiKeys, matches } = useSelector(state => state.BundleDetails);
    const [bundleId, setBundleId] = useState('')
    const location = useLocation()

    const AccountProperties = createSelector(
        state => state.Account,
        account => ({
            user: account.user,
        })
    )
    const { user } = useSelector(AccountProperties)
    const navigate = useNavigate()

    const tableConfig = useMemo(() => (
        tableConfigKeys()
    ), [])

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const id = queryParams.get("id")
        setBundleId(id)
    }, [location.search])

    useEffect(() => {
        if (bundleId) {
            dispatch(getBundleData(bundleId));
        }
    }, [dispatch, bundleId]);

    if (!tableConfig || loading) {
        return <Loader />
    }

    return (
        <Container fluid>
            <div className=" page-content px-4 pb-0">
                <div className="d-flex justify-content-end" style={{ marginTop: '-10px', paddingBottom: '5px' }}>
                    <Button className="bg-transparent border-0 text-black" onClick={() => navigate(-1)}> <i className="bi bi-arrow-right"></i> Back to All Games</Button>
                </div>
                <GameDescription
                    name={data?.name}
                    description={data?.description}
                    status={data?.status}
                    game_id={data?.game_id}
                    game_bundle_id={data?.game_bundle_id}
                />
            </div>

            {(user.role !== 3) &&
                <List
                    companies={apiKeys ?? ''}
                    loading={loading}
                    tableConfig={tableConfig}
                />
            }

        </Container>
    )
}

export default KeysList