import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import List from "components/List"
import { addGame, deleteBundle, deleteProfilAdmin, getAllUsers, getBundle, updateUserRoleAdmin } from "store/actions"
import { useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import Breadcrumbs from "components/Common/Breadcrumb"
import * as Yup from "yup"
import {
    Col,
    Container,
    Row,
    Button
} from "reactstrap"

import Loader from "components/Loader"
import GameModal from "../../components/modals/GameModal"
import getTableConfig from "./tableConfig"
import DeleteModal from "components/modals/DeleteModal"
import InviteModal from "components/modals/InviteModal"
import { getTableConfigAdmin } from "pages/Users/getTableConfigAdmin"
import { getTableConfigSuper } from "pages/Users/getTableConfigSuper"
import EditRoleModal from "components/modals/EditRoleModal"
import CompanyDetail from "components/CompanyDetail"
import EditStatusModal from "./EditStatusModal"

const initalDeleteModalData = {
    isOpen: false,
    data: {}
}

const initalUserDeleteModalData = {
    isOpen: false,
    data: {}
}

const initalModalEditStatus = {
    isOpen: false,
    data: {}
}

const initalEditModalData = {
    isOpen: false,
    data: {}
}

const CompanyOverview = () => {

    const dispatch = useDispatch();
    const [id, setId] = useState('')
    const { data, loading, bundle, company } = useSelector(state => state.Admin);
    const location = useLocation()
    const [modalEditData, setModalEditData] = useState(initalEditModalData);
    const { user } = useSelector(state => state.Account)
    const [modalDeleteData, setModalDeleteData] = useState(initalDeleteModalData);
    const [modalEditStatus, setModalEditStatus] = useState(initalModalEditStatus);
    const [modalUserDeleteData, setModalUserDeleteData] = useState(initalUserDeleteModalData);

    const tableConfigDataUsers = user?.role === 1 ? getTableConfigAdmin : getTableConfigSuper;
    const optionsData = user?.role === 1 ? [
        { value: 2, label: "Game Developer Admin" },
        { value: 4, label: "Game Developer User" },
    ] : [
        { value: 2, label: "Game Developer Admin" },
        { value: 4, label: "Game Developer User" },
    ];

    const toggleModalEditStatus = useCallback((params) => {

        setModalEditStatus(prev => {
            const newOpen = !prev.isOpen;

            if (newOpen) {
                const { id, status } = params;
                return {
                    isOpen: newOpen,
                    data: {
                        id,
                        status
                    }
                }
            }

            return initalModalEditStatus
        })
    }, [setModalEditStatus])

    const toggleModalDeleteData = useCallback((params) => {
        setModalDeleteData(prev => {
            const newOpen = !prev.isOpen;

            if (newOpen) {
                const { id, email } = params;
                return {
                    isOpen: newOpen,
                    data: {
                        id,
                        email
                    }
                }
            }

            return initalDeleteModalData
        })
    }, [setModalDeleteData])

    const toggleModalUserDeleteData = useCallback((params) => {
        setModalUserDeleteData(prev => {
            const newOpen = !prev.isOpen;

            if (newOpen) {
                const { id } = params;
                return {
                    isOpen: newOpen,
                    data: {
                        id,
                    }
                }
            }

            return initalUserDeleteModalData
        })
    }, [setModalUserDeleteData])

    const toggleModalEditData = useCallback((params) => {
        setModalEditData(prev => {
            const newOpen = !prev.isOpen;

            if (newOpen) {
                const { id, role } = params;
                return {
                    isOpen: newOpen,
                    data: {
                        id,
                        role,
                    }
                }
            }

            return initalEditModalData
        })
    }, [setModalEditData])

    const tableConfig = useMemo(() => (
        getTableConfig(toggleModalDeleteData, toggleModalEditStatus)
    ), [toggleModalDeleteData, toggleModalEditStatus])


    const tableConfigUsers = useMemo(() => (
        tableConfigDataUsers(toggleModalEditData, toggleModalUserDeleteData)
    ), [toggleModalEditData, toggleModalUserDeleteData])

    const [isModalInviteOpen, setIsModalInviteOpen] = useState(false);
    const toggleIsModalInviteOpen = () => setIsModalInviteOpen(prev => !prev);

    const [isModalInviteUserOpen, setIsModalInviteUserOpen] = useState(false);
    const toggleIsModalInviteUserOpen = () => setIsModalInviteUserOpen(prev => !prev);

    let filteredUsers;

    if (data?.users && id) {
        filteredUsers = data.users.filter(item => {
            if (item.Account) {
                return item.Account == id;
            }
        })
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const id = queryParams.get("id")
        setId(id)
    }, [location.search])


    useEffect(() => {
        if (id) {
            dispatch(getBundle(id))
            dispatch(getAllUsers())
        }
    }, [dispatch, id])


    if (!tableConfig || loading) {
        return <Loader />
    }

    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Company" breadcrumbItem="Company Detail" />
                    <Row className="px-2">
                        <Col lg="12">
                            {company && (
                                <CompanyDetail company={company} />
                            )}
                        </Col>

                    </Row>
                </Container>
                <List
                    companies={bundle || []}
                    loading={loading}
                    tableConfig={tableConfig}
                    className='px-0'
                    rightHeaderContent={(
                        <Col sm={6}>
                            <div className="text-sm-end">

                                <Button
                                    to=""
                                    onClick={toggleIsModalInviteOpen}
                                    className={'btn btn-success btn-rounded waves-effect waves-light mb-2 btn btn-secondary'}
                                >
                                    <i className="mdi mdi-plus me-1"></i> Add New Game
                                </Button>
                            </div>
                        </Col>
                    )}
                />
                <List
                    companies={filteredUsers}
                    loading={loading}
                    tableConfig={tableConfigUsers}
                    rightHeaderContent={(
                        <Col sm={6}>
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    className={'btn btn-success btn-rounded waves-effect waves-light mb-2 btn btn-secondary'}
                                    onClick={toggleIsModalInviteUserOpen}
                                >
                                    <i className="mdi mdi-plus me-1"></i> Invite User
                                </Button>
                            </div>
                        </Col>
                    )}
                    pxUsers={2}
                />
                {isModalInviteUserOpen && (
                    <InviteModal
                        toggle={toggleIsModalInviteUserOpen}
                        role={user?.role}
                        initialValues={{
                            email: '',
                            role: '',
                            first_name: '',
                            last_name: ''
                        }}
                        validationData={Yup.object({
                            role: Yup.number().required("User Role is Required"),
                            email: Yup.string().email("Invalid email"),
                            last_name: Yup.string().required("Last Name is Required"),
                            first_name: Yup.string().required("First Name is email"),
                        })}
                        company={company}
                        optionsData={optionsData}
                    />
                )}

                {modalEditData && (
                    <EditRoleModal
                        modalData={modalEditData}
                        toggle={toggleModalEditData}
                        role={user?.role}
                        optionsData={[
                            { value: 2, label: "Game Developer Admin" },
                            { value: 4, label: "Game Developer User" },
                        ]}
                        action={updateUserRoleAdmin}
                    />
                )}
                {modalUserDeleteData && (
                    <DeleteModal
                        modalData={modalUserDeleteData}
                        toggle={toggleModalUserDeleteData}
                        action={deleteProfilAdmin}
                        text={'Are you sure you want to permanently delete the user?'}
                    />
                )}
                {isModalInviteOpen && (
                    <GameModal
                        toggle={toggleIsModalInviteOpen}
                        action={addGame}
                        id={id}
                    />
                )}
                {modalDeleteData && (
                    <DeleteModal
                        modalData={modalDeleteData}
                        toggle={toggleModalDeleteData}
                        action={deleteBundle}
                        text={'Are you sure you want to permanently delete the game?'}
                    />
                )}
                {modalEditStatus && (
                    <EditStatusModal
                        modalData={modalEditStatus}
                        toggle={toggleModalEditStatus}
                        role={user?.role}
                    />
                )}

            </div>
        </React.Fragment >
    )
}

export default CompanyOverview
