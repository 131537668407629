import { PLAYER_GET_ACCOUNT_DATA, PLAYER_GET_ACCOUNT_DATA_ERROR, PLAYER_GET_ACCOUNT_DATA_SUCCESS } from "./actionTypes";

const initialState = {
    loading: true,
    data: null
};

const PlayerAccount = (state = initialState, action) => {
    switch (action.type) {
        case PLAYER_GET_ACCOUNT_DATA: {
            state = {
                ...state,
                loading: true
            }
            break;
        }
        case PLAYER_GET_ACCOUNT_DATA_SUCCESS: {
            state = {
                ...state,
                data: action.payload,
                loading: false
            }
            break;
        }
        case PLAYER_GET_ACCOUNT_DATA_ERROR: {
            state = {
                ...state,
                loading: false
            }
        }
    }

    return state;
};

export default PlayerAccount;
