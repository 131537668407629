import React from "react"

export const getTableConfig = (toggleModalInfo) => ({
    columns: [
        {
            header: "Created At",
            accessorKey: "createdAt",
            enableColumnFilter: false,
            enableSorting: true,
            cell: cellProps => (
                <span>
                    {cellProps.row.original.timestamp}
                </span>
            ),
        },
        {
            header: "Status Code",
            accessorKey: "status",
            enableColumnFilter: false,
            enableSorting: true,
            cell: cellProps => {
                const status = cellProps.row.original.status;
                let color = '';
                if (status >= 200 && status < 300) {
                    color = 'text-success';
                } else if (status >= 400 && status < 500) {
                    color = 'text-warning';
                } else if (status >= 500 && status < 600) {
                    color = 'text-danger';
                }
                return (
                    <span className={color} style={{  }}>
                        {status}
                    </span>
                );
            },
        },
        {
            header: "Endpoint",
            accessorKey: "requestUrl",
            enableColumnFilter: false,
            enableSorting: true,
            cell: cellProps => (
                <span>
                    {cellProps.row.original.url}
                </span>
            ),
        },
        {
            header: "Total Request Time",
            accessorKey: "totalRequestTime",
            enableColumnFilter: false,
            enableSorting: true,
            cell: cellProps => (
                <div>
                    {cellProps.row.original.totalTime}
                </div>
            ),
        },
        {
            header: "IP Address",
            accessorKey: "ipAddress",
            enableColumnFilter: false,
            enableSorting: true,
            cell: cellProps => (
                <span>
                    {cellProps.row.original.ipAddress}
                </span>
            ),
        },
        {
            header: "User Agent",
            accessorKey: "userAgent",
            enableColumnFilter: false,
            enableSorting: true,
            cell: cellProps => (
                <span>
                    {cellProps.row.original.header["user-agent"]}
                </span>
            ),
        },
        {
            header: "Action",
            accessorKey: "action",
            enableColumnFilter: false,
            enableSorting: true,
            cell: cellProps => (
                <button
                    style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        border: 'none',
                        background: 'transparent',
                        
                    }}
                    onClick={() => toggleModalInfo({
                        data: cellProps.row.original,
                    })}
                >
                    View Details
                </button>
            ),
        },
    ],
});

export default getTableConfig;