import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from "reactstrap"

import { Link, useLocation, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { registerUserFailed } from "store/actions"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"

const WvAuth = props => {
  //meta title
  document.title = "Register | Third Pillar"
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const location = useLocation()
  const [gameBundleId, setGameBundleId] = useState(null)
  const [playerId, setPlayerId] = useState(null)

  const AccountProperties = createSelector(
    state => state.Account,
    account => ({
      user: account.user,
      registrationError: account.registrationError,
      success: account.success,
      // loading: account.loading,
    })
  )

  const {
    user,
    registrationError,
    success,
    // loading
  } = useSelector(AccountProperties)

  useEffect(() => {
    // Use URLSearchParams to parse the query parameters from the location
    const queryParams = new URLSearchParams(location.search)

    // Get the value of a specific parameter, e.g., 'paramName'
    const gameBundleIdValue = queryParams.get("game_bundle_id")
    setGameBundleId(gameBundleIdValue)
    const playerIdValue = queryParams.get("player_id")
    setPlayerId(playerIdValue)

    if (!playerIdValue) {
      dispatch(registerUserFailed("Player id doesn't exist"))
    } else if (!gameBundleIdValue) {
      dispatch(registerUserFailed("Game bundle id doesn't exist"))
    }

    console.log("Query Parameter Value:", gameBundleIdValue, null)
  }, [location.search])

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-0 d-flex">
        <Container className="d-flex w-1000 h-100 justify-content-center align-items-center">
          <Row className="d-flex w-100 h-100 justify-content-center align-items-center">
            <Col className="d-flex" md={4} lg={6} xl={5}>
              <Card className="overflow-hidden w-100">
                <CardBody className="pt-0">
                  {registrationError && registrationError ? (
                    <Alert color="danger">{registrationError}</Alert>
                  ) : null}
                  <div className="p-2">
                    <Col>
                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block w-100"
                          type="button"
                          onClick={() => {
                            if (playerId && gameBundleId) {
                              navigate(
                                `/wv/login?player_id=${playerId}&game_bundle_id=${gameBundleId}`
                              )
                            }
                          }}
                        >
                          Login
                        </button>
                      </div>
                    </Col>

                    <Col>
                      <div className="mt-3">
                        <button
                          className="btn btn-primary btn-block w-100"
                          type="button"
                          onClick={() => {
                            if (playerId && gameBundleId) {
                              navigate(
                                `/wv/register?player_id=${playerId}&game_bundle_id=${gameBundleId}`
                              )
                            }
                          }}
                        >
                          Register
                        </button>
                      </div>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default WvAuth
