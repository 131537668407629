
/* ORDERS */
export const GET_ORDERS = "GET_ORDERS"
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS"
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL"
export const ADD_NEW_ORDER = "ADD_NEW_ORDER"
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS"
export const ADD_ORDER_FAIL = "ADD_ORDER_FAIL"
export const UPDATE_ORDER = "UPDATE_ORDER"
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS"
export const UPDATE_ORDER_FAIL = "UPDATE_ORDER_FAIL"
export const DELETE_ORDER = "DELETE_ORDER"
export const GET_ORDERS_LOADING_STATUS = "GET_ORDERS_LOADING_STATUS"
