import React, { useEffect } from "react"
import { Row, Col } from "reactstrap"

import MiniWidget from "pages/Dashboard-saas/mini-widget"

import { useSelector, useDispatch } from "react-redux"
import { PlayerGetAccountData } from "store/player/actions"
import Loader from "components/Loader"

const DashboardPlayer = ({ user }) => {
    const dispatch = useDispatch();
    const { data, loading } = useSelector(state => state.PlayerAccount);

    const reports = [
        {
            icon: "bx bx-copy-alt",
            title: "Account",
            value: `$ ${data?.sumAllTime ?? ''}`,
        },
        {
            icon: "bx bx-archive-in",
            title: "Win/Loss - last 30 days",
            value: `$ ${data?.sumLast30Days ?? ''}`,
        },
        {
            icon: "bx bx-purchase-tag-alt",
            title: "Game Played - last 30 days",
            value: data?.games ?? '',
        },
    ]


    useEffect(() => {
        dispatch(PlayerGetAccountData());
    }, [dispatch]);

    if (loading) {
        return <Loader />
    }

    return (
        <>
            <h4 className="mt-2 mb-4 font-size-18">Hi {user.first_name}! Welcome to your Dashboard.</h4>
            <Row>
                <Col xl="12">
                    <Row>
                        <MiniWidget reports={reports} />
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default DashboardPlayer