import {
  REGISTER_USER,
  REGISTER_COMPANY,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  REGISTER_USER_WV,
  REGISTER_COMPANY_EMPLOYEE,
} from "./actionTypes"

export const registerUser = (user, history) => {
  return {
    type: REGISTER_USER,
    payload: { user, history },
  }
}

export const registerCompany = (user, history, session, accountId) => {
  return {
    type: REGISTER_COMPANY,
    payload: { user, history, session, accountId },
  }
}

export const registerCompanyEmployee = (user, history, session) => {
  return {
    type: REGISTER_COMPANY_EMPLOYEE,
    payload: { user, history, session },
  }
}

export const registerWVUser = (user, history, game_bundle, player_id) => {
  return {
    type: REGISTER_USER_WV,
    payload: { user, history, game_bundle, player_id },
  }
}

export const registerUserSuccessful = user => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  }
}

export const registerUserFailed = user => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  }
}
