import React, { useEffect } from "react"
import { Container } from "reactstrap"
import { useSelector } from "react-redux"
import List from "components/List"
import { useDispatch } from "react-redux"
import { GameDevGetAccountData } from "store/actions"
import gamesTableConfig from "./tableConfigDev"

const GameDevGames = () => {
    const dispatch = useDispatch();
    const { data, loading } = useSelector(state => state.GameDevAccount);

    useEffect(() => {
        dispatch(GameDevGetAccountData());
    }, [dispatch]);

    return (
        <React.Fragment>
            <Container fluid>
                <List
                    companies={data?.allGames}
                    loading={loading}
                    tableConfig={gamesTableConfig}
                    pxUsers='2'
                />
            </Container>
        </React.Fragment>
    )
}

export default GameDevGames