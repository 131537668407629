import React from "react"
import { Link } from "react-router-dom"
import { Badge, UncontrolledTooltip } from "reactstrap"

const gamesTableConfig = {
    columns: [
        {
            header: "Name",
            enableColumnFilter: false,
            enableSorting: true,
            accessorKey: "name",
            cell: cellProps => {
                return (
                    <Link to={`/game/?id=${cellProps.row.original.id}`} className="text-body">
                        {cellProps.row.original.name}
                    </Link>
                )
            },
        },
        {
            header: "Game ID",
            enableColumnFilter: false,
            enableSorting: true,
            accessorKey: "game_id",
            cell: cellProps => {
                return (
                    <Link to={`/game/?id=${cellProps.row.original.id}`} className="text-body">
                        {cellProps.row.original.game_id}
                        te
                    </Link>
                )
            },
        },
        {
            header: "Game Bundle ID",
            enableColumnFilter: false,
            enableSorting: true,
            accessorKey: "game_bundle_id",
            cell: cellProps => {
                return (
                    <Link to={`/game/?id=${cellProps.row.original.id}`} className="text-body">
                        {cellProps.row.original.game_bundle_id}
                    </Link>
                )
            },
        },
        {
            header: "Created Date",
            enableColumnFilter: false,
            enableSorting: true,
            accessorKey: "created_at",
            cell: cellProps => {
                return <span>{cellProps.row.original.created_at.date}</span>;
            },
        },
        {
            header: "Modified Date",
            enableColumnFilter: false,
            enableSorting: true,
            accessorKey: "modified_at",
            cell: cellProps => {
                return <span>{cellProps.row.original.modified_at.date}</span>;
            },
        },
        {
            header: "Status",
            accessorKey: "status",
            enableColumnFilter: false,
            enableSorting: true,
            cell: cellProps => {
                switch (cellProps.row.original.status) {
                    case 1:
                        return <Badge className="bg-success px-2 py-1" style={{ fontSize: '11px' }}>Live</Badge>
                    case 0:
                        return <Badge className="bg-warning px-2 py-1" style={{ fontSize: '11px' }}>Pending</Badge>
                }
            },
        },
        // {
        //     header: "Action",
        //     enableColumnFilter: false,
        //     enableSorting: true,
        //     cell: cellProps => {
        //         return (
        //             <ul className="list-unstyled hstack gap-1 mb-0">
        //                 <li>
        //                     <Link
        //                         to="#"
        //                         className="btn btn-sm btn-soft-danger"
        //                         onClick={() => {
        //                             const jobData = cellProps.row.original
        //                             onClickDelete(jobData)
        //                         }}
        //                         id={`deletetooltip-${cellProps.row.original.id}`}
        //                     >
        //                         <i className="mdi mdi-delete-outline" />
        //                         <UncontrolledTooltip
        //                             placement="top"
        //                             target={`deletetooltip-${cellProps.row.original.id}`}
        //                         >
        //                             Delete
        //                         </UncontrolledTooltip>
        //                     </Link>
        //                 </li>
        //             </ul>
        //         )
        //     },
        // },
    ],
    title: "Games",
    isAddButton: false,
    search: 'Search Game'
}

export default gamesTableConfig;