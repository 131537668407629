import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { deleteKey, getBundleData } from "store/bundleDetails/actions"
import { useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import List from "components/List"
import gamesTableConfig from "./tableConfig"
import { Container, Col, Button } from "reactstrap"
import { createSelector } from "reselect"
import AddKeyModal from "./AddKeyModal"
import { tableConfigKeys } from "./tableConfigKeys"
import DeleteModal from "components/modals/DeleteModal"
import Loader from "components/Loader"
import { tableConfigGamDev } from "./tableConfigGameDev"
import GameDescription from "components/GameDescription"

const initalDeleteModalData = {
    isOpen: false,
    data: {}
}

const Game = () => {
    const dispatch = useDispatch();
    const { data, loading, apiKeys, matches } = useSelector(state => state.BundleDetails);
    const [bundleId, setBundleId] = useState('')
    const location = useLocation()
    const [isModalAddOpen, setIsModalAddOpen] = useState(false);
    const toggleIsModalAddOpen = () => setIsModalAddOpen(prev => !prev);
    const [modalDeleteData, setModalDeleteData] = useState(initalDeleteModalData);

    const AccountProperties = createSelector(
        state => state.Account,
        account => ({
            user: account.user,
        })
    )
    const { user } = useSelector(AccountProperties)

    const toggleModalDeleteData = useCallback((params) => {
        setModalDeleteData(prev => {
            const newOpen = !prev.isOpen;
            if (newOpen) {
                const { id } = params;
                return {
                    isOpen: newOpen,
                    data: {
                        id,
                    }
                }
            }

            return initalDeleteModalData
        })
    }, [setModalDeleteData])

    const tableConfig = useMemo(() => (
        tableConfigKeys(toggleModalDeleteData, user.role)
    ), [toggleModalDeleteData])


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const id = queryParams.get("id")
        setBundleId(id)
    }, [location.search])

    useEffect(() => {
        if (bundleId) {
            dispatch(getBundleData(bundleId));
        }
    }, [dispatch, bundleId]);

    if (!tableConfig || loading) {
        return <Loader />
    }

    return (
        <Container fluid>
            <div className=" page-content px-4 pb-0">
                <GameDescription
                    name={data?.name}
                    description={data?.description}
                    status={data?.status}
                    game_id={data?.game_id}
                    game_bundle_id={data?.game_bundle_id}
                />
            </div>
            <List
                companies={matches ?? ''}
                loading={loading}
                tableConfig={gamesTableConfig}
            />

            {(user.role === 0 || user.role === 1) &&
                <List
                    companies={apiKeys ?? ''}
                    loading={loading}
                    tableConfig={tableConfig}
                    rightHeaderContent={(
                        <Col sm={6}>
                            <div className="text-sm-end">

                                <Button
                                    to=""
                                    onClick={toggleIsModalAddOpen}
                                    className={'btn btn-success btn-rounded waves-effect waves-light mb-2 btn btn-secondary'}
                                >
                                    <i className="mdi mdi-plus me-1"></i> Add New Key
                                </Button>
                            </div>
                        </Col>
                    )}
                />

            }
            {user.role === 2 &&
                <List
                    companies={apiKeys ?? ''}
                    loading={loading}
                    tableConfig={tableConfigGamDev}
                />
            }

            {isModalAddOpen && < AddKeyModal toggle={toggleIsModalAddOpen} account={data.account} bundleId={bundleId} />}


            {modalDeleteData && (
                <DeleteModal
                    modalData={modalDeleteData}
                    toggle={toggleModalDeleteData}
                    text={'Are you sure you want to permanently delete the API Key?'}
                    action={deleteKey}
                />
            )}
        </Container>

    )
}

export default Game