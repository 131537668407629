import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Button, Col, Container } from "reactstrap"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import List from "components/List"

import getTableConfig from "./getTableConfig"
import InviteModal from "./InviteUserModal"
import { getCompanyEmployees, updateUserRole } from "store/actions"
import { deleteProfile } from "store/actions"
import Loader from "components/Loader"
import DeleteModal from "components/modals/DeleteModal"
import EditRoleModal from "components/modals/EditRoleModal"

const initalEditModalData = {
    isOpen: false,
    data: {}
}

const initalDeleteModalData = {
    isOpen: false,
    data: {}
}

const Employees = () => {
    const dispatch = useDispatch();
    const { data, loading } = useSelector(state => state.Company);

    const [isModalInviteOpen, setIsModalInviteOpen] = useState(false);
    const [modalEditData, setModalEditData] = useState(initalEditModalData);
    const [modalDeleteData, setModalDeleteData] = useState(initalDeleteModalData);

    const toggleIsModalInviteOpen = () => setIsModalInviteOpen(prev => !prev);

    const toggleModalEditData = useCallback((params) => {
        setModalEditData(prev => {
            const newOpen = !prev.isOpen;

            if (newOpen) {
                const { id, role } = params;
                return {
                    isOpen: newOpen,
                    data: {
                        id,
                        role,
                    }
                }
            }

            return initalEditModalData
        })
    }, [setModalEditData])

    const toggleModalDeleteData = useCallback((params) => {
        setModalDeleteData(prev => {
            const newOpen = !prev.isOpen;

            if (newOpen) {
                const { id, email } = params;
                return {
                    isOpen: newOpen,
                    data: {
                        id,
                        email
                    }
                }
            }

            return initalDeleteModalData
        })
    }, [setModalDeleteData])

    const tableConfig = useMemo(() => (
        getTableConfig(toggleModalEditData, toggleModalDeleteData)
    ), [toggleModalEditData, toggleModalDeleteData])


    useEffect(() => {
        dispatch(getCompanyEmployees())
    }, [dispatch])

    if (!tableConfig || loading) {
        return <Loader />
    }

    return (
        <React.Fragment>
            <Container fluid>
                <List
                    companies={data?.employees}
                    loading={loading}
                    tableConfig={tableConfig}
                    rightHeaderContent={(
                        <Col sm={6}>
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    className={'btn btn-success btn-rounded waves-effect waves-light mb-2 btn btn-secondary'}
                                    onClick={toggleIsModalInviteOpen}
                                >
                                    <i className="mdi mdi-plus me-1"></i> Invite User
                                </Button>
                            </div>
                        </Col>
                    )}
                    pxUsers='3'
                />
                {isModalInviteOpen && (
                    <InviteModal
                        toggle={toggleIsModalInviteOpen}
                    />
                )}

                {modalEditData && (
                    <EditRoleModal
                        modalData={modalEditData}
                        toggle={toggleModalEditData}
                        optionsData={[
                            { value: 2, label: "Admin" },
                            { value: 4, label: "User" },
                        ]}
                        isCompany={true}
                        action={updateUserRole}
                    />
                )}

                {modalDeleteData && (
                    <DeleteModal
                        modalData={modalDeleteData}
                        toggle={toggleModalDeleteData}
                        action={deleteProfile}
                        text={'Are you sure you want to permanently delete the user?'}
                    />
                )}

            </Container>
        </React.Fragment>
    )
}

export default Employees