import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
    GET_ALL_USERS,
    UPDATE_USER_ROLE_ADMIN,
    INVITE_USER,
    DELETE_PROFILE_ADMIN,
    GET_DASHBOARD_DATA,
    GET_COMPANIES,
    GET_BUNDLE,
    ADD_GAME,
    DELETE_BUNDLE,
    ADD_COMPANY,
    EDIT_COMPANY,
    UPDATE_BUNDLE_DATA,
    GET_CHARTS_DATA
} from "./actionTypes"
import {
    getAllUserError,
    getAllUsersSuccess,
    updateUserRoleAdminSuccess,
    updateUserRoleAdminError,
    deleteProfileAdminSuccess,
    deleteProfileAdminError,
    inviteUserError,
    inviteUserSuccess,
    getDashboardDataError,
    getDashboardDataSuccess,
    getCompaniesError,
    getCompaniesSuccess,
    getBundleSuccess,
    getBundleError,
    addGameSuccess,
    addGameError,
    deleteBundleSuccess,
    deleteBundleError,
    addCompanySuccess,
    addCompanyError,
    editCompanySuccess,
    editCompanyError,
    updateBundleDataSuccess,
    updateBundleDataError,
    getChartsDataError,
    getChartsDataSuccess,
} from "./actions"

import { toast } from "react-toastify"

import { getFirebaseBackend } from "helpers/firebase_helper"
import { formatFirestoreTimestampDate, formatFirestoreTimestampTime, updateTimestamps } from "helpers/date_helper"
const fireBaseBackend = getFirebaseBackend()

const formatDate = item => {
    if (item.created_at) {
        item.created_at = {
            date: formatFirestoreTimestampDate(item.created_at),
            time: formatFirestoreTimestampTime(item.created_at)
        };
    }

    if (item.modified_at) {
        item.modified_at = {
            date: formatFirestoreTimestampDate(item.modified_at),
            time: formatFirestoreTimestampTime(item.modified_at)
        };
    }

    if (item.last_login_time) {
        item.last_login_time = {
            date: formatFirestoreTimestampDate(item.last_login_time),
            time: formatFirestoreTimestampTime(item.last_login_time)
        };
    }

    return item
}

function transformAccountField(users) {
    return users.map(user => {
        if (user.Account && user.Account._path && user.Account._path.segments) {
            return {
                ...user,
                Account: user.Account._path.segments[1]
            };
        }
        return user;
    });
}


function* onGetAllUsers() {
    try {
        let data = yield call(fireBaseBackend.getAllUsers);

        data.users.sort((a, b) => {
            const timestampA = a?.created_at?._seconds * 1000000000 + a?.created_at?._nanoseconds;
            const timestampB = b.created_at?._seconds * 1000000000 + b.created_at?._nanoseconds;
            return timestampB - timestampA;
        });

        data = {
            ...data,
            users: data.users.map(formatDate),
        }

        data.users = transformAccountField(data.users);
        yield put(getAllUsersSuccess(data));
    } catch (error) {
        console.log(error);
        yield put(getAllUserError());
    }
}

function* onUpdateUserRole({ payload }) {
    try {
        const response = yield call(fireBaseBackend.updateUserRole, payload)
        yield put(updateUserRoleAdminSuccess(response))
        toast.success("Role Updated Successful", { autoClose: 2000 })
    } catch (error) {
        console.log(error)
        yield put(updateUserRoleAdminError(error))
        toast.error("Role Updates Failed", { autoClose: 2000 })
    }
}

function* onInviteUser({ payload }) {
    try {
        const response = yield call(fireBaseBackend.inviteUser, {
            email: payload.email,
            role: payload.role,
            first_name: payload.first_name,
            last_name: payload.last_name,
            account: payload.account
        })
        yield put(inviteUserSuccess(response))
        toast.success("Invited Successfully", { autoClose: 2000 })
    } catch (error) {
        console.log(error, 'Error')
        yield put(inviteUserError())
        toast.error(error, { autoClose: 2000 })
    }
}

function* deleteProfileAdmin({ payload }) {
    try {
        const data = yield call(fireBaseBackend.deleteProfile, payload);
        toast.success("Deleted Successfully", { autoClose: 2000 })
        yield put(deleteProfileAdminSuccess(data));
    } catch (error) {
        console.log(error);
        toast.error("Unexpected Error", { autoClose: 2000 })
        yield put(deleteProfileAdminError())
    }
}

function* getDashboardData() {
    try {
        const data = yield call(fireBaseBackend.thirdAdminGetAccountData);
        yield put(getDashboardDataSuccess(data));
    } catch (error) {
        console.log(error);
        yield put(getDashboardDataError());
    }
}

function* getCompanies() {
    try {
        let data = yield call(fireBaseBackend.getAllCompanies)
        data = data.map(formatDate)
        yield put(getCompaniesSuccess(data))
    } catch (error) {
        console.log(error)
        yield put(getCompaniesError(error))
    }
}


function* updateBundleDetails({ payload }) {
    try {
        const data = yield call(fireBaseBackend.editBundle, payload);
        data.bundle.created_at = {
            date: formatFirestoreTimestampDate(data.bundle.created_at),
            time: formatFirestoreTimestampTime(data.bundle.created_at)
        }
        data.bundle.modified_at = {
            date: formatFirestoreTimestampDate(data.bundle.modified_at),
            time: formatFirestoreTimestampTime(data.bundle.modified_at)
        }

        // data = {
        //     ...data,
        //     apiKeys: data.apiKeys.map(formatDate)
        // }

        toast.success("Updated Successfully", { autoClose: 2000 })

        yield put(updateBundleDataSuccess(data));
    } catch (error) {
        console.log(error);
        toast.error("Error", { autoClose: 2000 })
        yield put(updateBundleDataError());
    }
}


function* addCompany(action) {
    try {
        const { file, company, company_desc, status, platform_fee, rev_share } = action.payload;
        const fileUrl = yield call(fireBaseBackend.uploadFileToFirebase, { file, company });
        let data = yield call(fireBaseBackend.addCompany, { company, company_desc, status, platform_fee, rev_share, company_logo: fileUrl })
        data.company.created_at = {
            date: formatFirestoreTimestampDate(data.company.created_at),
            time: formatFirestoreTimestampTime(data.company.created_at)
        }

        data.company.modified_at = {
            date: formatFirestoreTimestampDate(data.company.modified_at),
            time: formatFirestoreTimestampTime(data.company.modified_at)
        }

        yield put(addCompanySuccess(data.company))
        toast.success("Added Successfully", { autoClose: 2000 })

    } catch (error) {
        console.log(error)
        toast.error(error, { autoClose: 2000 })
        yield put(addCompanyError(error))
    }
}


function* editCompany(action) {
    try {
        const { file, company, company_desc, status, platform_fee, rev_share } = action.payload;

        let fileUrl;
        if (typeof file !== 'string') {
            fileUrl = yield call(fireBaseBackend.uploadFileToFirebase, { file, company });
        }

        let data = yield call(fireBaseBackend.editCompany, { company, company_desc, status, platform_fee, rev_share, company_logo: fileUrl })
        yield put(editCompanySuccess(data.company))
        toast.success("Edited Successfully", { autoClose: 2000 })
    } catch (error) {
        toast.error(error, { autoClose: 2000 })
        yield put(editCompanyError(error))
    }
}

function* getBundle(payload) {
    try {
        let data = yield call(fireBaseBackend.getBundle, payload)
        data.allGames = data.allGames.map(formatDate);
        data.accountData.created_at = {
            date: formatFirestoreTimestampDate(data.accountData.created_at),
            time: formatFirestoreTimestampTime(data.accountData.created_at)
        }
        data.accountData.modified_at = {
            date: formatFirestoreTimestampDate(data.accountData.modified_at),
            time: formatFirestoreTimestampTime(data.accountData.modified_at)
        }
        yield put(getBundleSuccess(data))
    } catch (error) {
        console.log(error)
        yield put(getBundleError(error))
    }
}

function* addGame({ payload }) {
    try {
        let data = yield call(fireBaseBackend.addGame, {
            payload
        })

        data.game.created_at = {
            date: formatFirestoreTimestampDate(data.game.created_at),
            time: formatFirestoreTimestampTime(data.game.created_at)
        }

        data.game.modified_at = {
            date: formatFirestoreTimestampDate(data.game.modified_at),
            time: formatFirestoreTimestampTime(data.game.modified_at)
        }

        yield put(addGameSuccess(data.game))
        toast.success("Added Successfully", { autoClose: 2000 })
    } catch (error) {
        toast.error(error, { autoClose: 2000 })
        console.log(error)
        yield put(addGameError(error))
    }
}

function* deleteBundle({ payload }) {
    try {
        yield call(fireBaseBackend.deleteBundle, {
            payload
        })
        yield put(deleteBundleSuccess(payload))
        toast.success("Deleted Successfully", { autoClose: 2000 })
    } catch (error) {
        toast.error(error, { autoClose: 2000 })
        console.log(error)
        yield put(deleteBundleError(error))
    }
}

function* chartsData() {
    try {
        const data = yield call(fireBaseBackend.adminGetChartsData)
        data.users.dataLast30Days = updateTimestamps(data.users.dataLast30Days);
        data.users.data30To60Days = updateTimestamps(data.users.data30To60Days);
        data.users.dataAllTime = updateTimestamps(data.users.dataAllTime);
        data.revenue.dataLast30Days = updateTimestamps(data.revenue.dataLast30Days);
        data.revenue.data30To60Days = updateTimestamps(data.revenue.data30To60Days);
        data.revenue.dataAllTime = updateTimestamps(data.revenue.dataAllTime);

        yield put(getChartsDataSuccess(data))
    } catch (error) {
        console.log(error)
        yield put(getChartsDataError(error))
    }
}


function* adminSaga() {
    yield takeEvery(GET_ALL_USERS, onGetAllUsers)
    yield takeEvery(GET_CHARTS_DATA, chartsData)
    yield takeEvery(UPDATE_USER_ROLE_ADMIN, onUpdateUserRole)
    yield takeEvery(INVITE_USER, onInviteUser)
    yield takeEvery(DELETE_PROFILE_ADMIN, deleteProfileAdmin)
    yield takeEvery(GET_DASHBOARD_DATA, getDashboardData)
    yield takeEvery(GET_COMPANIES, getCompanies)
    yield takeEvery(GET_BUNDLE, getBundle)
    yield takeEvery(ADD_GAME, addGame)
    yield takeEvery(DELETE_BUNDLE, deleteBundle)
    yield takeEvery(ADD_COMPANY, addCompany)
    yield takeEvery(EDIT_COMPANY, editCompany)
    yield takeEvery(UPDATE_BUNDLE_DATA, updateBundleDetails)
}

export default adminSaga
