import { Row, Col } from "reactstrap"
import MiniWidget from "pages/Dashboard-saas/mini-widget"
import Earning from "pages/Dashboard-saas/earning"
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { GameDevGetAccountData, getChartsDataDev } from 'store/actions'
import React, { useEffect, useState } from 'react';
import Loader from "components/Loader"
import Chart from 'components/DashboardCharts'

const DashboardGameDev = () => {
    const dispatch = useDispatch();
    const { data, loading, charts } = useSelector(state => state.GameDevAccount);

    const reports = [
        {
            icon: "bx bx-copy-alt",
            title: "Games - last 30 days",
            value: data?.matchesLast30Days,
        },
        {
            icon: "bx bx-archive-in",
            title: "Revenue - last 30 days",
            value: `$${data?.sumForLast30Days.toFixed(2)}`,
        },
        {
            icon: "bx bx-purchase-tag-alt",
            title: "Average Revenue per game - last 30 days",
            value: `$${data?.averageRevenuePer30LastDays.toFixed(2)}`,
        },
    ]

    useEffect(() => {
        dispatch(getChartsDataDev());
        dispatch(GameDevGetAccountData());
    }, [dispatch]);

    if (loading || !charts) {
        return <Loader />
    }

    return (
        <>
            <Row>
                <Col xl="12">
                    <Row>
                        <MiniWidget reports={reports} />
                    </Row>
                </Col>
            </Row>
            {charts &&
                <>
                    <Row>
                        <Chart
                            dataColors='["--bs-primary"]'
                            data={charts?.users} title="New Users"
                            seriesName="Users"
                            yaxisTitle="Users"
                        />
                    </Row>
                    <Row>
                        <Chart
                            dataColors='["--bs-primary"]'
                            data={charts?.revenue}
                            title="Platform Revenue"
                            seriesName="Revenue"
                            yaxisTitle="Revenue"
                        />
                    </Row>
                </>}
        </>
    )
}

export default DashboardGameDev