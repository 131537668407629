import {
  GET_COMPANY_EMPLOYEES,
  GET_COMPANY_EMPLOYEES_SUCCESS,
  GET_COMPANY_EMPLOYEES_ERROR,
  UPDATE_USER_ROLE_ERROR,
  UPDATE_USER_ROLE_SUCCESS,
  UPDATE_USER_ROLE,
  DELETE_PROFILE,
  DELETE_PROFILE_SUCCESS,
  DELETE_PROFILE_ERROR,
  INVITE_COMPANY_EMPLOYEE_SUCCESS,
  INVITE_COMPANY_EMPLOYEE,
  INVITE_COMPANY_EMPLOYEE_ERROR,
} from "./actionTypes"

export const getCompanyEmployees = companyId => ({
  type: GET_COMPANY_EMPLOYEES,
  payload: companyId,
})

export const getCompanyEmployeesSuccess = payload => {
  return {
    type: GET_COMPANY_EMPLOYEES_SUCCESS,
    payload
  }
}

export const getCompanyEmployeesError = () => {
  return {
    type: GET_COMPANY_EMPLOYEES_ERROR,
  }
}

export const inviteCompanyEmployee = (payload) => {
  return {
    type: INVITE_COMPANY_EMPLOYEE,
    payload
  }
}

export const inviteCompanySuccess = (payload) => {
  return {
    type: INVITE_COMPANY_EMPLOYEE_SUCCESS,
    payload
  }
}

export const inviteCompanyError = (payload) => {
  return {
    type: INVITE_COMPANY_EMPLOYEE_ERROR,
    payload
  }
}

export const updateUserRole = (payload) => {
  return {
    type: UPDATE_USER_ROLE,
    payload
  }
}

export const updateUserRoleSuccess = (payload) => {
  return {
    type: UPDATE_USER_ROLE_SUCCESS,
    payload
  }
}

export const updateUserRoleError = (payload) => {
  return {
    type: UPDATE_USER_ROLE_ERROR,
    payload
  }
}

export const deleteProfile = (payload) => {
  return {
    type: DELETE_PROFILE,
    payload
  }
}

export const deleteProfileSuccess = (payload) => {
  return {
    type: DELETE_PROFILE_SUCCESS,
    payload
  }
}

export const deleteProfileError = (payload) => {
  return {
    type: DELETE_PROFILE_ERROR,
    payload
  }
}