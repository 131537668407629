import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import {
  REGISTER_COMPANY,
  REGISTER_USER,
  REGISTER_USER_FAILED,
  REGISTER_USER_WV,
  REGISTER_COMPANY_EMPLOYEE,
} from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"

import { getFirebaseBackend } from "../../../helpers/firebase_helper"

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, history } }) {
  console.log("using the following url for registration: ")
  try {
    console.log("Trying to register user (within try block)")
    const response = yield call(
      fireBaseBackend.registerUser,
      user.email,
      user.password
    )
    // refactor later. potential bug 
    const firestoreResponse = yield call(
      fireBaseBackend.addNewUserToFirestore,
      response
    )
    localStorage.setItem("authUser", JSON.stringify(firestoreResponse))
    yield put(registerUserSuccessful(firestoreResponse))
    history("/dashboard")
  } catch (error) {
    console.log("There was an error registering: ", error)
    yield put(registerUserFailed(error))
  }
}

function* registerUserWV({
  payload: { user, history, game_bundle, player_id },
}) {
  console.log("using the following url for registration: ")
  try {
    yield put({
      type: REGISTER_USER_FAILED,
      payload: null,
    })
    const gameRef = yield call(fireBaseBackend.getGameBundleRef, game_bundle)
    console.log("Trying to register user (within try block)")
    const response = yield call(
      fireBaseBackend.registerUser,
      user.email,
      user.password
    )
    const firestoreAuthResponse = yield call(
      fireBaseBackend.addNewUserToFirestore,
      response
    )
    const playerData = {
      game_bundle: game_bundle,
      player_id: player_id || response.uid,
      user: firestoreAuthResponse?.uid,
    }
    yield call(fireBaseBackend.createPlayer, playerData, gameRef)
    console.log("error")
    localStorage.setItem("authUser", JSON.stringify(firestoreAuthResponse))
    yield put(registerUserSuccessful(firestoreAuthResponse))
    history("/welcome")
  } catch (error) {
    console.log("There was an error registering: ", error)
    console.log("There was an error registering: ", error?.message)
    if (error?.message) {
      yield put(registerUserFailed(error?.message))
    } else {
      yield put(registerUserFailed(error))
    }
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
  yield takeEvery(REGISTER_USER_WV, registerUserWV)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
