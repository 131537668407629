import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  GET_COMPANY_EMPLOYEES,
  UPDATE_USER_ROLE,
  INVITE_COMPANY_EMPLOYEE,
  DELETE_PROFILE,
} from "./actionTypes"
import {
  getCompanyEmployeesSuccess,
  getCompanyEmployeesError,
  deleteProfileError,
  inviteCompanyError,
  inviteCompanySuccess,
  updateUserRoleError,
  deleteProfileSuccess,
  updateUserRoleSuccess,
} from "./actions"

import { toast } from "react-toastify"

import { getFirebaseBackend } from "helpers/firebase_helper"
import { formatFirestoreTimestampDate, formatFirestoreTimestampTime } from "helpers/date_helper"
const fireBaseBackend = getFirebaseBackend()

const formatDate = item => {
  if (item.created_at) {
    item.created_at = {
      date: formatFirestoreTimestampDate(item.created_at),
      time: formatFirestoreTimestampTime(item.created_at)
    };
  }

  if (item.modified_at) {
    item.modified_at = {
      date: formatFirestoreTimestampDate(item.modified_at),
      time: formatFirestoreTimestampTime(item.modified_at)
    };
  }

  if (item.last_login_time) {
    item.last_login_time = {
      date: formatFirestoreTimestampDate(item.last_login_time),
      time: formatFirestoreTimestampTime(item.last_login_time)
    };
  }

  return item
}

function* onGetCompanies() {
  yield put(getCompaniesLoadingStatus(true))
  try {
    const response = yield call(fireBaseBackend.getCompaniesWithListener)
    const updatedResponse = response.map(item => ({
      ...item,
      created_at: {
        date: convertFirebaseTimestampToFormattedDate(item.created_at)
      }
    }));
    yield put(getCompaniesSuccess(updatedResponse))
  } catch (error) {
    yield put(getCompaniesFail(error))
  }
  yield put(getCompaniesLoadingStatus(false))
}

function* onGetCompanyDetail({ payload }) {
  yield put(getCompaniesLoadingStatus(true))
  try {
    const response = yield call(fireBaseBackend.getCompanyDetail, payload)
    yield put(getCompanyDetailSuccess(response))
    yield put(getCompanyGames(response.id))
  } catch (error) {
    yield put(getCompanyDetailFail(error))
  }
  yield put(getCompaniesLoadingStatus(false))
}


function* onGetCompanyEmployees({ payload }) {
  try {
    let data = yield call(fireBaseBackend.getCompanyEmployees, payload);

    data.employees.sort((a, b) => {
      const timestampA = a?.created_at?._seconds * 1000000000 + a?.created_at?._nanoseconds;
      const timestampB = b.created_at?._seconds * 1000000000 + b.created_at?._nanoseconds;
      return timestampB - timestampA;
    });

    data = {
      ...data,
      employees: data.employees.map(formatDate),
    }
    yield put(getCompanyEmployeesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getCompanyEmployeesError());
  }
}

function* onUpdateUserRole({ payload }) {
  try {
    const response = yield call(fireBaseBackend.updateUserRole, payload)
    yield put(updateUserRoleSuccess(response))
    toast.success("Role Updated Successful", { autoClose: 2000 })
  } catch (error) {
    console.log(error)
    yield put(updateUserRoleError(error))
    toast.error("Role Updates Failed", { autoClose: 2000 })
  }
}

function* onInviteUser({ payload }) {
  try {
    const response = yield call(fireBaseBackend.inviteUser, {
      email: payload.email,
      role: payload.role,
      first_name: payload.first_name,
      last_name: payload.last_name
    })
    yield put(inviteCompanySuccess(response))
    toast.success("Invited Successfully", { autoClose: 2000 })
  } catch (error) {
    console.error(error)
    console.log(error, 'Error')
    yield put(inviteCompanyError())
    toast.error("User already invited or registered.", { autoClose: 2000 })
  }
}

function* deleteProfile({ payload }) {
  try {
    const data = yield call(fireBaseBackend.deleteProfile, payload);
    toast.success("Deleted Successfully", { autoClose: 2000 })
    yield put(deleteProfileSuccess(data));
  } catch (error) {
    console.log(error);
    toast.error("Unexpected Error", { autoClose: 2000 })
    yield put(deleteProfileError);
  }
}


function* companySaga() {
  yield takeEvery(GET_COMPANY_EMPLOYEES, onGetCompanyEmployees)
  yield takeEvery(UPDATE_USER_ROLE, onUpdateUserRole)
  yield takeEvery(INVITE_COMPANY_EMPLOYEE, onInviteUser)
  yield takeEvery(DELETE_PROFILE, deleteProfile)
}

export default companySaga
