export const NO_USER = "NO_USER"
export const GET_ME = "GET_ME"
export const GET_ME_SUCCESS = "GET_ME_SUCCESS"
export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_USER_WV = "LOGIN_USER_WV"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"
