import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_USERS,
  GET_USER_PROFILE,
  UPDATE_USER,
} from "./actionTypes"

import {
  getUsersSuccess,
  getUsersFail, 
  updateUserSuccess,
  updateUserFail,
  getUsersLoadingStatus,
} from "./actions"

import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { getFirebaseBackend } from "helpers/firebase_helper"

const fireBaseBackend = getFirebaseBackend()

function* fetchUsers() {
  console.log("TRIGGERED")
  yield put(getUsersLoadingStatus(true))
  console.log("TRIGGERED")
  try {
    const response = yield call(fireBaseBackend.getPlayers)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
  yield put(getUsersLoadingStatus(false))
}

function* onUpdateUser({ payload: { user, userId } }) {
  try {
    const response = yield call(fireBaseBackend.editProfileAPI, user, userId)
    yield put(updateUserSuccess(response))
    toast.success("Contact Updated Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateUserFail(error))
    toast.error("Contact Updated Failed", { autoClose: 2000 })
  }
}


function* contactsSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(UPDATE_USER, onUpdateUser)
}

export default contactsSaga
