import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { GET_API_LOGS, } from "./actionTypes";
import { getApiLogsError, getApiLogsSuccess } from "./actions";
import { getFirebaseBackend } from "helpers/firebase_helper";
import { formatTimestamp } from "helpers/date_helper";
import { toast } from "react-toastify"

const fireBaseBackend = getFirebaseBackend();

function* getApiLogs({ payload }) {
    try {
        const data = yield call(fireBaseBackend.getApiLogs, payload);
        const formattedData = data.map(item => {
            return {
                ...item,
                timestamp: formatTimestamp(item.timestamp)
            };
        });

        yield put(getApiLogsSuccess(formattedData));
    } catch (error) {
        console.log(error);
        yield put(getApiLogsError());
    }
}

export function* watchBundles() {
    yield takeEvery(GET_API_LOGS, getApiLogs);
}

function* ApiLogsSaga() {
    yield all([fork(watchBundles)]);
}

export default ApiLogsSaga;