import React, { useState } from "react"
import { Col, Input, Label, Modal, ModalBody, ModalHeader, Row, FormFeedback, Form, } from "reactstrap"
import Select from "react-select"
import { useFormik } from "formik"
import { useDispatch } from "react-redux"
import { inviteUser } from "store/actions"


const InviteModal = ({ toggle, initialValues, validationData, company, optionsData }) => {
    const dispatch = useDispatch()
    const [selectedUserRole, setSelectedUserRole] = useState({
        value: '',
        label: 'Role',
    })

    const validation = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: validationData,
        onSubmit: values => {
            dispatch(inviteUser({
                email: values.email,
                role: values.role,
                first_name: values.first_name,
                last_name: values.last_name,
                account: company && company.id
            }));
            validation.resetForm()
            toggle()
        },
    })

    return (
        <Modal isOpen={true} toggle={toggle} centered={true}>

            {(company) ?
                <ModalHeader toggle={toggle} tag="h4">
                    Invite User to {company.company}
                </ModalHeader>
                :
                <ModalHeader toggle={toggle} tag="h4">
                    Invite User
                </ModalHeader>
            }
            <ModalBody>
                <Form
                    onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <Row>
                        <Col xs={12}>
                            <div className="mb-3">
                                <Label className="form-label">First Name</Label>
                                <Input
                                    name="first_name"
                                    type="text"
                                    placeholder="First Name"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.first_name || ""}
                                    invalid={
                                        validation.touched.first_name && validation.errors.firstname
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.first_name && validation.errors.first_name ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.first_name}
                                    </FormFeedback>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Last Name</Label>
                                <Input
                                    name="last_name"
                                    type="text"
                                    placeholder="Last Name"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.last_name || ""}
                                    invalid={
                                        validation.touched.last_name && validation.errors.last_name
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.last_name && validation.errors.last_name ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.last_name}
                                    </FormFeedback>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Email</Label>
                                <Input
                                    name="email"
                                    type="text"
                                    placeholder="Email"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.email || ""}
                                    invalid={
                                        validation.touched.email && validation.errors.email
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.email && validation.errors.email ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.email}
                                    </FormFeedback>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">User Role</Label>
                                <Select
                                    options={optionsData}
                                    styles={{
                                        menu: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: "#fff",
                                        }),
                                    }}
                                    name="role"
                                    value={selectedUserRole}
                                    onChange={e => {
                                        setSelectedUserRole(e)
                                        validation.setFieldValue("role", e.value)
                                    }}
                                />
                                {validation.touched.role && validation.errors.role ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.role}
                                    </FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="text-end">
                                <button
                                    type="submit"
                                    className="btn btn-success save-user"
                                >
                                    Invite
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default InviteModal;