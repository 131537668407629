import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Button, Col, Container } from "reactstrap"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import List from "components/List"
import EditRoleModal from "../../components/modals/EditRoleModal"
import { getAllUsers, updateUserRoleAdmin } from "store/admin/actions"
import { getTableConfigAdmin } from "./getTableConfigAdmin"
import { getTableConfigSuper } from "./getTableConfigSuper"
import { deleteProfilAdmin } from "store/actions";
import Loader from "components/Loader"
import DeleteModal from "components/modals/DeleteModal"
import InviteModal from "components/modals/InviteModal"
import * as Yup from "yup"


export const getRoleLabel = (role) => {
    switch (role) {
        case 0: return "Third Pillar Super Admin";
        case 1: return "Third Pillar Admin";
        case 2: return "Game Developer Admin";
        case 3: return "Player";
        case 4: return "Game Developer User";
        default: return "Unknown Role";
    }
}

const initalEditModalData = {
    isOpen: false,
    data: {}
}

const initalDeleteModalData = {
    isOpen: false,
    data: {}
}

const Users = () => {
    const dispatch = useDispatch();
    const { data, loading } = useSelector(state => state.Admin);
    const { user } = useSelector(state => state.Account)
    const tableConfigData = user?.role === 1 ? getTableConfigAdmin : getTableConfigSuper
    const [isModalInviteOpen, setIsModalInviteOpen] = useState(false);
    const [modalEditData, setModalEditData] = useState(initalEditModalData);
    const [modalDeleteData, setModalDeleteData] = useState(initalDeleteModalData);

    const optionsData = user?.role === 1 ? [
        { value: 3, label: "Player" },
    ] : [
        { value: 0, label: "Third Pillar Super Admin" },
        { value: 1, label: "Third Pillar Admin" },
        { value: 3, label: "Player" },
    ];

    const optionsDataAdmin = user?.role === 0 ?[
        { value: 0, label: "Third Pillar Super Admin" },
        { value: 1, label: "Third Pillar Admin" },
        { value: 2, label: "Game Developer Admin" },
        { value: 3, label: "Player" },
        { value: 4, label: "Game Developer User" }
    ] : [
        { value: 2, label: "Game Developer Admin" },
        { value: 3, label: "Player" },
        { value: 4, label: "Game Developer User" },
    ]
    
    const toggleIsModalInviteOpen = () => setIsModalInviteOpen(prev => !prev);

    const toggleModalEditData = useCallback((params) => {
        setModalEditData(prev => {
            const newOpen = !prev.isOpen;

            if (newOpen) {
                const { id, role } = params;
                return {
                    isOpen: newOpen,
                    data: {
                        id,
                        role,
                    }
                }
            }

            return initalEditModalData
        })
    }, [setModalEditData])

    const toggleModalDeleteData = useCallback((params) => {
        setModalDeleteData(prev => {
            const newOpen = !prev.isOpen;

            if (newOpen) {
                const { id, email } = params;
                return {
                    isOpen: newOpen,
                    data: {
                        id,
                        email
                    }
                }
            }

            return initalDeleteModalData
        })
    }, [setModalDeleteData])

    const tableConfig = useMemo(() => (
        tableConfigData(toggleModalEditData, toggleModalDeleteData)
    ), [toggleModalEditData, toggleModalDeleteData])


    useEffect(() => {
        dispatch(getAllUsers())
    }, [dispatch])

    if (!tableConfig || loading ) {
        return <Loader />
    }

    return (
        <React.Fragment>
            <Container fluid style={{ paddingTop: '75px' }} >
                <List
                    companies={data?.users ?? ''}
                    loading={loading}
                    tableConfig={tableConfig}
                    rightHeaderContent={(
                        <Col sm={6}>
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    className={'btn btn-success btn-rounded waves-effect waves-light mb-2 btn btn-secondary'}
                                    onClick={toggleIsModalInviteOpen}
                                >
                                    <i className="mdi mdi-plus me-1"></i> Invite User
                                </Button>
                            </div>
                        </Col>
                    )}
                />
                {isModalInviteOpen && (
                    <InviteModal
                        toggle={toggleIsModalInviteOpen}
                        role={user?.role}
                        initialValues={{
                            email: '',
                            role: '',
                            first_name: '',
                            last_name: ''
                        }}
                        validationData={Yup.object({
                            role: Yup.number().required("User Role is Required"),
                            email: Yup.string().email("Invalid email"),
                            last_name: Yup.string().required("Last Name is Required"),
                            first_name: Yup.string().required("First Name is email"),
                        })}
                        optionsData={optionsData}
                    />
                )}

                {modalEditData && (
                    <EditRoleModal
                        modalData={modalEditData}
                        toggle={toggleModalEditData}
                        role={user?.role}
                        optionsData={optionsDataAdmin}
                        action={updateUserRoleAdmin}
                    />
                )}

                {modalDeleteData && (
                    <DeleteModal
                        modalData={modalDeleteData}
                        toggle={toggleModalDeleteData}
                        action={deleteProfilAdmin}
                        text={'Are you sure you want to permanently delete the user?'}
                    />
                )}

            </Container>
        </React.Fragment>
    )
}

export default Users;