import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { createSelector } from 'reselect';

const PrivateRoute = ({ children, allowedRoles }) => {
    const AccountProperties = createSelector(
        state => state.Account,
        account => ({
            user: account.user,
            registrationError: account.registrationError,
            success: account.success,
        })
    )

    const { user } = useSelector(AccountProperties)
    if (allowedRoles && user) {
        return allowedRoles?.includes(user.role) ? children : <Navigate to="/pages-404" />
    } else if (!user) {
        return <Navigate to="/login" />;
    }

    return children
};

export default PrivateRoute;