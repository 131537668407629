import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import {
  editProfile,
  resetProfileFlag,
  updatePassword,
} from "../../store/actions"

import firebase from "firebase/compat/app"

const UserProfile = () => {
  //meta title
  document.title = "Profile | Third Pillar"

  const dispatch = useDispatch()

  const [first_name, setFirst_name] = useState("")
  const [last_name, setLast_name] = useState("")
  const [username, setUsername] = useState("")

  const user = firebase.auth().currentUser;
  const displayName = user?.displayName;
  const email = user?.email;

  let firstLetter = ''
  if (user ) {
    firstLetter = displayName ? displayName[0].toUpperCase() : email[0].toUpperCase();
  }

  const ProfileProperties = createSelector(
    state => state.Profile,
    profile => ({
      error: profile.error,
      success: profile.success,
    })
  )

  const { error, success } = useSelector(ProfileProperties)

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      console.log(localStorage.getItem("authUser"))
      const obj = JSON.parse(localStorage.getItem("authUser"))
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setUsername(obj?.username)
        setLast_name(obj?.last_name)
        setFirst_name(obj?.first_name)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setUsername(obj?.username)
        setLast_name(obj?.last_name)
        setFirst_name(obj?.first_name)
      }
      setTimeout(() => {
        dispatch(resetProfileFlag())
      }, 3000)
    }
  }, [dispatch, success])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      first_name: first_name || "",
      last_name: last_name || "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string()
        .matches(/^\S+$/, "Please Enter a Single Word")
        .required("Please Enter Your First Name"),
      last_name: Yup.string()
        .matches(/^\S+$/, "Please Enter a Single Word")
        .required("Please Enter Your Last Name"),
    }),
    onSubmit: values => {
      dispatch(editProfile(values, firebase.auth().currentUser?.uid))
    },
  })

  const passwordValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      dispatch(updatePassword(values?.password))
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Third Pillar" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <div
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '50%',
                          backgroundColor: 'rgb(127 145 158)',
                          color: 'white',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: '20px',
                        }}
                      >
                        {firstLetter}
                      </div>
                    </div>
                    <div className="text-muted ms-3 ">
                      <h5>{displayName}</h5>
                      <p className="mb-1">
                        {email}
                      </p>
                      <p className="mb-0">
                        Id: #{firebase.auth().currentUser?.uid}
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Edit Profile</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="form-group mt-4">
                  <Label className="form-label">First Name</Label>
                  <Input
                    name="first_name"
                    // value={name}
                    className="form-control text-capitalize"
                    placeholder="Enter First Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.first_name || ""}
                    invalid={
                      validation.touched.first_name &&
                        validation.errors.first_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.first_name &&
                    validation.errors.first_name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.first_name}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="form-group mt-4">
                  <Label className="form-label">Last Name</Label>
                  <Input
                    name="last_name"
                    // value={name}
                    className="form-control text-capitalize"
                    placeholder="Enter Last Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.last_name || ""}
                    invalid={
                      validation.touched.last_name &&
                        validation.errors.last_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.last_name &&
                    validation.errors.last_name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.last_name}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Submit
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>

          <h4 className="card-title mb-4">Change Password</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  passwordValidation.handleSubmit()
                  return false
                }}
              >
                <div className="form-group mt-1">
                  <Label className="form-label">Password</Label>
                  <Input
                    name="password"
                    // value={name}
                    className="form-control"
                    placeholder="Current Password"
                    type="text"
                    onChange={passwordValidation.handleChange}
                    onBlur={passwordValidation.handleBlur}
                    value={passwordValidation.values.password || ""}
                    invalid={
                      passwordValidation.touched.password &&
                        passwordValidation.errors.password
                        ? true
                        : false
                    }
                  />
                  {passwordValidation.touched.password &&
                    passwordValidation.errors.password ? (
                    <FormFeedback type="invalid">
                      {passwordValidation.errors.password}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="form-group mt-4">
                  <Label className="form-label">Confirm Password</Label>
                  <Input
                    name="confirmPassword"
                    // value={name}
                    className="form-control"
                    placeholder="Confirm Password"
                    type="text"
                    onChange={passwordValidation.handleChange}
                    onBlur={passwordValidation.handleBlur}
                    value={passwordValidation.values.confirmPassword || ""}
                    invalid={
                      passwordValidation.touched.confirmPassword &&
                        passwordValidation.errors.confirmPassword
                        ? true
                        : false
                    }
                  />
                  {passwordValidation.touched.confirmPassword &&
                    passwordValidation.errors.confirmPassword ? (
                    <FormFeedback type="invalid">
                      {passwordValidation.errors.confirmPassword}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Change Password
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserProfile
