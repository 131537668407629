import React from "react"
import { Container } from "reactstrap"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import GameDevGames from "./GameDevGames"
import PlayerGames from "./PlayerGames"

const componentData = {
    2: {
        Component: GameDevGames,
    },
    3: {
        Component: PlayerGames,
    },
    4: {
        Component: GameDevGames,
    },
}


const Games = () => {
    const AccountProperties = createSelector(
        state => state.Account,
        account => ({
            user: account.user,
            registrationError: account.registrationError,
            success: account.success,
        })
    )

    const { user } = useSelector(AccountProperties)
    const { Component } = componentData[user?.role] || { Component: () => null }

    return (
        <React.Fragment>
            <Container fluid>
                <Component />
            </Container>
        </React.Fragment>
    )
}

export default Games