import React, { useState } from "react"
import { Col, Input, Label, Modal, ModalBody, ModalHeader, Row, FormFeedback, Form, } from "reactstrap"
import Select from "react-select"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"


const GameModal = ({ toggle, action, id }) => {
    const dispatch = useDispatch()
    const [selectedStatus, setSelectedStatus] = useState({
        value: '0',
        label: 'Pending',
    })

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            game_name: '',
            description: '',
            game_id: '',
            game_bundle_id: '',
            status: 0
        },

        validationSchema: Yup.object({
            game_name: Yup.string()
                .trim()
                .required("Game name is required"),
            description: Yup.string()
                .trim()
                .required("Description is required"),
            game_id: Yup.string()
                .trim()
                .required("Game ID is required"),
            game_bundle_id: Yup.string()
                .trim()
                .required("Game Bundle ID is required"),
            status: Yup.string()
                .required("Status is required"),
        }),

        onSubmit: values => {
            dispatch(action({
                name: values.game_name,
                description: values.description,
                game_id: values.game_id,
                game_bundle_id: values.game_bundle_id,
                status: values.status,
                id
            }));
            validation.resetForm()
            toggle()
        },
    })

    return (
        <Modal isOpen={true} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle} tag="h4">
                Add Game
            </ModalHeader>
            <ModalBody>
                <Form
                    onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <Row>
                        <Col xs={12}>
                            <div className="mb-3">
                                <Label className="form-label">Game Name</Label>
                                <Input
                                    name="game_name"
                                    type="text"
                                    placeholder="Game Name"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.game_name || ""}
                                    invalid={
                                        validation.touched.game_name && validation.errors.game_name
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.first_name && validation.errors.first_name ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.first_name}
                                    </FormFeedback>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Game Id</Label>
                                <Input
                                    name="game_id"
                                    type="text"
                                    placeholder="Game Id"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.game_id || ""}
                                    invalid={
                                        validation.touched.game_id && validation.errors.game_id
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.game_id && validation.errors.game_id ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.game_id}
                                    </FormFeedback>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Game Bundle Id</Label>
                                <Input
                                    name="game_bundle_id"
                                    type="text"
                                    placeholder="Game Bundle Id"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.game_bundle_id || ""}
                                    invalid={
                                        validation.touched.game_bundle_id && validation.errors.game_bundle_id
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.game_bundle_id && validation.errors.game_bundle_id ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.game_bundle_id}
                                    </FormFeedback>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Description</Label>
                                <Input
                                    name="description"
                                    type="text"
                                    placeholder="Description"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.description || ""}
                                    invalid={
                                        validation.touched.description && validation.errors.description
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.description && validation.errors.description ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.description}
                                    </FormFeedback>
                                ) : null}
                            </div>

                            <div className="mb-3">
                                <Label className="form-label">Status</Label>
                                <Select
                                    options={[
                                        { value: 0, label: "Pending" },
                                        { value: 1, label: "Live" },
                                    ]}
                                    styles={{
                                        menu: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: "#fff",
                                        }),
                                    }}
                                    name="status"
                                    value={selectedStatus}
                                    onChange={e => {
                                        setSelectedStatus(e)
                                        validation.setFieldValue("status", e.value)
                                    }}
                                />
                                {validation.touched.role && validation.errors.role ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.role}
                                    </FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="text-end">
                                <button
                                    type="submit"
                                    className="btn btn-success save-user"
                                >
                                    Add
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default GameModal;