import React from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Modal, ModalBody, ModalHeader, Row, FormFeedback, Form, Input } from "reactstrap"
import { useDispatch } from "react-redux"
import { addKey } from "store/bundleDetails/actions"


const AddKeyModal = ({ toggle, account, bundleId }) => {
    const dispatch = useDispatch()

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            key: '',
        },
        validationSchema: Yup.object({
            key: Yup.string().required("Key is Required"),
        }),
        onSubmit: values => {
            dispatch(addKey({
                key: values.key,
                account,
                bundleId
            }));

            validation.resetForm()
            toggle()
        },
    })


    return (
        <Modal isOpen={true} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle} tag="h4">
                Add Key
            </ModalHeader>
            <ModalBody>
                <Form
                    onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                    }}
                >
                    <Row className="flex">
                        <div className="mb-3">
                            <Input
                                name="key"
                                type="text"
                                placeholder="Key"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.key || ""}
                                invalid={
                                    validation.touched.key && validation.errors.key
                                        ? true
                                        : false
                                }
                            />
                            {validation.touched.key && validation.errors.key ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.key}
                                </FormFeedback>
                            ) : null}
                        </div>
                        <div className="text-end">
                            <button
                                type="submit"
                                className="btn btn-success save-user"
                            >
                                Add
                            </button>
                        </div>
                    </Row>

                </Form>
            </ModalBody>
        </Modal>

    )
}

export default AddKeyModal;