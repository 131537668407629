import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import { EDIT_PASSWORD_PROFILE, EDIT_PROFILE } from "./actionTypes"
import { profileSuccess, profileError } from "./actions"
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { loginSuccess } from "../login/actions"
import { toast } from "react-toastify"

const fireBaseBackend = getFirebaseBackend()

function* editProfile({ payload: { user, userId } }) {
  try {
    yield call(fireBaseBackend.editProfileAPI, user, userId)
    toast.success("Profile Updated Successful", { autoClose: 2000 })
  } catch (error) {
    yield put(profileError(error))
  }
}

function* changeUserPassword({ payload }) {
  try {
    const response = yield call(fireBaseBackend.changeUserAuthPassword, payload)
    yield put(profileSuccess(response))
  } catch (error) {
    yield put(profileError(error))
  }
}



export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
  yield takeEvery(EDIT_PASSWORD_PROFILE, changeUserPassword)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
