import React, { useEffect, useMemo, useState } from "react"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"


//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { getCompanyGames } from "store/actions"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  Button,
} from "reactstrap"
import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import { Link, useNavigate, useParams } from "react-router-dom"
import { convertFirebaseTimestampToFormattedDate } from "helpers/date_helper"

const SelectGame = () => {
  //meta title
  document.title = "Jobs List | Third Pillar"

  const { id } = useParams()
  const navigate = useNavigate()
  //   const id = "A5MFyQdjkQaxsk1iE7A2"

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [job, setJob] = useState(null)

  const dispatch = useDispatch()

  const AccountProperties = createSelector(
    state => state.Account,
    account => ({
      user: account.user,
      registrationError: account.registrationError,
      success: account.success,
      // loading: account.loading,
    })
  )

  const CompanyDetailProperties = createSelector(
    state => state.Company,
    Company => ({
      companyGames: Company.companyGames,
    })
  )

  const { user } = useSelector(AccountProperties)
  const { companyGames } = useSelector(CompanyDetailProperties)
  const [selectedGame, setSelectedGame] = useState(null)

  // useEffect(() => {
  //   if (id) {
  //     // dispatch(getCompanyGames(id))
  //   }
  // }, [getCompanyGames])

  //delete Job
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = job => {
    setJob(job)
    setDeleteModal(true)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Game" breadcrumbItem="Games Lists" /> */}
          {!companyGames || (companyGames && !companyGames.length) ? (
            <Spinners setLoading={() => {}} />
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody className="border-bottom">
                    <div className="d-flex align-items-center">
                      <h5 className="mb-0 card-title flex-grow-1">
                        {companyGames && companyGames.length > 1
                          ? "Select a Game"
                          : ""}
                      </h5>
                    </div>
                  </CardBody>
                  <CardBody>
                    <Row className="mb-2">
                      <Col xs={9}>
                        <select
                          className="form-select pageSize mb-2"
                          value={
                            companyGames && companyGames.length
                              ? companyGames[0]
                              : ""
                          }
                          onChange={e => {
                            setSelectedGame(e.target.value)
                          }}
                        >
                          {companyGames && companyGames.length
                            ? companyGames.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))
                            : null}
                        </select>
                      </Col>
                      <Col xs={3}>
                        <Link
                          to={`/logs/${
                            selectedGame ? selectedGame.id : companyGames[0].id
                          }`}
                        >
                          <Button
                            color="primary"
                            className="btn btn-primary w-100"
                          >
                            Show Logs
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                    {/* <TableContainer
                      columns={columns}
                      data={companyGames || []}
                      isCustomPageSize={true}
                      handleUserClick={row => {
                        console.log("row", row)
                        // console.log("original", row.original)
                        // navigate(`/logs/${row.original.game_bundle_id}`)
                        // console.log("games from row", games)
                        // navigate(`/logs/${companyGames}`)
                        // navigate(`/logs/${games.game_bundle_id}`)
                      }}
                      isGlobalFilter={true}
                      isJobListGlobalFilter={true}
                      isPagination={true}
                      SearchPlaceholder="Search for ..."
                      tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline mt-4 border-top"
                      pagination="pagination"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    /> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default SelectGame
