export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_ERROR = 'GET_ALL_USERS_ERROR';
export const UPDATE_USER_ROLE_ADMIN_ERROR = 'UPDATE_USER_ROLE_ADMIN_ERROR';
export const UPDATE_USER_ROLE_ADMIN_SUCCESS = 'UPDATE_USER_ROLE_ADMIN_SUCCESS';
export const UPDATE_USER_ROLE_ADMIN = 'UPDATE_USER_ROLE_ADMIN';
export const DELETE_PROFILE_ADMIN = 'DELETE_PROFILE_ADMIN';
export const DELETE_PROFILE_ADMIN_SUCCESS = 'DELETE_PROFILE_ADMIN_SUCCESS';
export const DELETE_PROFILE_ADMIN_ERROR = 'DELETE_PROFILE_ADMIN';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const INVITE_USER = 'INVITE_USER';
export const INVITE_USER_ERROR = 'INVITE_USER_ERROR';
export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS';
export const GET_DASHBOARD_DATA_ERROR = 'GET_DASHBOARD_DATA_ERROR'
export const GET_CHARTS_DATA = 'GET_CHARTS_DATA';
export const GET_CHARTS_DATA_SUCCESS = 'GET_CHARTS_DATA_SUCCESS';
export const GET_CHARTS_DATA_ERROR = 'GET_CHARTS_DATA_ERROR'
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_ERROR = "GET_COMPANIES_ERROR";
export const ADD_COMPANY = "ADD_COMPANY";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_ERROR = "ADD_COMPANY_ERROR";
export const EDIT_COMPANY = "EDIT_COMPANY";
export const EDIT_COMPANY_SUCCESS = "EDIT_COMPANY_SUCCESS";
export const EDIT_COMPANY_ERROR = "EDIT_COMPANY_ERROR";
export const UPDATE_BUNDLE_DATA = "UPDATE_BUNDLE_DATA";
export const UPDATE_BUNDLE_DATA_SUCCESS = "UPDATE_BUNDLE_DATA_SUCCESS";
export const UPDATE_BUNDLE_DATA_ERROR = "UPDATE_BUNDLE_DATA_ERROR";
export const GET_BUNDLE = 'GET_BUNDLE';
export const GET_BUNDLE_SUCCESS = 'GET_BUNDLE_SUCCESS';
export const GET_BUNDLE_ERROR = 'GET_BUNDLE_ERROR';
export const ADD_GAME = 'ADD_GAME';
export const ADD_GAME_SUCCESS = 'ADD_GAME_SUCCESS';
export const ADD_GAME_ERROR = 'ADD_GAME_ERROR';
export const DELETE_BUNDLE = 'DELETE_BUNDLE';
export const DELETE_BUNDLE_SUCCESS = 'DELETE_BUNDLE_SUCCESS';
export const DELETE_BUNDLE_ERROR = 'DELETE_BUNDLE_ERROR';