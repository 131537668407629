import {
  GET_ORDERS_FAIL,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_LOADING_STATUS,

} from "./actionTypes"

const INIT_STATE = {
  products: [],
  product: {},
  orders: [],
  cartData: {},
  customers: [],
  productComments: [],
  shops: [],
  error: {},
  loading: true,
}

const Ecommerce = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
        loading: false
      }

    case GET_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ORDERS_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}

export default Ecommerce
