import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  EDIT_PASSWORD_PROFILE,
} from "./actionTypes"

export const editProfile = (user, userId) => {
  return {
    type: EDIT_PROFILE,
    payload: { user, userId },
  }
}

export const updatePassword = password => {
  return {
    type: EDIT_PASSWORD_PROFILE,
    payload: password,
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}

