import React from "react"
import { Container } from "reactstrap"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"

//Import Components

import DashboardGameDev from "components/GameDeveloper/DashboardGameDeveloper/index.js"
import DashboardPlayer from "components/Player/DashboardPlayer"
import DashboardThirdAdmin from "components/ThirdPillarAdmin/DashboardThirdAdmin"


const componentData = {
  0: {
    Component: DashboardThirdAdmin,
  },
  1: {
    Component: DashboardThirdAdmin,
  },
  2: {
    Component: DashboardGameDev,
  },
  3: {
    Component: DashboardPlayer,
  },
  4: {
    Component: DashboardGameDev,
  }
}

const DashboardSaas = () => {
  const AccountProperties = createSelector(
    state => state.Account,
    account => ({
      user: account.user,
      registrationError: account.registrationError,
      success: account.success,
    })
  )

  const { user } = useSelector(AccountProperties)
  const { Component } = componentData[user?.role] || { Component: () => null }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Component user={user} />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DashboardSaas
