import React, { useEffect, useState } from "react"
import { Col, Label, Modal, ModalBody, ModalHeader, Row, FormFeedback, Form, } from "reactstrap"
import Select from "react-select"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import { updateBundleData } from "store/actions"

const EditStatusModal = ({ modalData, toggle }) => {
    const { isOpen, data } = modalData;
    const dispatch = useDispatch();

    const [selectedUserStatus, setSelectedUserStatus] = useState({
        value: data?.status ?? 0,
        label: data?.status === 0 ? 'Pending' : 'Live'
    });

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            status: data?.status,
        },        
        validationSchema: Yup.object({
            status: Yup.number().required("Status is Required"),
        }),
        onSubmit: values => {
            dispatch(updateBundleData({
                id: data.id,
                status: values.status
            }));

            validation.resetForm()
            toggle()
        },
    })

    useEffect(() => {
        setSelectedUserStatus(prev => ({
            ...prev,
            label: data?.status === 0 ? 'Pending' : 'Live'
        }));
    }, [data.status]);

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle} tag="h4">
                Edit Game Status
            </ModalHeader>
            <ModalBody>
                <Form
                    onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                    }}
                >
                    <Row>
                        <Col xs={12}>
                            <div className="mb-3">
                                <Select
                                    options={[{ value: 0, label: "Pending" }, { value: 1, label: "Live" }]}
                                    styles={{
                                        menu: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: "#fff",
                                        }),
                                    }}
                                    name="status"
                                    value={selectedUserStatus}
                                    onChange={e => {
                                        setSelectedUserStatus(e);
                                        validation.setFieldValue("status", e.value);
                                    }}
                                    
                                />
                                {validation.touched.status && validation.errors.status ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.status}
                                    </FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="text-end">
                                <button
                                    type="submit"
                                    className="btn btn-success save-user"
                                >
                                    Save
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default EditStatusModal;