import React from "react"
import { Container } from "reactstrap"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import AdminLogs from "./AdminLogs"
import GameDevLogs from "./GameDevLogs"

const componentData = {
    0: {
        Component: AdminLogs,
    },
    1: {
        Component: AdminLogs,
    },
    2: {
        Component: GameDevLogs,
    },
    4: {
        Component: GameDevLogs,
    }
}

const GamesLog = () => {
    const AccountProperties = createSelector(
        state => state.Account,
        account => ({
            user: account.user,
            registrationError: account.registrationError,
            success: account.success,
        })
    )

    const { user } = useSelector(AccountProperties)
    const { Component } = componentData[user?.role] || { Component: () => null }

    return (
        <React.Fragment>
            <Container fluid>
                <Component />
            </Container>
        </React.Fragment>
    )
}

export default GamesLog