import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import List from "components/List"
import { getCompanies } from "store/actions"
import Loader from "components/Loader"
import getTableConfig from "./tableConfig"
import { Col, Container, Row, Button } from "reactstrap"
import CompanyModal from "./CompanyModal"

const Companies = () => {
    const dispatch = useDispatch();
    const { companies, loading } = useSelector(state => state.Admin);
    const [editModal, setEditModal] = useState({
        isOpen: false,
        data: null,
        isEdit: false
    })

    const openEditModal = useCallback((data) => {
        setEditModal({
            isOpen: true,
            isEdit: true,
            data,
        })
    }, [])

    const tableConfig = useMemo(() => (
        getTableConfig(openEditModal)
    ), [])

    useEffect(() => {
        dispatch(getCompanies())
    }, [dispatch])


    if (!tableConfig || loading || !companies) {
        return <Loader />
    }

    return (
        <React.Fragment>
            <Container fluid>
                <List
                    companies={companies}
                    loading={loading}
                    tableConfig={tableConfig}
                    rightHeaderContent={(
                        <Col sm={6}>
                            <div className="text-sm-end">

                                <Button
                                    to=""
                                    onClick={() => {
                                        setEditModal({
                                            isOpen: true,
                                            data: null,
                                            isEdit: false
                                        })
                                    }}
                                    className={'btn btn-success btn-rounded waves-effect waves-light mb-2 btn btn-secondary'}
                                >
                                    <i className="mdi mdi-plus me-1"></i> Add New Company
                                </Button>
                            </div>
                        </Col>
                    )}
                />
            </Container>

            {editModal.isOpen && (
                <CompanyModal
                    toggle={() => setEditModal(prev => ({
                        isOpen: !prev.isOpen,
                        data: null,
                        isEdit: false
                    }))}
                    initialValues={editModal.data || undefined}
                    isEdit={editModal.isEdit}
                />
            )}
        </React.Fragment>
    )
}

export default Companies;
