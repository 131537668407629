import { GAMEDEV_GET_ACCOUNT_DATA, GAMEDEV_GET_ACCOUNT_DATA_ERROR, GAMEDEV_GET_ACCOUNT_DATA_SUCCESS, GET_CHARTS_DATA_DEV, GET_CHARTS_DATA_DEV_SUCCESS } from "./actionTypes";

const initialState = {
    loading: true,
    data: null,
    charts: null
};

const GameDevAccount = (state = initialState, action) => {
    switch (action.type) {
        case GAMEDEV_GET_ACCOUNT_DATA: {
            state = {
                ...state,
                loading: true
            }
            break;
        }
        case GAMEDEV_GET_ACCOUNT_DATA_SUCCESS: {
            state = {
                ...state,
                data: action.payload,
                loading: false
            }
            break;
        }
        case GAMEDEV_GET_ACCOUNT_DATA_ERROR: {
            return state = {
                ...state,
                loading: false
            }
        }
        case GET_CHARTS_DATA_DEV: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_CHARTS_DATA_DEV_SUCCESS: {
            return {
                ...state,
                charts: action.payload,
                loading: false
            };
        }
        case GAMEDEV_GET_ACCOUNT_DATA_ERROR: {
            return {
                ...state,
                loading: false
            };
        }
    }

    return state;
};

export default GameDevAccount;