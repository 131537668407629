import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import firebase from "firebase/compat/app"
import "firebase/compat/auth"

// Login Redux States
import {
  GET_ME,
  LOGIN_USER,
  LOGIN_USER_WV,
  LOGOUT_USER,
  SOCIAL_LOGIN,
} from "./actionTypes"
import { apiError, getMeSuccess, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { registerUserFailed, registerUserSuccessful } from "../register/actions"

const fireBaseBackend = getFirebaseBackend()

function* loginUserWV({ payload: { user, history, game_bundle, player_id } }) {
  try {
    const gameRef = yield call(fireBaseBackend.getGameBundleRef, game_bundle)
    const response = yield call(
      fireBaseBackend.loginUser,
      user.email,
      user.password
    )
    const firestoreResponse = yield call(fireBaseBackend.getUserData, "User")
    if (firestoreResponse) {
      const playerData = {
        game_bundle: game_bundle,
        player_id: player_id || response.uid,
        user: firestoreResponse?.uid,
      }
      yield call(fireBaseBackend.createPlayer, playerData, gameRef)
      localStorage.setItem("authUser", JSON.stringify(firestoreResponse))
    } else {
      const userDataResponse = yield call(
        fireBaseBackend.addNewUserToFirestore,
        response
      )
      const playerData = {
        game_bundle: game_bundle,
        player_id: player_id || response.uid,
        user: firestoreResponse?.uid,
      }
      yield call(fireBaseBackend.createPlayer, playerData, gameRef)
      localStorage.setItem("authUser", JSON.stringify({ ...userDataResponse }))
    }
    yield put(loginSuccess({ firestoreResponse }))
    history("/welcome")
  } catch (error) {
    if (error?.message) {
      yield put(registerUserFailed(error?.message))
    } else if (
      error ===
      "Firebase: The supplied auth credential is incorrect, malformed or has expired. (auth/invalid-credential)."
    ) {
      yield put(registerUserFailed("User not Found"))
    } else {
      yield put(registerUserFailed(error))
    }
  }
}

function* getMe() {
  try {
    const firestoreResponse = yield call(fireBaseBackend.getUserData, "User");

    if (firestoreResponse) {
      localStorage.setItem("authUser", JSON.stringify(firestoreResponse));
      yield put(getMeSuccess(firestoreResponse))
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(
      fireBaseBackend.loginUser,
      user.email,
      user.password
    )
    const firestoreResponse = yield call(fireBaseBackend.getUserData, "User")
    if (firestoreResponse) {
      localStorage.setItem("authUser", JSON.stringify(firestoreResponse))
    } else {
      const userDataResponse = yield call(
        fireBaseBackend.addNewUserToFirestore,
        response
      )
      localStorage.setItem("authUser", JSON.stringify({ ...userDataResponse }))
    }
    yield put(loginSuccess({ firestoreResponse }))
  } catch (error) {
    if (
      error ===
      "Firebase: The supplied auth credential is incorrect, malformed or has expired. (auth/invalid-credential)."
    ) {
      yield put(apiError("Incorrect user credential"))
    } else {
      yield put(apiError(error))
    }
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess())
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({
  payload: { type, history, authFor, game_bundle, player_id },
}) {
  try {
    if (authFor && authFor === "WV") {
      const gameRef = yield call(fireBaseBackend.getGameBundleRef, game_bundle)
      const response = yield call(fireBaseBackend.socialLoginUser, type)
      const firestoreResponse = yield call(fireBaseBackend.getUserData, "User")
      if (firestoreResponse) {
        const playerData = {
          game_bundle: game_bundle,
          player_id: player_id || response.uid,
          user: firestoreResponse?.uid,
        }
        yield call(fireBaseBackend.createPlayer, playerData, gameRef)
        localStorage.setItem(
          "authUser",
          JSON.stringify({ ...firestoreResponse })
        )
        yield put(loginSuccess(response))
      } else {
        const userDataResponse = yield call(
          fireBaseBackend.addNewUserToFirestore,
          response
        )
        const playerData = {
          game_bundle: game_bundle,
          player_id: player_id || response.uid,
          user: firestoreResponse?.uid,
        }
        yield call(fireBaseBackend.createPlayer, playerData, gameRef)
        localStorage.setItem(
          "authUser",
          JSON.stringify({ ...userDataResponse })
        )
        yield put(loginSuccess(response))
      }
      history("/page-confirm-mail")
    } else {
      const response = yield call(fireBaseBackend.socialLoginUser, type)
      const firestoreResponse = yield call(fireBaseBackend.getUserData, "User")
      if (firestoreResponse) {
        localStorage.setItem(
          "authUser",
          JSON.stringify({ ...firestoreResponse })
        )
        yield put(loginSuccess(firestoreResponse))
      } else {
        const userDataResponse = yield call(
          fireBaseBackend.addNewUserToFirestore,
          response
        )
        localStorage.setItem("authUser", JSON.stringify(userDataResponse))
        yield put(registerUserSuccessful(userDataResponse))
      }
      history("/dashboard")
    }
  } catch (error) {
    console.log("ERROR", error)
    if (authFor === "WV") {
      if (error?.message) {
        yield put(registerUserFailed(error?.message))
      } else {
        yield put(registerUserFailed(error))
      }
    } else {
      yield put(apiError(error))
    }
  }
}

function* authSaga() {
  yield takeEvery(GET_ME, getMe)
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGIN_USER_WV, loginUserWV)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
