import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import {
  registerUser,
  apiError,
  registerWVUser,
  socialLogin,
  registerUserFailed,
} from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import { Link, useLocation, useNavigate } from "react-router-dom"

// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"

const WelcomeWv = props => {
  //meta title
  document.title = "Register | Third Pillar"

  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div> */}
      <div className="account-pages my-4 pt-sm-0">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-12  align-items-center">
                      <div className="text-primary p-4 text-center">
                        <h5 className="text-primary">Welcome</h5>
                        <p>Get your free Third Pillar account now.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      {/* <img src={profileImg} alt="" className="img-fluid" /> */}
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  {/* <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div> */}
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      style={{}}
                      onSubmit={e => {}}
                    >
                      {/* {user && user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null} */}

                      <Row>
                        <Col className="d-flex flex-column align-items-center ">
                          <div
                            style={{
                              marginTop: 28,
                              width: "100%",
                            }}
                          >
                            <button
                              className="btn btn-primary btn-block w-100"
                              type="submit"
                            >
                              continue
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} Third Pillar.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default WelcomeWv
