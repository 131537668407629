import React from 'react'
import { Badge } from "reactstrap"

const GameDescription = ({ name, description, status, game_id, game_bundle_id }) => (
    <div className="card">
        <div className="border-bottom card-body">
            <div className="d-flex justify-content-between">
                <div className="">
                    <h5 className="fw-semibold">{name}</h5>
                    <p className="text-muted">{description}</p>
                </div>
                <div className="" style={{ width: '320px' }}>
                    <p>
                        <span className="fw-semibold m-0">Status:</span>&nbsp;
                        <Badge style={{ fontSize: '11px' }} className={status === 0 ? "bg-warning fs-6 text px-2 py-1" : status === 1 ? "bg-success fs-6 textpx-2 py-1" : ""}>
                            {status === 0 ? "Pending" : status === 1 ? "Live" : ""}
                        </Badge>
                    </p>
                    <p><span className="fw-semibold m-0">Game ID:</span> {game_id}</p>
                    <p><span className="fw-semibold m-0">Game Bundle ID:</span> {game_bundle_id}</p>
                </div>
            </div>
        </div>
    </div>
)

export default GameDescription