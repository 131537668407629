import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom"
import { getApiLogs } from 'store/actions';
import Table from 'components/Table';
import Loader from 'components/Loader';
import getTableConfig from './tableConfig';
import InfoModal from './InfoModal';
import { Button } from 'reactstrap'

const initalModalData = {
    isOpen: false,
    data: {}
}

const ApiLogs = () => {
    const dispatch = useDispatch();
    const [key, setKey] = useState('')
    const { loading, logs } = useSelector(state => state.ApiLogs);
    const location = useLocation()
    const [modalInfo, setModalInfo] = useState(initalModalData);
    const navigate = useNavigate();

    const toggleModalInfo = useCallback((params) => {
        setModalInfo(prev => {
            const newOpen = !prev.isOpen;

            if (newOpen) {
                const { data } = params;
                return {
                    isOpen: newOpen,
                    data
                }
            }

            return initalModalData
        })
    }, [setModalInfo])

    const tableConfig = useMemo(() => (
        getTableConfig(toggleModalInfo)
    ), [toggleModalInfo])

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const key = queryParams.get("key")
        setKey(key)
    }, [location.search])

    useEffect(() => {
        if (key) {
            dispatch(getApiLogs({ key }))
        }
    }, [dispatch, key])

    if (!tableConfig || loading) {
        return <Loader />
    }

    return (
        <div className="page-content">
            <div className="d-flex justify-content-end" style={{ margin: '-10px 0px' }}>
                <Button className="bg-transparent border-0 text-black" onClick={() => navigate(-1)}>Back to API Keys</Button>
            </div>
            <div className="container-fluid px-2">
                <h4>API Logs</h4>
                <Table
                    columns={tableConfig}
                    data={logs}
                    placeholder='Search logs...'
                />
            </div>
            {modalInfo && (
                <InfoModal
                    modalData={modalInfo}
                    toggle={toggleModalInfo}
                />
            )}
        </div>
    )
}

export default ApiLogs