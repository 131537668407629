import React, { useEffect, useState } from "react"
import { Col, Label, Modal, ModalBody, ModalHeader, Row, FormFeedback, Form, } from "reactstrap"
import Select from "react-select"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import { getRoleLabel } from "../../pages/Users"

const EditRoleModal = ({ modalData, toggle, optionsData, isCompany, action }) => {
    const { isOpen, data } = modalData;
    const dispatch = useDispatch();

    const [selectedUserRole, setSelectedUserRole] = useState({
        value: '',
        label: '',
    })

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            role: data?.role,
        },
        validationSchema: Yup.object({
            role: Yup.number().required("User Role is Required"),
        }),
        onSubmit: values => {
            dispatch(action({
                id: data.id,
                role: values.role
            }));

            validation.resetForm()
            toggle()
        },
    })

    useEffect(() => {

        setSelectedUserRole(prev => ({
            ...prev,
            label: isCompany ? (data.role === 2 ? 'Admin' : 'User') : getRoleLabel(data.role)
        }))
    }, [data.role])


    return (
        <Modal isOpen={isOpen} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle} tag="h4">
                Edit Role
            </ModalHeader>
            <ModalBody>
                <Form
                    onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                    }}
                >
                    <Row>
                        <Col xs={12}>
                            <div className="mb-3">
                                <Label className="form-label">User Role</Label>
                                <Select
                                    options={optionsData}
                                    styles={{
                                        menu: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: "#fff",
                                        }),
                                    }}
                                    name="role"
                                    value={selectedUserRole}
                                    onChange={e => {
                                        setSelectedUserRole(e)
                                        validation.setFieldValue("role", e.value)
                                    }}
                                />
                                {validation.touched.role && validation.errors.role ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.role}
                                    </FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="text-end">
                                <button
                                    type="submit"
                                    className="btn btn-success save-user"
                                >
                                    Save
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default EditRoleModal;