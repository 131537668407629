import React from "react";
import { Modal, ModalBody, ModalHeader, Row, Col, Form, Input, Label, FormFeedback } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { addCompany, editCompany } from "store/actions";

const CompanyModal = ({
    toggle,
    initialValues = {
        companyName: '',
        description: '',
        status: 0,
        platform_fee: 0,
        rev_share: 0,
        file: null,
    },
    isEdit
}) => {
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object({
            companyName: Yup.string().required("Company name is required"),
            description: Yup.string().required("Description is required"),
            status: Yup.number().required("Status is required"),
            platform_fee: Yup.number()
                .min(0, "Platform fee must be a non-negative number")
                .required("Platform fee is required"),
            rev_share: Yup.number()
                .min(0, "Revenue share must be a non-negative number")
                .required("Revenue share is required"),
            file: isEdit ? Yup.mixed()
                :
                Yup.mixed()
                    .required("A file is required")
                    .test(
                        "fileSize",
                        "The file is too large",
                        value => value && value.size <= 1024 * 1024 * 5 // 5MB maximum size
                    )
                    .test(
                        "fileType",
                        "Unsupported file format",
                        value => value && [
                            "image/jpeg", "image/png", "image/gif",
                            "image/svg", "image/jpg", "image/webp"
                        ].includes(value.type)
                    )
        }),
        onSubmit: (values) => {
            console.log(isEdit, 'edit')

            if (!isEdit) {
                dispatch(addCompany({
                    company: values.companyName,
                    company_desc: values.description,
                    status: values.status,
                    platform_fee: values.platform_fee,
                    rev_share: values.rev_share,
                    file: values.file,
                }));
            } else {
                dispatch(editCompany({
                    company: values.companyName,
                    company_desc: values.description,
                    status: values.status,
                    platform_fee: values.platform_fee,
                    rev_share: values.rev_share,
                    file: values.file,
                }));
            }

            formik.resetForm();
            toggle();
        },
    });

    return (
        <Modal isOpen={true} toggle={toggle} centered={true}>

            {isEdit ?
                <ModalHeader toggle={toggle} tag="h4">
                    Edit Company Details
                </ModalHeader> :
                <ModalHeader toggle={toggle} tag="h4">
                    Add Company
                </ModalHeader>
            }

            <ModalBody>
                <Form onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col md={12}>
                            <Label for="companyName">Company Name</Label>
                            <Input
                                disabled={isEdit ? true : false}
                                id="companyName"
                                name="companyName"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.companyName}
                                invalid={formik.touched.companyName && !!formik.errors.companyName}
                            />
                            <FormFeedback>{formik.errors.companyName}</FormFeedback>
                        </Col>
                    </Row>
                    <Row className={'my-3'}>
                        <Col md={12}>
                            <Label for="description">Description</Label>
                            <Input
                                id="description"
                                name="description"
                                type="textarea"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.description}
                                invalid={formik.touched.description && !!formik.errors.description}
                                rows={isEdit ? 5 : 3}
                            />
                            <FormFeedback>{formik.errors.description}</FormFeedback>
                        </Col>
                    </Row>

                    <Row className={'mb-3'}>
                        <Col md={12}>
                            <Label for="platform_fee">Platform Fee</Label>

                            <Input
                                id="platform_fee"
                                name="platform_fee"
                                type="number"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.platform_fee}
                                invalid={formik.touched.platform_fee && !!formik.errors.platform_fee}
                                min="0"
                            />
                            <FormFeedback>{formik.errors.platform_fee}</FormFeedback>
                        </Col>
                    </Row>
                    <Row className={'mb-3'}>
                        <Col md={12}>
                            <Label for="rev_share">Revenue Share</Label>
                            <Input
                                id="rev_share"
                                name="rev_share"
                                type="number"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.rev_share}
                                invalid={formik.touched.rev_share && !!formik.errors.rev_share}
                                min="0"
                            />
                            <FormFeedback>{formik.errors.rev_share}</FormFeedback>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Label className="form-label">Status</Label>
                        {/* <Row className=""> */}
                        <Col md={12}>
                            <Select
                                options={[
                                    { value: 0, label: "Pending" },
                                    { value: 1, label: "Live" }
                                ]}
                                styles={{
                                    menu: (provided) => ({
                                        ...provided,
                                        backgroundColor: "#fff",
                                    }),
                                }}
                                name="status"
                                value={formik.values.status === 0 || formik.values.status === 1 ?
                                    { value: formik.values.status, label: formik.values.status === 0 ? "Pending" : "Live" } :
                                    null}
                                onChange={option => formik.setFieldValue("status", option.value)}
                                isSearchable={false}
                            />
                        </Col>
                        {/* </Row> */}
                    </Row>
                    <Row className="mb-3">
                        <Col md={12}>
                            <Label for="logo">Company Logo</Label>
                            <Input
                                id="logo"
                                name="file"
                                type="file"
                                onChange={(event) => {
                                    const file = event.currentTarget.files[0];
                                    formik.setFieldValue("file", file);
                                }}
                                invalid={formik.touched.file && !!formik.errors.file}
                            />
                            {formik.touched.file && formik.errors.file && (
                                <FormFeedback>{formik.errors.file}</FormFeedback>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {isEdit ?
                                <button type="submit" className="btn btn-secondary">Edit Company</button>
                                :
                                <button type="submit" className="btn btn-success">Add Company</button>
                            }

                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default CompanyModal;