import React from "react"
import { Link } from "react-router-dom"
import { Badge, Button } from "reactstrap"
import { getRoleLabel } from "."

export const getTableConfigSuper = (toggleEditModalOpen, toggleModalDeleteData) => ({
    columns: [
        {
            header: "#",
            accessorKey: "img",
            cell: cell => (
                <>
                    {!cell.getValue() ? (
                        <div className="avatar-xs">
                            <span className="avatar-title rounded-circle">
                                {cell.row.original.first_name &&
                                    cell.row.original.first_name.charAt(0)}{" "}
                            </span>
                        </div>
                    ) : (
                        <div>
                            <img
                                className="rounded-circle avatar-xs"
                                src={cell.getValue()}
                                alt=""
                            />
                        </div>
                    )}
                </>
            ),
            enableColumnFilter: false,
            enableSorting: true,
        },
        {
            header: "Name",
            accessorKey: "name",
            enableColumnFilter: false,
            enableSorting: true,
            cell: cell => {
                return (
                    <>
                        <p className="mb-0">
                            {cell.row.original.first_name}
                        </p>
                    </>
                )
            },
        },
        {
            header: "Surname",
            accessorKey: "surname",
            enableColumnFilter: false,
            enableSorting: true,
            cell: cell => {
                return (
                    <>
                        <p className="mb-0">
                            {cell.row.original.last_name}
                        </p>
                    </>
                )
            },
        },
        {
            header: "Status",
            accessorKey: "status",
            enableColumnFilter: false,
            enableSorting: true,
            cell: cell => {
                return cell.row.original.isInvited ? (
                    <Badge className="bg-warning px-2 py-1" style={{ fontSize: '11px' }}>Invited</Badge>
                ) : (
                    <Badge className="bg-success px-2 py-1" style={{ fontSize: '11px' }}>Joined</Badge>
                );
            },
        },
        {
            header: "Email",
            accessorKey: "email",
            enableColumnFilter: false,
            enableSorting: true,
        },

        {
            header: "Role",
            cell: cell => {
                return (
                    <div className="d-flex gap-4">
                        <p className="m-0 d-flex align-items-center" style={{ width: 'auto' }}>
                            {getRoleLabel(cell.row.original.role)}
                        </p>
                        <Button
                            className="text-success bg-transparent shadow-none border-0"
                            onClick={() => toggleEditModalOpen({ id: cell.row.original.docId, role: cell.row.original.role })}
                        >
                            <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                        </Button>
                    </div>
                )
            },
        },

        {
            header: "Country",
            accessorKey: "country",
            enableColumnFilter: false,
            enableSorting: true,
            cell: cell => {
                const regionNamesInEnglish = new Intl.DisplayNames(["en"], {
                    type: "region",
                })

                return (
                    <div>
                        {cell.row.original.country_code
                            ? regionNamesInEnglish.of(cell.row.original.country_code)
                            : "--"}
                    </div>
                )
            },
        },

        {
            header: "Delete",
            cell: cell => {
                return (
                    <div className="d-flex gap-3">
                        <Button
                            className="text-danger bg-transparent shadow-none border-0"
                            onClick={() => toggleModalDeleteData({ id: cell.row.original.docId, email: cell.row.original.email })}
                        >
                            <i className="mdi mdi-trash-can font-size-20" />
                        </Button>
                    </div>
                )
            },
        },
    ],
    title: "Users",
    search: 'Search User',
    searchField: 'email',
    pt: '0',
})