import React, { useEffect } from 'react'
import { Row, Col } from "reactstrap"

//Import Components 
import MiniWidget from "pages/Dashboard-saas/mini-widget"
import Earning from "pages/Dashboard-saas/earning"
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getChartsData, getDashboardData } from 'store/actions'
import Loader from 'components/Loader'
import Chart from 'components/DashboardCharts'

const DashboardThirdAdmin = () => {
    const { dashboard, loading, charts } = useSelector(state => state.Admin);
    const dispatch = useDispatch();

    const reports = [
        {
            icon: "bx bx-copy-alt",
            title: "Games - last 30 days",
            value: `${dashboard?.matchesLast30Days}`,
        },
        {
            icon: "bx bx-archive-in",
            title: "Revenue - last 30 days",
            value: `$ ${dashboard?.sumForLast30Days.toFixed(2)}`,
        },
        {
            icon: "bx bx-purchase-tag-alt",
            title: "Average Revenue per game - last 30 days",
            value: `$ ${dashboard?.averageRevenuePer30LastDays}`,
        },
    ]

    useEffect(() => {
        dispatch(getChartsData());
        dispatch(getDashboardData());
    }, [dispatch]);

    if (loading) {
        return <Loader />
    }

    return (<>
        <Row>
            <Col xl="12">
                <Row>
                    <MiniWidget reports={reports} />
                </Row>
            </Col>
        </Row>
        {charts &&
            <>
                <Row>
                    <Chart
                        dataColors='["--bs-primary"]'
                        data={charts?.users} title="New Users"
                        seriesName="User Signups"
                        yaxisTitle="New Users"
                    />
                </Row>
                <Row>
                    <Chart
                        dataColors='["--bs-primary"]'
                        data={charts?.revenue}
                        title="Platform Revenue"
                        seriesName="Revenue"
                        yaxisTitle="Revenue"
                    />
                </Row>
            </>
        }
    </>)
}

export default DashboardThirdAdmin