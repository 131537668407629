import { call, put, takeEvery } from "redux-saga/effects"
import {
  GET_ORDERS,
  UPDATE_ORDER,
} from "./actionTypes"

import "react-toastify/dist/ReactToastify.css"
import { getFirebaseBackend } from "helpers/firebase_helper"
import { getOrdersFail, getOrdersLoadingSuccess } from "./actions"

const fireBaseBackend = getFirebaseBackend()

// function* fetchOrders() {
//   console.log('FETCH-dd')
//   yield put(getOrdersLoadingSuccess(true))
//   try {
//     const response = yield call(fireBaseBackend.getTransactions)
//     yield put(getOrdersSuccess(response))
//   } catch (error) {
//     yield put(getOrdersFail(error))
//   }
//   yield put(getOrdersLoadingSuccess(false))
// }

function* onUpdateOrder({ payload: order }) {
  try {
    const response = yield call(updateOrder, order)
    yield put(updateOrderSuccess(response))
    toast.success("Order Update Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateOrderFail(error))
    toast.error("Order Update Failed", { autoClose: 2000 })
  }
}

function* ecommerceSaga() {
  // yield takeEvery(GET_ORDERS, fetchOrders)
  yield takeEvery(UPDATE_ORDER, onUpdateOrder)
}

export default ecommerceSaga
