import React from "react"
import { Badge } from "reactstrap"
import { Link } from "react-router-dom"

export const getTableConfig = () => ({
    columns: [
        {
            header: "Name",
            enableColumnFilter: false,
            enableSorting: true,
            accessorKey: "name",
            cell: cellProps => {
                return (
                    <Link to={`/keys-list/?id=${cellProps.row.original.id}`} className="text-body">
                        {cellProps.row.original.name}
                    </Link>
                )
            },
        },
        {
            header: "Game ID",
            enableColumnFilter: false,
            enableSorting: true,
            accessorKey: "game_id",
            cell: cellProps => {
                return (
                    <Link to={`/keys-list/?id=${cellProps.row.original.id}`} className="text-body">
                        {cellProps.row.original.game_id}
                    </Link>
                )
            },
        },
        {
            header: "Game Bundle ID",
            enableColumnFilter: false,
            enableSorting: true,
            accessorKey: "game_bundle_id",
            cell: cellProps => {
                return (
                    <Link to={`/keys-list/?id=${cellProps.row.original.id}`} className="text-body">
                        {cellProps.row.original.game_bundle_id}
                    </Link>
                )
            },
        },
        {
            header: "Created Date",
            enableColumnFilter: false,
            enableSorting: true,
            accessorKey: "created_at",
            cell: cellProps => {
                return <span>{cellProps.row.original.created_at.date}</span>;
            },
        },
        {
            header: "Modified Date",
            enableColumnFilter: false,
            enableSorting: true,
            accessorKey: "modified_at",
            cell: cellProps => {
                return <span>{cellProps.row.original.modified_at.date}</span>;
            },
        },
        {
            header: "Status",
            accessorKey: "status",
            enableColumnFilter: false,
            enableSorting: true,
            cell: cell => {
                switch (cell.row.original.status) {
                    case 1:
                        return <Badge
                            className="bg-success px-2 py-1"
                            style={{ fontSize: '11px' }}
                        >Live
                        </Badge>
                    case 0:
                        return <Badge
                            className="bg-warning px-2 py-1"
                            style={{ fontSize: '11px' }}
                        >
                            Pending
                        </Badge>
                }
            },
        },
    ],
    title: 'Games',
    search: 'Search Game',
    pt: '0',
    px: '0',
    pb: '0'
})

export default getTableConfig;